const root = '/apps';

export const applicationsRoutes = {
  root,

  calc: `${root}/calc`,
  editCalc: (id?: string) => {
    if (id) return `${root}/calc/${id}` as const;
    return `${root}/calc/:id` as const;
  },

  list: `${root}/list`,

  application: (id?: string) => {
    if (id) return `${root}/list/${id}` as const;
    return `${root}/list/:id` as const;
  },

  logs: (id?: string) => {
    if (id) return `${root}/logs/${id}` as const;
    return `${root}/logs/:id` as const;
  },
} as const;
