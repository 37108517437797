import React from 'react';
import Result from '../Result';
import { Button, Box, makeStyles } from '@material-ui/core';
import hasAnyRole from 'common/hooks/useHasAnyRole';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBotton: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  extra: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttons: {
    margin: theme.spacing(3, 1),
  },
}));

type ErrorFallbackProps = {
  className?: string;
  error: any;
  resetErrorBoundary?: any;
};
const ErrorFallback: React.FC<ErrorFallbackProps> = ({ error, resetErrorBoundary }) => {
  const classes = useStyles();

  const handleTryAgain = () => {
    if (resetErrorBoundary) {
      resetErrorBoundary();
    } else {
      window.location.reload();
    }
  };

  const hasAdminRole = hasAnyRole(['ADMIN']);
  const defaultMessage = 'Разработчики уже в курсе, и скоро исправят ошибку.';
  return (
    <>
      <Result
        title="Что-то пошло не так... 🤔"
        status={'Неизвестная'}
        subTitle={hasAdminRole ? error.message : defaultMessage}
        extra={
          <Box className={classes.extra}>
            <Button className={classes.buttons} href="/" variant="contained" color="secondary">
              На главную
            </Button>
            <Button
              className={classes.buttons}
              onClick={handleTryAgain}
              variant="outlined"
              color="primary"
            >
              Перезагрузить
            </Button>
          </Box>
        }
      />
    </>
  );
};

export default ErrorFallback;
