type ArrayElement = {
  value: string;
  name: string;
};

type Options = {
  errorCode?: boolean;
  replacedStr?: string;
};

/**
 * Конвертирует перечисление в массив, также с помощью опций можно конвертировать перечесление ошибок
 *
 * @example Пример использования
 * enum Enum {
 *   'err001' = 'Bad document',
 *   'err002' = 'Low quality',
 * }
 *
 * Следующая запись:
 * enumToArray(Enum, { errorCode: true, replacedStr: 'err' })
 * Преобразуется в:
 * [{ value: '001', name: 'Bad document' }, { value: '002', name: 'Low quality' }]
 */
export const enumToArray: (enumObj: object, options?: Options) => ArrayElement[] = (
  enumObj,
  { errorCode = false, replacedStr = 'e' } = {}
) => {
  const array: ArrayElement[] = Object.keys(enumObj).map((key) => {
    return {
      value: errorCode ? key.replace(replacedStr, '') : key,
      name: enumObj[key],
    };
  });

  return array;
};
