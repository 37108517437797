import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import authSeviceSlice from 'common/services/auth';
import notistackSlice from 'modules/notification/notistackSlice';
import documentsSlice from 'features/documents/documentsSlice';
import agentsSlice from 'features/profile/agentsSlice';
import countDownTimerSlice from 'features/countDownTimer/countDownTimerSlice';

export interface Reducers {
  [s: string]: Reducer;
}

const rootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    authService: authSeviceSlice,
    notistack: notistackSlice,
    documents: documentsSlice,
    agents: agentsSlice,
    countDownTimer: countDownTimerSlice,
  });

export default rootReducer;
