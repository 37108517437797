import { useQuery } from 'react-query';
// Api
import { usersApi } from 'lib/api';
// Enums
import { QueryKeysEnum } from 'lib/enums';

/**
 * Хук запрашивает данные о пользователе (который залогинен)
 */
export const useUser = () => {
  const { data, ...res } = useQuery(
    QueryKeysEnum.userInfo,
    async () => {
      const { data } = await usersApi.fetchUserInfo();
      return data;
    },
    {
      refetchOnWindowFocus: false,
      onError: (error) => console.error('Fetch user error: ', error),
    }
  );

  return {
    user: data,
    ...res,
  };
};
