import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { AppThunk, RootState } from 'app/store';
import { User } from 'common/model';
import api from './api';
import { ErrorResponse } from './ApiTypes';

interface InitialAuthServiceState {
  userId: string | null;
  isAuthenticating: boolean; // isAuthenticating?
  user: User | null;
  error: Partial<ErrorResponse> | null;
}

// AuthService Initial State
const initialState: InitialAuthServiceState = {
  isAuthenticating: false,
  userId: null,
  user: null,
  error: null,
};

// AuthService slice
const authSeviceSlice = createSlice({
  name: 'authService',
  initialState,
  reducers: {
    setIsAuthenticating: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticating = payload;
    },
    requestStarted: (state) => {
      state.error = null;
    },
    requestFailed: (state, { payload }: PayloadAction<Partial<ErrorResponse>>) => {
      state.error = payload;
    },
    dropError: (state) => {
      state.error = null;
    },
    signUpSuccess: (state, { payload }: PayloadAction<string>) => {
      state.userId = payload;
    },
    getAuthStateSuccess: (state, { payload }: PayloadAction<User>) => {
      state.user = payload;
    },

    logoutSuccess: (state) => {
      state.userId = null;
      state.user = null;
      state.error = null;
    },
  },
});

// Actions
export const {
  setIsAuthenticating,
  requestStarted,
  signUpSuccess,
  getAuthStateSuccess,
  requestFailed,
  logoutSuccess,
  dropError,
} = authSeviceSlice.actions;

/**
 * Проверка статуса авторизации
 */
export const getAuthState = (): AppThunk => async (dispatch) => {
  dispatch(setIsAuthenticating(true));
  api.getAuthState().then((resp) => {
    const { data } = resp;
    dispatch(getAuthStateSuccess(data));
    dispatch(setIsAuthenticating(false));
  });
};

export const relogin = (): AppThunk => (dispatch) => {
  dispatch(refreshToken());
};

export type ActivateAccountArgs = {
  activationToken: string;
};

/**
 * refreshToken
 */
export const refreshToken = (): AppThunk => async (dispatch) => {
  api
    .refreshToken()
    .then(() => {
      dispatch(getAuthState());
    })
    .catch((error) => {
      console.log('activationToken:', error);
    });
};

// Reducer
export default authSeviceSlice.reducer;

// State chunks exports
export const authServiceStateSelector = (state: RootState) => state.authService;

/**
 * SELECTORS
 */

export const selectIsAuthenticating = (state: RootState) =>
  authServiceStateSelector(state).isAuthenticating;

export const selectUserId = (state: RootState) => authServiceStateSelector(state).userId;
export const selectIsLoggedIn = createSelector(selectUserId, (userId) => !!userId);

export const selectUser = (state: RootState) => authServiceStateSelector(state).user;

export const selectErrorState = (state: RootState) => authServiceStateSelector(state).error;
