import { FC, Suspense, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ConnectedRouter } from 'connected-react-router';
import { ErrorBoundary } from 'react-error-boundary';
import { LocalizationProvider } from '@material-ui/pickers';
import DateFnsAdapter from '@material-ui/pickers/adapter/luxon';
// Components
import { LoadingScreen } from 'common/components';
import ErrorFallback from 'common/components/ErrorBoundary/ErrorFallback';
import NotificationsProvider from 'modules/notification/NotificationsProvider';
import Notifier from 'modules/notification/Notifier';
import GlobalStyles from 'common/components/GlobalStyles';
import { ThemeProviderMui4 } from '../modules/theme';
import { ThemeProviderMui5 } from '../lib/contexts/ThemeContext';
import ChatWootSnippet from 'common/components/Snippets/ChatWootSnippet';
// Utils
import history from './history';
import { ProvideAuth } from 'common/hooks/useAuth';
import { store, StorePersistGate } from './store';
import { Routes } from './routes';
import './styles.css';
import 'devextreme/dist/css/dx.light.css';
import { locale } from 'devextreme/localization';

const queryClient = new QueryClient();
/**
 * Главный корневой компонент
 */
export const Root: FC = () => {
  useEffect(() => {
    locale('ru-RU');
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProviderMui4>
        <ThemeProviderMui5>
          <Provider store={store}>
            <RecoilRoot>
              <Suspense fallback={<LoadingScreen />}>
                <StorePersistGate>
                  <NotificationsProvider>
                    <LocalizationProvider dateAdapter={DateFnsAdapter} locale={'ru-RU'}>
                      <ConnectedRouter history={history}>
                        <ProvideAuth>
                          <GlobalStyles />
                          <ErrorBoundary FallbackComponent={ErrorFallback}>
                            <Notifier />
                            {false && <ChatWootSnippet />}
                            <Routes />
                          </ErrorBoundary>
                        </ProvideAuth>
                      </ConnectedRouter>
                    </LocalizationProvider>
                  </NotificationsProvider>
                </StorePersistGate>
              </Suspense>
            </RecoilRoot>
          </Provider>
        </ThemeProviderMui5>
      </ThemeProviderMui4>

      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
    </QueryClientProvider>
  );
};
