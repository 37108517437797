// Mui components
import { Typography } from '@mui/material';
// Ui components
import { MortgageIcon } from 'lib/ui/Icons';
// Components
// Types
import { ProductCard } from 'lib/models/ProductCard';
import { CreateApplicationButtonZenit } from '../components/CreateApplicationButtonZenit';

/**
 * Карточки продуктов проекта
 */
export const mtsProductCards: ProductCard[] = [
  {
    id: 'product-card-1',
    icon: <MortgageIcon />,
    title: 'Ипотека / Рефинансирование / Кредит под залог',
    description: (
      <>
        <Typography variant="body2">
          Вознаграждение до{' '}
          <Typography variant="body2" component="span" color="primary">
            1.25%
          </Typography>
        </Typography>
      </>
    ),
    actions: (
      <>
        <CreateApplicationButtonZenit
          invalidateKey="applications"
          route={(id: string) => `/private/applications/list/${id}`}
        />
      </>
    ),
  },
];
