import { useQuery } from 'react-query';
// Api
import { commonApi } from 'common/api';
// Enums
import { QueryKeysEnum } from 'lib/enums';

/**
 * Загружает ЦИКи / Филиалы
 */
export const useMortgageCenters = () => {
  const { data, ...res } = useQuery(
    QueryKeysEnum.mortgageCenters,
    async () => {
      const { data } = await commonApi.getMortgageCenters();
      return data;
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      onError: (error) => console.error('Fetch mortgage centers: ', error),
    }
  );

  return {
    mortgageCenters: data,
    ...res,
  };
};
