import { FC, useState, MouseEvent } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// Mui components
import { Box, Tooltip, IconButton, Menu, MenuItem } from '@mui/material';
// Mui icons
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
// Hooks
import useHasAnyRole from 'common/hooks/useHasAnyRole';
import { useAuth } from 'common/hooks/useAuth';

/**
 * Компонент отображающий дополнительное меню Профиля пользователя
 */
export const Account: FC = () => {
  /**
   * Hooks
   */
  const auth = useAuth();
  const { pathname } = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { hasRole: hasOfficeStaffRole } = useHasAnyRole(['OFFICE_STAFF']);
  const { hasRole: hasOfficeManagerRole } = useHasAnyRole(['OFFICE_MANAGER']);

  /**
   * Constants
   */
  const companyWorkers = !hasOfficeStaffRole && !hasOfficeManagerRole;

  /**
   * Handlers
   */
  const handleClick = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleClickLogout = () => auth.signout();

  return (
    <Box>
      <Tooltip title="Аккаунт">
        <IconButton sx={{ color: ({ palette }) => palette.text.primary }} onClick={handleClick}>
          <AccountCircleRoundedIcon />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={handleClose}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{ '.MuiMenu-list': { minWidth: 180 } }}
      >
        <MenuItem
          component={RouterLink}
          to={'/private/profile/me'}
          selected={pathname === '/private/profile/me'}
        >
          Профиль
        </MenuItem>

        {companyWorkers && (
          <>
            <MenuItem
              component={RouterLink}
              to={'/private/profile/rewards'}
              selected={pathname === '/private/profile/rewards'}
            >
              Уровень вознаграждения (%)
            </MenuItem>

            <MenuItem
              component={RouterLink}
              to={'/private/profile/legal-info'}
              selected={pathname === '/private/profile/legal-info'}
            >
              Юридическая информация
            </MenuItem>
          </>
        )}

        <MenuItem
          component={RouterLink}
          to={'/private/profile/settings'}
          selected={pathname === '/private/profile/settings'}
        >
          Настройки
        </MenuItem>

        <MenuItem component={RouterLink} to={'/private/profile/logout'} onClick={handleClickLogout}>
          Выйти
        </MenuItem>
      </Menu>
    </Box>
  );
};
