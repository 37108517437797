import { FC } from 'react';
import { CreateApplicationButton } from 'lib/components/CreateApplicationButton';

interface Props {
  invalidateKey?: string;
  route?: (id: string) => string;
}

/**
 * Кнопка создания заявки
 * С добавленной функциональностью для зенита, блокировать создание если отсутствует профиль
 */
export const CreateApplicationButtonZenit: FC<Props> = ({ invalidateKey, route }) => {
  return <CreateApplicationButton invalidateKey={invalidateKey} route={route} />;
};
