import { FC } from 'react';
import { NavLink } from 'react-router-dom';
// Mui components
import { List, ListItem, ListItemButton, SvgIcon, Typography, Chip } from '@mui/material';
// Utils
import { useMenuList } from './useMenuList';

/**
 * Компонент отображающий меню
 */
export const Menu: FC = () => {
  const menu = useMenuList();
  return (
    <List
      sx={{ display: 'flex', flexDirection: 'column', gap: ({ spacing }) => spacing(0.5) }}
      disablePadding
    >
      {menu?.map(({ id, link, title, Icon, label }) => (
        <ListItem key={id} sx={{ padding: 0 }} disableGutters>
          <ListItemButton
            sx={{
              padding: ({ spacing }) => spacing(1),
              borderRadius: ({ shape }) => `${shape.borderRadius}px`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: ({ spacing }) => spacing(2),
              color: ({ palette }) => palette.text.primary,
              '&.active': {
                background: ({ palette }) => palette.action.hover,
                color: ({ palette }) => palette.primary.main,
              },
            }}
            component={NavLink}
            to={link}
          >
            {Icon && (
              <SvgIcon fontSize="small">
                <Icon />
              </SvgIcon>
            )}

            {title && <Typography variant="subtitle2">{title}</Typography>}

            {label && (
              <Chip
                sx={{
                  marginLeft: 'auto',
                  height: 20,
                  borderRadius: ({ shape }) => `calc(${shape.borderRadius}px /2)`,
                }}
                label={label}
                size="small"
                color="primary"
              />
            )}
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
