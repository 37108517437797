export const applications = {
  totalCount: 4,
  page: 1,
  limit: 10,
  data: [
    {
      _id: 'd7fe69b4-6312-41a5-9cd8-1e3afefa5248',
      type: 'MORTGAGE',
      comments: '',
      createdBy: {
        _id: 'd68ce687-eece-4ed7-9ac7-b39c3e2a2f1f',
        firstName: 'max',
        lastName: 'alex',
        email: 'kodermax@gmail.com',
        phone: '89037767523',
      },
      status: 'EP_DRAFT',
      entityNo: 309,
      applicant: {
        firstName: 'Ivanov',
        lastName: 'Alex',
        middleName: 'Maksim',
        birthDate: '1999-12-31T21:00:00.000Z',
        gender: 'MALE',
        maritalStatus: 'MARRIED',
        educationLevel: 'HIGHER_2_OR_MORE',
        contacts: {
          email: 'kodermax@gmail.com',
          phone: '79857257397',
        },
        passport: {
          typeName: 'RUSSIAN_PASSPORT',
          docSeries: '1321',
          docNo: '432432',
          issueUnit: '432432',
          issueDate: '2009-12-31T21:00:00.000Z',
          validDate: '2030-02-01T21:00:00.000Z',
          issuedBy: 'testestestes',
          birthPlace: 'tsetes',
          birthCountry: 'fdfsdfds',
          address: 'Тамбовская обл, рп Мучкапский, ул Красная Москва',
        },
        address: {
          isMatch: true,
        },
        incomes: {
          incomeVerificationType: 9,
          snils: '44324234234',
          reportIncome: 34234,
          reportIncomeRetirement: 432432432,
          reportIncomeRealty: 432432432,
        },
        employment: {
          noEployment: false,
          name: '3432432432',
          inn: '432432432432',
          registeredDate: '1999-12-31T21:00:00.000Z',
          fieldOfActivity: 9,
          phoneNumber: '79037657654',
          employmentType: 1,
          employmentDate: '2021-01-13T19:28:11.000Z',
          employmentAddress: 'г Москва, пр-кт Защитников Москвы',
          fieldOfActivityInCompany: 11,
          jobLevel: 1,
          natureOfWork: 1,
          lawyerOrNotary: '1',
          experienceInIndustry: 112430001,
        },
        expenses: [
          {
            expenseType: 'CREDITS',
            amount: 45345,
          },
          {
            expenseType: 'ALIMENT',
            amount: 12000,
          },
        ],
      },
      changedBy: 'd68ce687-eece-4ed7-9ac7-b39c3e2a2f1f',
      credit: {
        amount: 120000,
        period: 20,
        initialPayment: 55000,
      },
      realEstate: {
        region: '01',
        city: 'Майкоп',
        objectType: 2,
      },
      coApplicants: [
        {
          contacts: {
            email: 'kodermax@gmail.com',
            phone: '79030132414',
          },
          passport: {
            typeName: 'RUSSIAN_PASSPORT',
            docSeries: '2321',
            docNo: '321321',
            issueUnit: '321321',
            issuedBy: 'fsdf dsf dsfds fds',
            validDate: '1999-12-31T21:00:00.000Z',
            issueDate: '1999-12-31T21:00:00.000Z',
            birthPlace: ' foids fjkds hfkjsdh fjkds',
            birthCountry: ' fds fsdf dsf ds',
          },
          address: {
            isMatch: true,
          },
          incomes: {
            reportIncomeRetirement: 4324324,
            reportIncomeRealty: 4324324324,
            incomeVerificationType: 9,
            snils: '43423432432',
            reportIncome: 432432,
          },
          employment: {
            noEployment: false,
            name: 'fdsfdsfdsf ',
            inn: '4324324324',
            registeredDate: '1999-12-31T21:00:00.000Z',
            fieldOfActivity: 8,
            phoneNumber: '74324324324',
            employmentType: 2,
            employmentDate: '1999-12-31T21:00:00.000Z',
            employmentAddress: 'Псковская обл, Порховский р-н, деревня Москва',
            fieldOfActivityInCompany: 10,
            jobLevel: 2,
            natureOfWork: 2,
            lawyerOrNotary: '1',
            experienceInIndustry: 112430000,
          },
          expenses: [],
          firstName: 'dsad',
          lastName: 'dsadsa',
          middleName: 'dsad',
          maritalStatus: 'MARRIED',
          gender: 'MALE',
          birthDate: '1999-12-31T21:00:00.000Z',
          educationLevel: 'HIGHER_2_OR_MORE',
        },
      ],
      bankId: 'ABSOLUTBANK',
      leadId: '9eb37d52-0e00-4d68-9ee1-10b3ad3f1c8e',
      leadNo: 114,
      updatedAt: '2021-01-27T12:43:16.344Z',
      createdAt: '2021-01-27T12:43:16.345Z',
      files: {
        passport: [],
        privacyAgreement: [],
        realEstate: [],
      },
    },
    {
      _id: '6edc47eb-8db8-4052-a8e1-932c87d451e8',
      type: 'MORTGAGE',
      comments: 'hello',
      createdBy: {
        _id: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
        firstName: 'Анатолий',
        lastName: 'Туков',
        email: 'at@bizmedia.ru',
        phone: '79253335102',
      },
      status: 'BANK_IN_PROCESS_BANK',
      entityNo: 305,
      applicant: {
        firstName: 'Туков',
        lastName: 'Анатолий',
        middleName: 'Русланович',
        birthDate: '1981-06-27T20:00:00.000Z',
        gender: 'MALE',
        maritalStatus: 'SINGLE',
        educationLevel: 'INCOMPLETE',
        contacts: {
          email: 'at@bizmedia.ru',
          phone: '79253335102',
        },
        passport: {
          typeName: 'RUSSIAN_PASSPORT',
          docSeries: '5400',
          docNo: '090909',
          issueUnit: '770707',
          issueDate: '2002-11-01T21:00:00.000Z',
          issuedBy: 'Межгалактическим советом светлых сил',
          birthPlace: 'Ставропольский край',
          birthCountry: 'Россия',
          address: 'Карачаево-Черкесская Респ, Абазинский р-н, аул Эльбурган',
        },
        address: {
          isMatch: true,
        },
        incomes: {
          incomeVerificationType: 1,
          snils: '34234234234',
          reportIncome: 350000,
          reportIncomeRetirement: 0,
          reportIncomeRealty: 150000,
        },
        employment: {
          noEployment: true,
        },
        expenses: [
          {
            expenseType: 'CREDITS',
            amount: 3000,
          },
        ],
      },
      changedBy: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
      credit: {
        amount: 3000000,
        period: 2,
        initialPayment: 12000000,
      },
      realEstate: {
        region: '77',
        city: 'Москва',
        objectType: 3,
      },
      privacyAgreement: {
        files: ['36116929-db86-4e95-bdec-9cc620a5afe7'],
      },
      bankId: 'ABSOLUTBANK',
      leadId: '79f38cbb-3ca1-452b-96dd-a14d7fbcf57c',
      leadNo: 126,
      updatedAt: '2021-01-26T12:23:13.210Z',
      createdAt: '2021-01-26T12:20:11.703Z',
      files: {
        passport: [],
        privacyAgreement: [
          {
            _id: '36116929-db86-4e95-bdec-9cc620a5afe7',
            key: 'root_applicant_privacyAgreement_agreement/25180c3b-83e8-47bd-9081-f81b7fed3836.pdf',
            url: 'https://ep-storage-dev.hb.bizmrg.com/root_applicant_privacyAgreement_agreement/25180c3b-83e8-47bd-9081-f81b7fed3836.pdf',
            ext: 'pdf',
            size: 95723,
            mime: 'application/pdf',
            name: 'agreement.pdf',
            createdBy: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
            updatedAt: '2021-01-26T12:18:43.772Z',
            createdAt: '2021-01-26T12:18:43.772Z',
          },
        ],
        realEstate: [],
      },
    },
    {
      _id: '88d74acd-2e0c-40b6-a4a9-72120b82a30b',
      type: 'MORTGAGE',
      comments: 'hello',
      createdBy: {
        _id: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
        firstName: 'Анатолий',
        lastName: 'Туков',
        email: 'at@bizmedia.ru',
        phone: '79253335102',
      },
      status: 'EP_DRAFT',
      entityNo: 308,
      applicant: {
        firstName: 'Туков',
        lastName: 'Анатолий',
        middleName: 'Русланович',
        birthDate: '1981-06-27T20:00:00.000Z',
        gender: 'MALE',
        maritalStatus: 'SINGLE',
        educationLevel: 'INCOMPLETE',
        contacts: {
          email: 'at@bizmedia.ru',
          phone: '79253335102',
        },
        passport: {
          typeName: 'RUSSIAN_PASSPORT',
          docSeries: '5400',
          docNo: '090909',
          issueUnit: '770707',
          issueDate: '2002-11-01T21:00:00.000Z',
          issuedBy: 'Межгалактическим советом светлых сил',
          birthPlace: 'Ставропольский край',
          birthCountry: 'Россия',
          address: 'Карачаево-Черкесская Респ, Абазинский р-н, аул Эльбурган',
        },
        address: {
          isMatch: true,
        },
        incomes: {
          incomeVerificationType: 1,
          snils: '34234234234',
          reportIncome: 350000,
          reportIncomeRetirement: 0,
          reportIncomeRealty: 150000,
        },
        employment: {
          noEployment: true,
        },
        expenses: [
          {
            expenseType: 'CREDITS',
            amount: 3000,
          },
        ],
      },
      changedBy: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
      credit: {
        amount: 3000000,
        period: 2,
        initialPayment: 12000000,
      },
      realEstate: {
        region: '77',
        city: 'Москва',
        objectType: 3,
      },
      privacyAgreement: {
        files: ['36116929-db86-4e95-bdec-9cc620a5afe7'],
      },
      bankId: 'ROSBANK_DOM',
      leadId: '79f38cbb-3ca1-452b-96dd-a14d7fbcf57c',
      leadNo: 126,
      updatedAt: '2021-01-26T12:20:11.704Z',
      createdAt: '2021-01-26T12:20:11.704Z',
      files: {
        passport: [],
        privacyAgreement: [
          {
            _id: '36116929-db86-4e95-bdec-9cc620a5afe7',
            key: 'root_applicant_privacyAgreement_agreement/25180c3b-83e8-47bd-9081-f81b7fed3836.pdf',
            url: 'https://ep-storage-dev.hb.bizmrg.com/root_applicant_privacyAgreement_agreement/25180c3b-83e8-47bd-9081-f81b7fed3836.pdf',
            ext: 'pdf',
            size: 95723,
            mime: 'application/pdf',
            name: 'agreement.pdf',
            createdBy: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
            updatedAt: '2021-01-26T12:18:43.772Z',
            createdAt: '2021-01-26T12:18:43.772Z',
          },
        ],
        realEstate: [],
      },
    },
    {
      _id: '7627237d-8ab8-4702-b2ea-02b37611990a',
      type: 'MORTGAGE',
      comments: 'hello',
      createdBy: {
        _id: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
        firstName: 'Анатолий',
        lastName: 'Туков',
        email: 'at@bizmedia.ru',
        phone: '79253335102',
      },
      status: 'EP_DRAFT',
      entityNo: 306,
      applicant: {
        firstName: 'Туков',
        lastName: 'Анатолий',
        middleName: 'Русланович',
        birthDate: '1981-06-27T20:00:00.000Z',
        gender: 'MALE',
        maritalStatus: 'SINGLE',
        educationLevel: 'INCOMPLETE',
        contacts: {
          email: 'at@bizmedia.ru',
          phone: '79253335102',
        },
        passport: {
          typeName: 'RUSSIAN_PASSPORT',
          docSeries: '5400',
          docNo: '090909',
          issueUnit: '770707',
          issueDate: '2002-11-01T21:00:00.000Z',
          issuedBy: 'Межгалактическим советом светлых сил',
          birthPlace: 'Ставропольский край',
          birthCountry: 'Россия',
          address: 'Карачаево-Черкесская Респ, Абазинский р-н, аул Эльбурган',
        },
        address: {
          isMatch: true,
        },
        incomes: {
          incomeVerificationType: 1,
          snils: '34234234234',
          reportIncome: 350000,
          reportIncomeRetirement: 0,
          reportIncomeRealty: 150000,
        },
        employment: {
          noEployment: true,
        },
        expenses: [
          {
            expenseType: 'CREDITS',
            amount: 3000,
          },
        ],
      },
      changedBy: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
      credit: {
        amount: 3000000,
        period: 2,
        initialPayment: 12000000,
      },
      realEstate: {
        region: '77',
        city: 'Москва',
        objectType: 3,
      },
      privacyAgreement: {
        files: ['36116929-db86-4e95-bdec-9cc620a5afe7'],
      },
      bankId: 'SOVCOMBANK',
      leadId: '79f38cbb-3ca1-452b-96dd-a14d7fbcf57c',
      leadNo: 126,
      updatedAt: '2021-01-26T12:20:11.703Z',
      createdAt: '2021-01-26T12:20:11.703Z',
      files: {
        passport: [],
        privacyAgreement: [
          {
            _id: '36116929-db86-4e95-bdec-9cc620a5afe7',
            key: 'root_applicant_privacyAgreement_agreement/25180c3b-83e8-47bd-9081-f81b7fed3836.pdf',
            url: 'https://ep-storage-dev.hb.bizmrg.com/root_applicant_privacyAgreement_agreement/25180c3b-83e8-47bd-9081-f81b7fed3836.pdf',
            ext: 'pdf',
            size: 95723,
            mime: 'application/pdf',
            name: 'agreement.pdf',
            createdBy: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
            updatedAt: '2021-01-26T12:18:43.772Z',
            createdAt: '2021-01-26T12:18:43.772Z',
          },
        ],
        realEstate: [],
      },
    },
    {
      _id: '237262f4-147f-4ab7-bdb1-cc4343382e3a',
      type: 'MORTGAGE',
      comments: 'hello',
      createdBy: {
        _id: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
        firstName: 'Анатолий',
        lastName: 'Туков',
        email: 'at@bizmedia.ru',
        phone: '79253335102',
      },
      status: 'EP_DRAFT',
      entityNo: 307,
      applicant: {
        firstName: 'Туков',
        lastName: 'Анатолий',
        middleName: 'Русланович',
        birthDate: '1981-06-27T20:00:00.000Z',
        gender: 'MALE',
        maritalStatus: 'SINGLE',
        educationLevel: 'INCOMPLETE',
        contacts: {
          email: 'at@bizmedia.ru',
          phone: '79253335102',
        },
        passport: {
          typeName: 'RUSSIAN_PASSPORT',
          docSeries: '5400',
          docNo: '090909',
          issueUnit: '770707',
          issueDate: '2002-11-01T21:00:00.000Z',
          issuedBy: 'Межгалактическим советом светлых сил',
          birthPlace: 'Ставропольский край',
          birthCountry: 'Россия',
          address: 'Карачаево-Черкесская Респ, Абазинский р-н, аул Эльбурган',
        },
        address: {
          isMatch: true,
        },
        incomes: {
          incomeVerificationType: 1,
          snils: '34234234234',
          reportIncome: 350000,
          reportIncomeRetirement: 0,
          reportIncomeRealty: 150000,
        },
        employment: {
          noEployment: true,
        },
        expenses: [
          {
            expenseType: 'CREDITS',
            amount: 3000,
          },
        ],
      },
      changedBy: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
      credit: {
        amount: 3000000,
        period: 2,
        initialPayment: 12000000,
      },
      realEstate: {
        region: '77',
        city: 'Москва',
        objectType: 3,
      },
      privacyAgreement: {
        files: ['36116929-db86-4e95-bdec-9cc620a5afe7'],
      },
      bankId: 'IPOTEKA24',
      leadId: '79f38cbb-3ca1-452b-96dd-a14d7fbcf57c',
      leadNo: 126,
      updatedAt: '2021-01-26T12:20:11.703Z',
      createdAt: '2021-01-26T12:20:11.704Z',
      files: {
        passport: [],
        privacyAgreement: [
          {
            _id: '36116929-db86-4e95-bdec-9cc620a5afe7',
            key: 'root_applicant_privacyAgreement_agreement/25180c3b-83e8-47bd-9081-f81b7fed3836.pdf',
            url: 'https://ep-storage-dev.hb.bizmrg.com/root_applicant_privacyAgreement_agreement/25180c3b-83e8-47bd-9081-f81b7fed3836.pdf',
            ext: 'pdf',
            size: 95723,
            mime: 'application/pdf',
            name: 'agreement.pdf',
            createdBy: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
            updatedAt: '2021-01-26T12:18:43.772Z',
            createdAt: '2021-01-26T12:18:43.772Z',
          },
        ],
        realEstate: [],
      },
    },
  ],
};
