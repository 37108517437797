import mock from 'common/utils/mock';
import { Site } from 'lib/models';
/// Main Service
import main from './data-main';
// Sites
import gazprom from './data-gazprom';
import domrf from './data-domrf';
import mtsbank from './data-mtsbank';

const getSiteData = () => {
  const site = process.env.REACT_APP_PROJECT as Site;
  switch (site) {
    case 'MAIN':
      return main;
    case 'DOMRF':
      return domrf;
    case 'MTSBANK':
      return mtsbank;
    case 'GAZPROM':
      return gazprom;
    default:
      return main;
  }
};
export const products = getSiteData();
mock.onGet('/api/products').reply(200, { products });
