import { getPrimaryColor } from 'theme';
import { Theme } from '@material-ui/core';
// ICONS
import {
  ShoppingCart as ShoppingCartIcon,
  PieChart as PieChartIcon,
  Briefcase as BriefcaseIcon,
  Users as UsersIcon,
  Bookmark as BookmarkIcon,
  UserCheck as PartnersIcon,
  List as RegisterIcon,
  Percent as PercentIcon,
} from 'react-feather';

import { NavConfig } from 'common/components/DashboardLayout/NavBarSimple/types';

import { applicationsRoutes } from 'sites/domrf/features/applications/constants';
import { promotionsRoutes } from 'sites/domrf/features/promotions/routes/constants';
// constants
import {
  ACTS_ROUTER_PATH_ADMIN,
  ACTS_ROUTER_PATH_AGENT,
} from 'sites/domrf/features/acts/routes/constants';
import { USERS_ROUTER_PATHS } from 'sites/domrf/features/users/routes/constants';
import { SERVICES_ROUTER_PATHS } from 'sites/domrf/features/services/routes/constants';
import { CONTRAGENTS_ROUTER_PATHS } from 'features/contragents/constants';
// components
import Label from 'common/components/Label';
// hooks
import useHasAnyRole from 'common/hooks/useHasAnyRole';
// types
import { UserRole } from 'common/model';

export const DomrfMenu = (theme: Theme): NavConfig => {
  const themeSettings = getPrimaryColor(theme);

  // get roles
  const { hasRole: hasManagerHeadRole } = useHasAnyRole(['MANAGER']);
  const { hasRole: hasCompanyOwnerRole } = useHasAnyRole(['COMPANY_OWNER']);
  const { hasRole: hasOfficeManager } = useHasAnyRole(['OFFICE_MANAGER']);
  const { hasRole: hasEpAdminRole } = useHasAnyRole(['EP_ADMIN']);

  const menu = [
    {
      subheader: 'Продукты',
      href: '/',
      roles: ['ADMIN', 'AGENT', 'MANAGER', 'DEPARTMENT_HEAD'] as UserRole[],
      items: [
        {
          title: 'Продукты',
          icon: ShoppingCartIcon,
          href: applicationsRoutes.calc,
          roles: [
            'ADMIN',
            'AGENT',
            'MANAGER',
            'DEPARTMENT_HEAD',
            'COMPANY_OWNER',
            'OFFICE_MANAGER',
            'OFFICE_STAFF',
          ] as UserRole[],
        },
        {
          title: 'Все Заявки',
          icon: BriefcaseIcon,
          href: applicationsRoutes.list,
          roles: [
            'ADMIN',
            'AGENT',
            'MANAGER',
            'DEPARTMENT_HEAD',
            'COMPANY_OWNER',
            'OFFICE_MANAGER',
            'OFFICE_STAFF',
          ] as UserRole[],
        },
        {
          title: 'Акции от Застройщиков',
          icon: PercentIcon,
          href: promotionsRoutes.root,
          roles: [
            'ADMIN',
            'AGENT',
            'MANAGER',
            'DEPARTMENT_HEAD',
            'COMPANY_OWNER',
            'OFFICE_MANAGER',
            'OFFICE_STAFF',
          ] as UserRole[],
        },
        {
          title: 'Сервисы',
          icon: BookmarkIcon,
          href: SERVICES_ROUTER_PATHS.docsChecking,
          roles: [
            'ADMIN',
            'AGENT',
            'MANAGER',
            'DEPARTMENT_HEAD',
            'COMPANY_OWNER',
            'OFFICE_MANAGER',
            'OFFICE_STAFF',
          ] as UserRole[],
        },
        {
          title: hasManagerHeadRole
            ? 'Агенты'
            : hasCompanyOwnerRole || hasOfficeManager
            ? 'Сотрудники'
            : 'Пользователи',
          icon: UsersIcon,
          href: USERS_ROUTER_PATHS.list,
          roles: [
            'ADMIN',
            'DEPARTMENT_HEAD',
            'MANAGER',
            'COMPANY_OWNER',
            'OFFICE_MANAGER',
          ] as UserRole[],
        },
        {
          title: 'Соглашения',
          icon: PartnersIcon,
          href: '/agreements',
          roles: ['ADMIN'] as UserRole[],
          info: () => <Label color={themeSettings.sidebar.badge}>admin</Label>,
        },
        {
          title: 'Профили',
          icon: PartnersIcon,
          href: CONTRAGENTS_ROUTER_PATHS.list,
          roles: ['EP_ADMIN'] as UserRole[],
          info: () => <Label color={themeSettings.sidebar.badge}>admin</Label>,
        },
        {
          title: 'Статистика',
          icon: PieChartIcon,
          href: '/dashboard',
          roles: ['ADMIN'] as UserRole[],
          info: () => <Label color={themeSettings.sidebar.badge}>admin</Label>,
        },
        {
          title: 'Акты',
          icon: RegisterIcon,
          href: ACTS_ROUTER_PATH_AGENT?.list,
          roles: ['AGENT', 'COMPANY_OWNER', 'DEPARTMENT_HEAD', 'MANAGER'] as UserRole[],
          info: () => <Label color={themeSettings.sidebar.badge}>new</Label>,
        },
      ],
    },
  ];

  /**
   * проверка на админа c определенным емейлом 421@e-place.com
   * если емейл совпадает, добавляется "Раздел Актов"
   */
  if (hasEpAdminRole) {
    menu[0].items.push({
      title: 'Акты',
      icon: RegisterIcon,
      href: ACTS_ROUTER_PATH_ADMIN?.list,
      roles: ['EP_ADMIN'] as UserRole[],
      info: () => <Label color={themeSettings.sidebar.badge}>admin</Label>,
    });
  }

  return menu;
};
