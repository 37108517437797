import apiClient from 'common/services/apiClient';
import { Id } from 'common/model/Common';
import { RequstVerifyCodeData } from 'features/auth/api/types';
import { ParamsCheckSmsCode, ResponseCheckSmsCode } from './types';

export const commonApi = {
  /**
   * Получение списка филиалов
   */
  getMortgageCenters: () => {
    return apiClient.get('/mortgage-centers');
  },

  /**
   * Получение ссылки для конкурса
   */
  getContests: () => apiClient.get('/contests'),

  /**
   * Получение данных о конкурсе
   */
  contestsStats: (id: Id) => apiClient.get(`/contests/${id}/stats`),

  /*
   * Запрос отправляет на телефон СМС подтверждение
   */
  verifyPhone: (data: RequstVerifyCodeData) => apiClient.post('/users/request-verify-phone', data),

  /**
   * Запрос на проверку СМС кода
   */
  checkSmsCode: (data: ParamsCheckSmsCode) =>
    apiClient.post<ResponseCheckSmsCode>('/users/check-sms-code', data),
};
