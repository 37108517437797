import { createSlice, PayloadAction, createEntityAdapter } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import api from './api';
import { PaginationArgs } from './ApiTypes';
import { push } from 'connected-react-router';
import { AUTH_ROUTER_PATHS } from 'features/auth/constants';

interface Agent {
  _id: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  email: string;
  phone: string;
  createdAt: string;
}

const agentsAdpter = createEntityAdapter<Agent>({
  selectId: (lead) => lead._id,
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});
// Agents slice
const agentsSlice = createSlice({
  name: 'agents',
  initialState: agentsAdpter.getInitialState({
    page: 0,
    limit: 10,
    refCode: null,
  }),
  reducers: {
    leadsReceived(state, action) {
      agentsAdpter.setAll(state, action.payload);
    },
    pageChanged: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    limitChanged: (state, { payload }: PayloadAction<number>) => {
      state.limit = payload;
    },
    refLinkeReceived: (state, { payload }: PayloadAction<string>) => {
      state.refCode = payload;
    },
  },
});

// Actions
const { leadsReceived, refLinkeReceived, pageChanged, limitChanged } = agentsSlice.actions;

// Async side effects
export const getAgents =
  ({ limit, page }: PaginationArgs): AppThunk =>
  (dispatch) => {
    console.log(limit, page);
    api.getInvitedUsers().then(({ data }) => {
      if (data.length > 0) {
        dispatch(leadsReceived(data));
      }
    });
  };

export const getRefCode = (): AppThunk => (dispatch) => {
  api.getRefCode().then(({ data }) => {
    dispatch(refLinkeReceived(data.code));
  });
};

export const registerInvite =
  (code: string): AppThunk =>
  (dispatch) => {
    api.registerInvite(code).then(() => {
      dispatch(push(AUTH_ROUTER_PATHS.signUp));
    });
  };

/**
 * Change page
 * @param page
 */
export const changePage =
  (page: number): AppThunk =>
  (dispatch, getState) => {
    dispatch(pageChanged(page));
    dispatch(getAgents({ page, limit: getState().agents.limit }));
  };

/**
 * Change page limit
 * @param limit
 */
export const changeLimit =
  (limit: number): AppThunk =>
  (dispatch, getState) => {
    dispatch(limitChanged(limit));
    dispatch(getAgents({ page: getState().agents.page, limit: limit }));
  };

// Reducer
export default agentsSlice.reducer;

// State chunks exports
export const agentsState = (state: RootState) => state.agents;
export const refCodeSelector = (state: RootState) => state.agents.refCode;
export const agentsSelector = agentsAdpter.getSelectors((state: RootState) => state.agents);
export const pageState = (state: RootState) => state.agents.page;
export const limitState = (state: RootState) => state.agents.limit;

export const allAgentsSelector = (state: RootState) => agentsSelector.selectAll(state);
