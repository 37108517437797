import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';
// Api
import { applicationApi } from 'lib/api';

type FilterState = {
  pagination: {
    page?: number;
    limit?: number;
  };
  sort: {
    sortField?: string;
    sortDirection?: 'asc' | 'desc';
  };
  filters: {
    entityNo?: number; // скорее всего строка все равно
    status?: string;
    mortgageCenterId?: string;
    product?: string;
    region?: string;
    client: string;
    phone?: string;
    email?: string;
    createdBy: string;
    bankManager: string;
    dealManager: string;
  };
};

const applicationsListState = atom<FilterState>({
  key: 'applicationsListState',
  default: {
    pagination: {
      page: 0,
      limit: 10,
    },
    sort: {
      sortField: 'createdAt',
      sortDirection: 'desc',
    },
    filters: {
      entityNo: undefined,
      status: undefined,
      product: undefined,
      region: undefined,
      mortgageCenterId: undefined,
      client: undefined,
      phone: undefined,
      email: undefined,
      createdBy: undefined,
      bankManager: undefined,
      dealManager: undefined,
    },
  },
});

/**
 * Хук запрашивает список всех заявок и фильтрует их
 */
export const useApplicationsList = () => {
  const location = useLocation();
  const [{ pagination, sort, filters }, setFilterState] = useRecoilState(applicationsListState);
  const resetFilter = useResetRecoilState(applicationsListState);

  useEffect(() => {
    resetFilter();
    // eslint-disable-next-line
  }, [location]);

  const { data, ...res } = useQuery(
    ['applications', { pagination, sort, filters }],
    async () => {
      const { page, limit } = pagination;
      const { sortDirection, sortField } = sort;
      const {
        entityNo,
        status,
        region,
        product,
        mortgageCenterId,
        client,
        phone,
        email,
        createdBy,
        bankManager,
        dealManager,
      } = filters;

      const { data } = await applicationApi.fetchApplications({
        page,
        limit,
        sort: `${sortField}:${sortDirection}`,
        filter: {
          entityNo,
          status,
          region,
          product,
          mortgageCenterId,
          client,
          phone,
          email,
          createdBy,
          bankManager,
          dealManager,
        },
      });
      return data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 20 * 1000,
    }
  );

  /**
   * Pagination
   */
  const setPage = (page: number) => {
    setFilterState((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, page },
    }));
  };

  const setLimit = (limit: number) => {
    setFilterState((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, limit },
    }));
  };

  /**
   * Sort
   */
  const setSortField = (sortField: string) => {
    setFilterState((prev) => ({
      ...prev,
      sort: { ...prev.sort, sortField },
    }));
  };

  const toggleSortDirection = () => {
    setFilterState((prev) => ({
      ...prev,
      sort: { ...prev.sort, sortDirection: prev.sort.sortDirection === 'asc' ? 'desc' : 'asc' },
    }));
  };

  /**
   * Filters
   */
  const setEntityNo = (entityNo: number) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, entityNo },
    }));
  };

  const setStatus = (status: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, status },
    }));
  };

  const setRegion = (region: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, region },
    }));
  };

  const setProduct = (product: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, product },
    }));
  };

  const setMortgageCenter = (mortgageCenterId: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, mortgageCenterId },
    }));
  };

  const setClient = (client: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, client },
    }));
  };

  const setPhone = (phone: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, phone },
    }));
  };

  const setEmail = (email: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, email },
    }));
  };

  const setCreatedBy = (createdBy: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, createdBy },
    }));
  };

  const setBankManager = (bankManager: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, bankManager },
    }));
  };

  const setDealManager = (dealManager: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, dealManager },
    }));
  };

  return {
    ...res,
    applications: data?.data || [],
    total: data?.totalCount || 0,
    resetFilter,
    pagination: {
      ...pagination,
      setPage,
      setLimit,
    },
    sort: {
      ...sort,
      setSortField,
      toggleSortDirection,
    },
    filters: {
      ...filters,
      setEntityNo,
      setStatus,
      setMortgageCenter,
      setProduct,
      setRegion,
      setClient,
      setPhone,
      setEmail,
      setCreatedBy,
      setBankManager,
      setDealManager,
    },
  };
};
