import { zenitMenu } from 'sites/zenit/settings';
import { sovcomMenu } from 'sites/sovcom/settings';
// Hooks
import { useAuth } from 'common/hooks/useAuth';
import useHasAnyRole from 'common/hooks/useHasAnyRole';
// Utils
import { site } from 'lib/constants';
// Types
import { Site } from 'lib/models';
import { MenuList } from './types';
import { mtsBankMenu } from 'sites/mtsbank/settings';

const menus: Partial<Record<Site, MenuList>> = {
  ZENIT: zenitMenu,
  SOVCOM: sovcomMenu,
  MTSBANK: mtsBankMenu
};

/**
 * Возвращает меню в зависимости от проекта
 */
export const useMenuList = (): MenuList => {
  const auth = useAuth();
  const roles = auth.authState.roles;
  const { hasRole: hasCompanyOwnerRole } = useHasAnyRole(['COMPANY_OWNER']);
  const { hasRole: hasOfficeManagerRole } = useHasAnyRole(['OFFICE_MANAGER']);

  // Метод фильтрует меню в зависимости от роли пользователя
  const filterMenuByRoles = (menu: MenuList): MenuList => {
    if (hasCompanyOwnerRole || hasOfficeManagerRole) {
      menu?.forEach((el) => {
        if (el.title === 'Пользователи') return (el.title = 'Сотрудники');
      });
    }

    return menu?.filter((item) => {
      const intersection = item.roles.filter((role) => roles?.includes(role));
      return intersection.length > 0;
    });
  };

  return menus[site] ? filterMenuByRoles(menus[site]) : [];
};
