import { RewardMethod } from '../common/model';

export const rewardMethods: RewardMethod[] = [
  {
    name: 'Физическое лицо',
    description: 'Базовая ставка. Мы удержим от 13% до 30% на НДФЛ',
  },
  {
    name: 'Самозанятый',
    description: 'Базовая ставка + 10%. Вы сами платите налоги',
  },
  {
    name: 'ИП',
    description: 'Базовая ставка + 10%. Вы сами платите налоги',
  },
  {
    name: 'ООО',
    description: 'Базовая ставка + 10%. Вы сами платите налоги. Только УСН',
  },
];
