import { useQuery } from 'react-query';
// Api
import { organizationsApi } from 'lib/api';
// Enums
import { QueryKeysEnum } from 'lib/enums';

/**
 * Загружает список созданных филиалов
 */
export const useOrganizations = () => {
  const { data, ...res } = useQuery(
    QueryKeysEnum.organizations,
    async () => {
      const { data } = await organizationsApi.fetchOrganizationOffice();
      return data;
    },
    {
      refetchOnWindowFocus: false,
      onError: (error) => console.error('Fetch organizations error: ', error),
    }
  );

  return {
    organizations: data,
    ...res,
  };
};
