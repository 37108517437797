// Api
import apiClient from 'common/services/apiClient';
import { TextBannerParams } from './types';

/**
 * Api актов
 */
export const actsApi = {
  /**
   * Создать новый текст для баннера
   */
  createTextBanner: (params: TextBannerParams) => apiClient.post('/banner?module=act', params),
};
