import React, { useCallback, ReactChild } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { SnackbarProvider, OptionsObject } from 'notistack';

interface NotificationsContextProps {
  showSuccess: (message: string) => any;
  showError: (message: string) => any;
  showWarning: (message: string) => any;
  enqueueSnackbar: (message: string, options: OptionsObject) => any;
}
export const NotificationsContext = React.createContext({
  showSuccess: (message: string) => {
    console.log(message);
  },
  showError: (message: string) => {
    console.log(message);
  },
  showWarning: (message: string) => {
    console.log(message);
  },
  enqueueSnackbar: (message: string, options: OptionsObject) => {
    console.log(message, options);
  },
});

interface NotificationsProviderProps {
  children: ReactChild;
}

const NotificationsProvider: React.FC<NotificationsProviderProps> = ({ children }) => {
  const notistackRef = React.useRef<any>();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const contextValue: NotificationsContextProps = {
    showSuccess: useCallback(
      (message) => notistackRef.current.enqueueSnackbar(message, { variant: 'success' }),
      [notistackRef]
    ),
    showError: useCallback(
      (message) => notistackRef.current.enqueueSnackbar(message, { variant: 'error' }),
      [notistackRef]
    ),
    showWarning: useCallback(
      (message) => notistackRef.current.enqueueSnackbar(message, { variant: 'warning' }),
      [notistackRef]
    ),
    enqueueSnackbar: useCallback(
      (message, options) => notistackRef.current.enqueueSnackbar(message, options),
      [notistackRef]
    ),
  };

  return (
    <NotificationsContext.Provider value={contextValue}>
      <SnackbarProvider
        ref={notistackRef}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        action={(key) => (
          <IconButton
            key={`close-${key}`}
            aria-label="Закрыть"
            color="inherit"
            onClick={onClickDismiss(key)}
          >
            <CloseIcon />
          </IconButton>
        )}
      >
        {children}
      </SnackbarProvider>
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
