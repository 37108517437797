import { FC } from 'react';
// Mui components
import { Stack, Typography } from '@mui/material';

interface Props {
  size?: 'small' | 'medium';
}

/**
 * Компонент обертка, показывающий блок видимый только для администратора
 */
export const AdminWrapper: FC<Props> = ({ size = 'medium', children }) => {
  const isSmall = size === 'small';

  return (
    <Stack
      sx={{
        padding: ({ spacing }) => `0 0 0 ${isSmall ? spacing(1) : spacing(2)}`,
        borderLeft: ({ palette }) => `2px solid ${palette.primary.main}`,
      }}
      gap={isSmall ? 0.5 : 1}
    >
      <Typography variant={isSmall ? 'h6' : 'h4'} color="primary">
        ADMIN
      </Typography>

      {children}
    </Stack>
  );
};
