import apiClient from 'common/services/apiClient';
// Data types
import {
  CheckExistEmailData,
  CheckExistPhoneData,
  CheckSmsCodeData,
  RequstVerifyCodeData,
  RegisterUserData,
  GetOrganizationByInnData,
} from './types';
// Response types
import {
  CheckExistEmailResponse,
  CheckExistPhoneResponse,
  CheckSmsCodeResponse,
  RequstVerifyCodeResponse,
  RegisterUserResponse,
  GetOrganizationByInnResponse,
} from './types';
// Config
import { site } from 'lib/constants';

export const authApi = {
  /**
   * Метод проверяет зарегистрирован ли уже введенный email
   * Метод вернет:
   * - 200, если email не зарегистрирован
   * - 422, если email уже зарегистрирован
   */
  checkExistEmail: async (data: CheckExistEmailData) => {
    const res = await apiClient.post<CheckExistEmailResponse>('/accounts/check-email', data);
    return res.data;
  },

  /**
   * Метод проверяет зарегистрирован ли уже введенный phone
   * Метод вернет:
   * - 200, если phone не зарегистрирован
   * - 422, если phone уже зарегистрирован
   */
  checkExistPhone: async (data: CheckExistPhoneData) => {
    const res = await apiClient.post<CheckExistPhoneResponse>('/accounts/check-phone', data);
    return res.data;
  },

  /**
   * Метод проверяет правильность введенного кода, для телефона
   * Метод вернет:
   * - 200, если введен верный код
   * - 404, если введен не верный код
   */
  checkSmsCode: async (data: CheckSmsCodeData) => {
    const res = await apiClient.post<CheckSmsCodeResponse>('/accounts/check-sms-code', data);
    return res.data;
  },

  /**
   * Метод отправляет телефон для верификации и отправки смс-кода
   */
  requestVerifyPhone: async (data: RequstVerifyCodeData) => {
    const res = await apiClient.post<RequstVerifyCodeResponse>(
      '/accounts/request-verify-phone',
      data
    );
    return res.data;
  },

  /**
   * Метод регистрирует пользователя
   * Вернет id пользователя при удачной регистрации
   */
  registerUser: async (data: RegisterUserData) => {
    console.log('data', data);
    const res = await apiClient.post<RegisterUserResponse>('/accounts/register', data);
    return res.data;
  },

  /**
   * Метод загружает данные ИНН
   */
  getOrganizationByInn: async (data: GetOrganizationByInnData) => {
    const res = await apiClient.get<GetOrganizationByInnResponse[]>('/dadata/inn', {
      params: data,
    });
    return res.data;
  },

  checkCompanyExist: async (inn: string) => {
    if (site === 'ZENIT') {
      const { data } = await apiClient.post('/profiles/check-company', { inn });
      return data;
    }
    const res = await apiClient.post('/profile/check-company', { inn });
    return res.data;
  },
};
