import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { store } from 'app/store';
import { enqueueSnackbarSuccess } from 'modules/notification/notistackSlice';

type AxiosRequestConfigWithRetry = AxiosRequestConfig & { _retry: boolean };
let isAlreadyFetchingAccessToken = false;
const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
};

const apiClient = axios.create(config);

const refreshToken = async () => await apiClient.get('/auth/refresh');
const logout = () => {
  if (typeof window !== 'undefined') {
    const authState = { loggedIn: false };
    window.localStorage.setItem('authState', JSON.stringify(authState));
    window.location.replace('/auth/login');
  }
};
// Request interceptor for API calls

apiClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest: AxiosRequestConfigWithRetry =
      error.config as AxiosRequestConfigWithRetry;
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        originalRequest._retry = true;
        try {
          await refreshToken();
        } catch (error) {
          logout();
        }
        isAlreadyFetchingAccessToken = false;
        return axios.request(originalRequest);
      }
    }
    if (error.response?.status !== 401) {
      showErrorMessage(error);
    }
    return Promise.reject(error);
  }
);

export default apiClient;

/**
 * CONFIG FOR MULTIPART FILE UPLOADS
 *
 */

const multipartConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
    accept: 'application/json',
  },
};
export const apiMultipartClient = axios.create(multipartConfig);
apiMultipartClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest: AxiosRequestConfigWithRetry =
      error.config as AxiosRequestConfigWithRetry;
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;
        originalRequest._retry = true;
        await refreshToken();
        isAlreadyFetchingAccessToken = false;
        return axios.request(originalRequest);
      }
    }
    if (error.response?.status !== 401) {
      showErrorMessage(error);
    }
    return Promise.reject(error);
  }
);

/*
COMMON FUNCTIONS
*/

function showErrorMessage(error: AxiosError) {
  const errorMsg = extractErrorMsg(error);

  if (Array.isArray(errorMsg)) {
    errorMsg.forEach((err) => {
      displayError(err);
    });
  } else {
    displayError(`${errorMsg}`);
  }
}

/**
 * Extracts Error Message
 * @param error
 */
function extractErrorMsg(error: AxiosError<any>): string | string[] {
  const { response, message } = error;
  const request: XMLHttpRequest | undefined = error.request;

  // Server responded with a status code that falls out of the range of 2xx
  if (response) {
    if (response.data?.message) {
      return response.data.message;
    } else if (response.data?.error?.message) {
      return response.data.error.message;
    } else if (response.data?.error?.inner) {
      return response.data.error.inner;
    }

    return response.statusText;
  }
  // The request was made but no response was received
  else if (request) return 'Unexpected error occured';

  // Something happened in setting up the request that triggered an Error
  return message;
}

/**
 * Displays Error Message
 * @param message
 */
const displayError = (message: string) => {
  store.dispatch(enqueueSnackbarSuccess({ message, options: { variant: 'error' } }));
};
