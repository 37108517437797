import { ChartSeries } from '../../../../model';
export const data_month: ChartSeries[] = [
  {
    data: [
      { x: 'Aug-2021', y: 10 },
      { x: 'Sep-2021', y: 60 },
      { x: 'Oct-2021', y: 478 },
      { x: 'Nov-2021', y: 521 },
    ],
  },
];
