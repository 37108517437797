import { User } from 'common/model';
import apiClient from 'common/services/apiClient';
import { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

interface AuthContext {
  approveEmail: () => void;
  authorize: () => Promise<any>;
  authorized: boolean;
  authState: AuthState;
  checkAuth: () => boolean;
  getAuthState: () => AuthState;
  logout: () => void;
  user: User | null;
  signout: () => void;
  validAuth: () => void;
}
interface BaseAuthState {
  loggedIn: boolean;
}
export interface AuthState extends BaseAuthState {
  id?: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  email?: string;
  emailConfirmed?: boolean;
  expires?: string;
  phone?: string;
  registeredAt?: string;
  timeToLive?: number;
  roles?: string[];
}

const authContext = createContext<AuthContext>({
  approveEmail: () => {},
  authorized: false,
  authState: { loggedIn: false },
  getAuthState: () => ({} as AuthState),
  authorize: () => Promise.resolve(),
  checkAuth: () => false,
  logout: () => {},
  user: null,
  signout: () => {},
  validAuth: () => {},
});

function useProvideAuth() {
  const localAuthState = typeof window !== 'undefined' && window?.localStorage.getItem('authState');
  const [authState, setAuthState] = useState<AuthState>(
    localAuthState ? JSON.parse(localAuthState) : { loggedIn: false }
  );
  const [authorized, setAuthorized] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const router = useHistory();

  useEffect(() => {
    (async () => {
      if (typeof window !== 'undefined') {
        window.addEventListener('storage', listenToken);
        const authState: AuthState = getAuthState();
        setAuthState(authState);
        if (authState && authState.loggedIn) {
          await authorize();
        }
      }
    })();
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const authorize = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await apiClient.get<User>('/auth/state');
        const authState = { ...response.data, loggedIn: true };
        window.localStorage.setItem('authState', JSON.stringify(authState));
        // try {
        //   const tracker = new Tracker({
        //     projectKey: process.env.REACT_APP_OPENREPLAY,
        //     ingestPoint: 'https://openreplay.e-place.com/ingest',
        //     __DISABLE_SECURE_MODE: true,
        //   });
        //   tracker.use(
        //     trackerAxios({
        //       instance: apiClient,
        //       failuresOnly: false,
        //     })
        //   );
        //   tracker.start({
        //     userID: authState._id,
        //     metadata: {
        //       email: authState.email,
        //       roleMain: authState.roles[0],
        //       environment: process.env.REACT_APP_ENVIRONMENT,
        //       mortgageCenter: authState.mortgageCenter,
        //       phone: authState.phone,
        //     },
        //     forceNew: true,
        //   });
        // } catch (error) {
        //   console.error(error);
        // }

        resolve(authState);
        setAuthorized(true);
        setAuthState(authState);
        await login();
      } catch (e) {
        if (e.statusCode === 401) {
          window.localStorage.setItem('authState', JSON.stringify({ loggedIn: false }));
          setAuthState({ loggedIn: false });
          setAuthorized(false);
        }
        reject(e);
      }
    });
  };

  const getAuthState = () => {
    if (typeof window !== 'undefined') {
      const state = window.localStorage.getItem('authState');
      if (state) {
        return JSON.parse(state);
      }
    }
    return { loggedIn: false };
  };

  const approveEmail = () => {
    const authState = getAuthState();
    authState.emailConfirmed = true;
    setAuthState(authState);
    window.localStorage.setItem('authState', JSON.stringify(authState));
  };

  const listenToken = async (e: any) => {
    const { key, newValue } = e;
    if (key === 'authState') {
      if (!newValue) {
        await logout();
      } else {
        const newState = JSON.parse(newValue);
        if (!newState?.loggedIn) {
          await logout();
        }
      }
    }
  };

  const signout = async () => {
    if (typeof window !== 'undefined') {
      const authState = { loggedIn: false };
      window.localStorage.setItem('authState', JSON.stringify(authState));
      setAuthorized(false);
      setAuthState(authState);
      try {
        await apiClient.post('/auth/logout');
      } catch (e) {}
      router.replace('/auth/login');
    }
  };
  const login = async () => {
    try {
      //FIXME: Сделать на бэке более понятный эндпоинт, например метод loginWithEmail
      const res = await apiClient.get('/users/me');

      setUser(res.data);
    } catch (e) {
      console.log(e);
    }
  };
  const logout = () => {
    router.push('/auth/logout');
  };

  const validAuth = async () => {
    try {
      const response = await apiClient.get<User>('/auth/state');
      const authState = { ...response.data, loggedIn: true };
      window.localStorage.setItem('authState', JSON.stringify(authState));
      setAuthState(authState);
      setAuthorized(true);
    } catch (e) {
      if (e.statusCode === 401) {
        window.localStorage.setItem('authState', JSON.stringify({ loggedIn: false }));
        setAuthState({ loggedIn: false });
        setAuthorized(false);
      }
    }
  };
  const checkAuth = () => {
    let result = false;
    if (typeof window !== 'undefined') {
      const authState = getAuthState();
      result = authState.loggedIn;
    }
    return result;
  };
  // Return the user object and auth methods

  return {
    approveEmail,
    authorized,
    authorize,
    checkAuth,
    getAuthState,
    authState,
    user,
    logout,
    signout,
    validAuth,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
