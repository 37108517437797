import { FC, lazy } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Mui components
import { Box, Link, Divider, Typography, useTheme, useMediaQuery } from '@mui/material';

interface Props {
  link?: string;
}

/**
 * Компонент подставляющий логотипы банков
 */
export const Logo: FC<Props> = ({ link }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const site = process.env.REACT_APP_PROJECT;

  const LogoBySite = lazy(async () => {
    try {
      const DynamicLogo = await import(`sites/${site.toLowerCase()}/settings/Logo`);
      return DynamicLogo;
    } catch (error) {
      console.log('Error importing logo', error);
    }
  });

  const InnerLogo = (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: ({ spacing }) => spacing(2) }}>
      <LogoBySite />
      {!isMobile && (
        <>
          <Divider sx={{ height: 40 }} orientation="vertical" />
          <Typography sx={{ textTransform: 'uppercase' }} variant="h4">
            Партнеры
          </Typography>
        </>
      )}
    </Box>
  );

  return link ? (
    <Link
      sx={{ color: ({ palette }) => palette.text.primary, textDecoration: 'none' }}
      component={RouterLink}
      to={link}
    >
      {InnerLogo}
    </Link>
  ) : (
    InnerLogo
  );
};
