import { useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
// Hooks
import { useNotifications } from 'modules/notification';
// Enums
import { QueryKeysEnum } from 'lib/enums';
// Api
import { applicationApi } from 'lib/api';

type UpdateApplicationParams = {
  id: string;
  dealManagerId: string;
};

/**
 * Хук (мутация обернутая в хук) прикрепляет текущего авторизованного пользователя как менеджера заявки
 */
export const useAttachApplication = () => {
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useNotifications();
  const { id } = useParams<{ id: string }>();

  return useMutation(
    ({ id, dealManagerId }: UpdateApplicationParams) =>
      applicationApi.updateApplication({ id, update: { dealManagerId } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeysEnum.application, { id }]);
        showSuccess('Вы успешно закрепили заявку');
      },
      onError: (error) => {
        showError('Закрепить заявку не удалось');
        console.error('Attach application error: ', error);
      },
    }
  );
};
