import { FC, cloneElement, useState, ReactNode } from 'react';
// Material 5
import {
  Stack,
  Divider,
  Dialog,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
// Icons
import CloseIcon from '@mui/icons-material/Close';

type ConfirmDialogProps = {
  /**
   * Заголовок диалогового окна
   */
  title: string;
  /**
   * Текст в контент части диалогового окна
   */
  content?: string | ReactNode;
  /**
   * Включить выключить диалоговое окно на всю ширину
   */
  fullWidth?: boolean;
  /**
   * Кнопки для диалогового окна
   */
  buttons: ReactNode;
  /**
   * Кнопка открывающая диалоговое окно
   */
  children: JSX.Element;
};

/**
 * Компонент отображающий диалоговое, модальное окно для подтверждения действий
 */
export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  title,
  content,
  fullWidth = true,
  buttons,
  children,
}) => {
  const [open, setOpen] = useState(false);

  const buttonActiveDialog = cloneElement(children, {
    onClick: () => setOpen(true),
  });

  const onClickDialogCloseHandler = () => setOpen(false);

  return (
    <>
      {buttonActiveDialog}

      <Dialog open={open} onClose={onClickDialogCloseHandler} maxWidth="md" fullWidth={fullWidth}>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">{title}</Typography>

            <IconButton size="small" onClick={onClickDialogCloseHandler}>
              <CloseIcon />
            </IconButton>
          </Stack>
        </DialogTitle>

        <Divider />

        {content && (
          <DialogContent>
            <Typography variant="body2">{content}</Typography>
          </DialogContent>
        )}

        {buttons && <DialogActions>{buttons}</DialogActions>}
      </Dialog>
    </>
  );
};
