import { FC } from 'react';
// Ui components
import { ActionButton } from 'lib/ui';
// Hooks
import { useCreateApplication } from 'lib/hooks/mutations';
import useHasAnyRole from 'common/hooks/useHasAnyRole';
import type { ActionButtonProps } from 'lib/ui/ActionButton';

interface Props {
  invalidateKey?: string;
  route?: (id: string) => string;
}

/**
 * Кнопка создания заявки
 */
export const CreateApplicationButton: FC<Props & Partial<ActionButtonProps>> = ({
  invalidateKey,
  route,
  ...restProps
}) => {
  /**
   * Hooks
   */
  const { hasRole: hasDepartmentHeadRole } = useHasAnyRole(['DEPARTMENT_HEAD']);
  const { hasRole: hasManagerRole } = useHasAnyRole(['MANAGER']);
  const { hasRole: hasDealAdminRole } = useHasAnyRole(['DEAL_ADMIN']);
  const { hasRole: hasDealHeadRole } = useHasAnyRole(['DEAL_HEAD']);
  const { hasRole: hasDealManagerRole } = useHasAnyRole(['DEAL_MANAGER']);

  /**
   * Constants
   */
  const disabled =
    hasManagerRole ||
    hasDepartmentHeadRole ||
    hasDealAdminRole ||
    hasDealHeadRole ||
    hasDealManagerRole;

  /**
   * Mutations
   */
  const createMutation = useCreateApplication({ invalidateKey, route });

  /**
   * Handlers
   */

  const onMutationHandler = () => createMutation.mutate();
  return (
    <ActionButton
      title="Создать заявку"
      color="primary"
      variant="contained"
      fullWidth
      loading={createMutation.isLoading}
      onClick={onMutationHandler}
      disabled={disabled}
      {...restProps}
    />
  );
};
