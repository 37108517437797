import { FC, ReactNode } from 'react';
// Material 5
import { IconButtonProps, Tooltip, IconButton, CircularProgress } from '@mui/material';

interface ActionIconButtonProps extends IconButtonProps {
  icon: ReactNode;
  title?: string;
  loading?: boolean;
}

/**
 * Кнопка только с иконкой
 */
export const ActionIconButton: FC<ActionIconButtonProps> = ({
  icon,
  title = '',
  loading = false,
  ...iconButtonProps
}) => {
  return (
    <Tooltip title={title}>
      <IconButton {...iconButtonProps}>
        {loading ? <CircularProgress size={22} color="inherit" /> : icon}
      </IconButton>
    </Tooltip>
  );
};
