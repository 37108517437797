import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Grid, Typography, makeStyles, Container, Box } from '@material-ui/core';
import Logo from 'common/components/Logo';
import Image from './ErrorImage';
const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(0),
    backgroundColor: theme.palette.background.paper,
  },
  logo: {
    margin: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  container: {
    padding: theme.spacing(5, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
  },
  imgBackground: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subTitle: {
    marginTop: theme.spacing(2),
    marginBotton: theme.spacing(2),
  },
  extra: {
    margin: theme.spacing(3, 0, 2),
  },
}));
type ResultProps = {
  className?: string;
  status: number | string;
  title: string;
  subTitle?: string;
  extra?: ReactNode;
};
const Result: React.FC<ResultProps> = ({ className, status, title, subTitle, extra }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Grid container spacing={0}>
        <Grid item md={6}>
          <div className={classes.logo}>
            <Logo />
          </div>
          <Container className={classes.container}>
            <Typography variant="h3" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h5" gutterBottom>
              {status} ошибка
            </Typography>
            <Typography className={classes.subTitle} variant="body1">
              {subTitle}
            </Typography>
            <Box className={classes.extra}>{extra}</Box>
          </Container>
        </Grid>
        <Grid item md={6} className={classes.imgBackground}>
          <Image />
        </Grid>
      </Grid>
    </div>
  );
};

export default Result;
