import { FC, useState, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
// Mui components
import { Box } from '@mui/material';
// Components
import { Header } from './Header';
import { Sidebar } from './Sidebar';
import { Content } from './Content';
// Hooks
import { useAuth } from 'common/hooks/useAuth';
// import { useStyles } from 'common/ui/Layout/Layout.styles';

interface Props {
  publicRedirectTo: string;
}

/**
 * Раскладка страницы для авторизованного пользователя
 */
export const PrivateLayout: FC<Props> = ({ publicRedirectTo, children }) => {
  // const classes = useStyles();
  const auth = useAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  /**
   * Handlers
   */

  const handleClickMenuIcon = useCallback(() => setSidebarOpen(true), []);

  const handleClose = useCallback(() => setSidebarOpen(false), []);

  return auth.authState.loggedIn ? (
    // <div className={classes.layout}>
    // </div>
    <>
      <Header onClickMenuIcon={handleClickMenuIcon} />
      <Box sx={{ display: 'flex', height: '100%' }}>
        <Sidebar open={sidebarOpen} onClose={handleClose} />
        <Content children={children} />
      </Box>
    </>
  ) : (
    <Redirect to={publicRedirectTo} />
  );
};
