import {
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil';

type UserAvatarAtom = {
  src: string;
};

/**
 * Состояние касающееся изображения профиля пользователя
 */
const userAvatarAtom = atom<UserAvatarAtom>({
  key: 'userAvatarAtom',
  default: {
    src: `${process.env.REACT_APP_API_URL}/users/avatar`,
  },
});

export const useUserAvatar = () => useRecoilState(userAvatarAtom);
export const useUserAvatarValue = () => useRecoilValue(userAvatarAtom);
export const useSetUserAvatar = () => useSetRecoilState(userAvatarAtom);
export const useResetUserAvatar = () => useResetRecoilState(userAvatarAtom);
