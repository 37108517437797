import { site } from 'lib/constants';

export const UserRoleEnum = {
  ADMIN: 'Администратор',
  DEPARTMENT_HEAD: site === 'DOMRF' ? 'Руководитель ЦИК' : 'Руководитель отделения',
  MANAGER: site === 'DOMRF' ? 'Менеджер ЦИК' : 'Менеджер отделения',

  DEAL_ADMIN: 'Администратор заявок',
  DEAL_HEAD: 'Руководитель заявок',
  DEAL_MANAGER: 'Менеджер заявок',

  COMPANY_OWNER: 'Владелец компании',
  OFFICE_MANAGER: 'Руководитель офиса',
  OFFICE_STAFF: 'Сотрудник',
  AGENT: 'Партнер',
} as const;
