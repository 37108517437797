export const checkDocsBookmarks = [
  {
    title: 'Действительность паспорта гражданина РФ',
    label: 'services.fms.gov.ru',
    url: 'http://services.fms.gov.ru/info-service.htm?sid=2000',
  },
  {
    title: 'Соответствие паспорта и адреса регистрации',
    label: 'services.fms.gov.ru',
    url: 'http://services.fms.gov.ru/info-service.htm?sid=2160',
  },
  {
    title: 'Узнать ИНН физлица',
    label: 'service.nalog.ru',
    url: 'https://service.nalog.ru/inn.do',
  },
  {
    title: 'Проверить коррректность ИНН',
    label: 'egrul.ru',
    url: 'https://www.egrul.ru/inn.html',
  },
  {
    title: 'Проверить СНИЛС',
    label: 'pfrf.ru',
    url: 'http://www.pfrf.ru/branches/orel/news/~2020/04/15/203737',
  },
  {
    title: 'Действительность водительского удостоверения',
    label: 'гибдд.рф',
    url: 'https://гибдд.рф/check/driver/#+',
  },
  {
    title: 'Действительности разрешений на работу и патентов',
    label: 'services.fms.gov.ru',
    url: 'http://services.fms.gov.ru/info-service.htm?sid=2060',
  },
  {
    title: 'Проверка доверенностей по реквизитам',
    label: 'reestr-dover.ru',
    url: 'https://www.reestr-dover.ru',
  },
  {
    title: 'Действительность приглашений на въезд в РФ',
    label: 'services.fms.gov.ru',
    url: 'http://services.fms.gov.ru/info-service.htm?sid=2061',
  },
  {
    title: 'Наличие запрета на въезд на территорию РФ',
    label: 'services.fms.gov.ru',
    url: 'http://services.fms.gov.ru/info-service.htm?sid=2061',
  },
  {
    title: 'Проверка электронного документа',
    label: 'rosreestr.gov.ru',
    url: 'https://rosreestr.gov.ru/wps/portal/cc_vizualisation',
  },
  {
    title: 'Проверка электронной подписи',
    label: 'crypto.kontur.ru',
    url: 'https://crypto.kontur.ru/verify',
  },
];
