import { Theme } from '@material-ui/core';
import { site } from 'lib/constants';
import { Site } from 'lib/models';
// Main
import main, { getMainThemeSettings } from 'sites/main/theme';
import mainMessages from 'sites/main/theme/messages';
// Domrf
import domrf, { getDomRfThemeSettings } from 'sites/domrf/theme';
import domRfMessages from 'sites/domrf/theme/messages';
// Gazprom
import gazprom, { getGazpromThemeSettings } from 'sites/gazprom/theme';
import gazpromMessages from 'sites/gazprom/theme/messages';
// Zenit
import zenit from 'sites/zenit/theme';
// Sovcom
import sovcom from 'sites/sovcom/theme';

import mtsbank from 'sites/mtsbank/theme';

import { ThemeSettings, ThemeMessages } from './types';

/**
 * Старые темы для материала 4 версии
 */
const themes: Partial<Record<Site, () => any>> = {
  MAIN: main,
  DOMRF: domrf,
  GAZPROM: gazprom,
  ZENIT: zenit,
  SOVCOM: sovcom,
  MTSBANK: mtsbank,
};

export const getThemes = themes[site];

export const getThemeMessages = (): ThemeMessages => {
  switch (site) {
    case 'MAIN':
      return mainMessages;
    case 'DOMRF':
      return domRfMessages;
    case 'GAZPROM':
      return gazpromMessages;
    default:
      return mainMessages;
  }
};

/**
 * Theme helper functions used in components
 */
export type ColorRole = 'primary' | 'secondary';

export interface GetThemeColorsPayload {
  products: string;
  productsBadge: string;
  sidebar: string;
  sidebarInfo: ColorRole;
  role: ColorRole;
  button: ColorRole;
}

export const getPrimaryColor = (theme: Theme): ThemeSettings => {
  const getThemeSettings = (): ThemeSettings => {
    switch (site) {
      case 'DOMRF':
        return getDomRfThemeSettings(theme);
      case 'GAZPROM':
        return getGazpromThemeSettings(theme);
      default:
        return getMainThemeSettings(theme);
    }
  };

  const themeSettings = getThemeSettings();
  return themeSettings;
};
