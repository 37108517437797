import { useMutation, useQueryClient } from 'react-query';
// Api
import { applicationApi } from 'lib/api';
// Hooks
import { useNotifications } from 'modules/notification';
// Enums
import { QueryKeysEnum } from 'lib/enums';
// Types
import { ChangeApplicationStatusParams } from 'lib/api/applicationApi';
import { Id } from 'lib/models';

/**
 * Хук (мутация) который меняет статус текущей заявки
 */
export const useChangeApplicationStatus = (id: Id) => {
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useNotifications();

  return useMutation(
    ({ id, changes }: ChangeApplicationStatusParams) =>
      applicationApi.changeApplicationStatus({ id, changes }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeysEnum.application, { id }]);
        showSuccess('Статус успешно обновлен');
      },
      onError: () => showError('Не удалось обновить статус'),
    }
  );
};
