import mock from 'common/utils/mock';
// Количество заявок
import { data_day as appsCountDayMock } from 'sites/domrf/features/analytics/mock/apps/count/day';
import { data_month as appsCountMonthMock } from 'sites/domrf/features/analytics/mock/apps/count/month';
import { data_year as appsCountYearMock } from 'sites/domrf/features/analytics/mock/apps/count/year';
// Сумма выданных кредитов
import { data_day as loansVolumeDay } from 'sites/domrf/features/analytics/mock/loans/volume/day';
import { data_month as loansVolumeMonth } from 'sites/domrf/features/analytics/mock/loans/volume/month';
import { data_year as loansVolumeYear } from 'sites/domrf/features/analytics/mock/loans/volume/year';
// КОличество выданных кредитов
import { data_day as loansCountDay } from 'sites/domrf/features/analytics/mock/loans/count/day';
import { data_month as loansCountMonth } from 'sites/domrf/features/analytics/mock/loans/count/month';
import { data_year as loansCountYear } from 'sites/domrf/features/analytics/mock/loans/count/year';

/**
 * Количество продаж
 */
mock
  .onGet('/api/analytics/apps/count', {
    params: { binning: 'day' },
  })
  .reply(200, appsCountDayMock);

mock
  .onGet('/api/analytics/apps/count', {
    params: { binning: 'month' },
  })
  .reply(200, appsCountMonthMock);

mock
  .onGet('/api/analytics/apps/count', {
    params: { binning: 'year' },
  })
  .reply(200, appsCountYearMock);

/**
 * Объем выданных кредитов
 */
mock
  .onGet('/api/analytics/loans/volume', {
    params: { binning: 'day' },
  })
  .reply(200, loansVolumeDay);

mock
  .onGet('/api/analytics/loans/volume', {
    params: { binning: 'month' },
  })
  .reply(200, loansVolumeMonth);

mock
  .onGet('/api/analytics/loans/volume', {
    params: { binning: 'year' },
  })
  .reply(200, loansVolumeYear);

/**
 * Количество выданных кредитов
 */
mock
  .onGet('/api/analytics/loans/count', {
    params: { binning: 'day' },
  })
  .reply(200, loansCountDay);

mock
  .onGet('/api/analytics/loans/count', {
    params: { binning: 'month' },
  })
  .reply(200, loansCountMonth);

mock
  .onGet('/api/analytics/loans/count', {
    params: { binning: 'year' },
  })
  .reply(200, loansCountYear);
