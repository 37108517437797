import { Product } from 'common/model';
var products: Product[] = [
    {
        id: 'mortage',
        thumbnail: '/static/images/undraw_house_searching_n8mp.svg',
        title: 'Ипотека',
        shortTitle: 'Ипотека',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Условия кредита и программы Банков смотрите на официальных сайтах Банков (для перехода нажмите на логотип Банка). Важно! Но заявку в Банк передавайте только через e-place!',
        ],
        active: true,
        type: 'MORTGAGE',
    },

    {
        id: 'refin-mortgage',
        type: 'REFIN_MORTGAGE',
        thumbnail: '/static/images/undraw_For_sale_re_egkk.svg',
        title: 'Рефинансирование ипотеки',
        shortTitle: 'Рефин. ипотеки',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: true,
        badge: '',
    },
    {
        id: 'HOME_EQUITY_CREDIT',
        type: 'HOME_EQUITY_CREDIT',
        thumbnail: '/static/images/undraw_buy_house_560d.svg',
        title: 'Кредит под залог недвижимости',
        hideMore: true,
        badge: 'Скоро',
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
    {
        id: 'CREDIT_CASH',
        type: 'CREDIT_CASH',
        thumbnail: '/static/images/undraw_buy_house_560d.svg',
        title: 'Кредит наличными',
        badge: 'Скоро',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
    {
        id: 'autocredit',
        type: 'AUTO_CREDIT',
        thumbnail: '/static/images/undraw_apartment_rent_o0ut.svg',
        title: 'Автокредит',
        hideMore: true,
        badge: 'Скоро',
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
    {
        id: 'debet_card',
        type: 'DEBET_CARD',
        thumbnail: '/static/images/undraw_factory_dy0a.svg',
        title: 'Дебетовая карта',
        badge: 'Скоро',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
    {
        id: 'credit_card',
        type: 'CREDIT_CARD',
        thumbnail: '/static/images/undraw_factory_dy0a.svg',
        title: 'Кредитная карта',
        badge: 'Скоро',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
    {
        id: 'INSURANCE',
        type: 'INSURANCE',
        thumbnail: '/static/images/undraw_factory_dy0a.svg',
        title: 'Страхование',
        badge: 'Скоро',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
    {
        id: 'INVESTMENT',
        type: 'INVESTMENT',
        thumbnail: '/static/images/undraw_factory_dy0a.svg',
        title: 'Инвестиции',
        badge: 'Скоро',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
    {
        id: 'SALARY_PROJECT',
        type: 'SALARY_PROJECT',
        thumbnail: '/static/images/undraw_factory_dy0a.svg',
        title: 'Зарплатный проект',
        badge: 'Скоро',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
    {
        id: 'CASH_SERVICE',
        type: 'CASH_SERVICE',
        thumbnail: '/static/images/undraw_factory_dy0a.svg',
        title: 'Расчетно-кассовое обслуживание',
        badge: 'Скоро',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
    {
        id: 'ACQUIRING',
        type: 'ACQUIRING',
        thumbnail: '/static/images/undraw_factory_dy0a.svg',
        title: 'Эквайринг',
        badge: 'Скоро',
        hideMore: true,
        benefits: [
            {
                icon: 'money',
                label: 'Вознаграждение',
                amount: '3%',
                highlight: true,
            },
        ],
        conditions: [
            'Ставка по кредиту: от 5,6%',
            'Сумма: до 5 000 000 ₽',
            'Первоначальный взнос: 0 ₽',
            'Новый или б/у автомобиль',
            'Без обязательного КАСКО',
            'Без посещения Банка',
        ],
        active: false,
    },
];

export default products;
