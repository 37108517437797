/**
 * Pollyfils
 */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

/**
 * React Perfect Scrollbar
 */
import 'react-perfect-scrollbar/dist/css/styles.css';

/**
 * Initialise store
 */
import 'app/store';
import 'mocks';
import { enableES5 } from 'immer';
enableES5();
require('raf').polyfill();
