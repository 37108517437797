import { useTheme } from '@material-ui/core';

const SvgSketchSelectedSvg = (props) => {
  const theme = useTheme();
  const primaryColor = theme.palette.secondary.main;
  const bugColor = theme.palette.type === 'dark' ? '#FFF' : '#000';
  return (
    <svg width={690} height={449} {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M211.813 420.11c4.668-10.083 9.333-20.316 11.36-31.241 2.025-10.926 1.175-22.783-4.628-32.26-5.802-9.476-17.228-15.902-28.131-13.76-8.955 1.76-16.146 8.813-20.357 16.91-4.21 8.097-5.9 17.23-7.537 26.209-.53-10.2-1.09-20.565-4.478-30.2-3.388-9.636-10.06-18.615-19.57-22.343-9.508-3.729-21.878-.797-26.89 8.103-7.06 12.538 2.302 30.125-5.817 42.006-1.393-11.917-13.855-21.333-25.7-19.416-11.843 1.916-20.7 14.781-18.264 26.53 1.45 6.99 6.22 12.936 11.922 17.231 5.702 4.296 12.334 9.275 18.895 12.09l119.195.142z"
          fill={primaryColor}
        />
        <path
          d="M68.02 372.537c9.408 3.289 18.903 6.615 27.492 11.756 7.698 4.608 14.553 10.812 18.882 18.757a33.356 33.356 0 0 1 4.126 13.858c.063 1.018 1.655 1.025 1.592 0-.557-9.06-4.972-17.353-11.087-23.91-6.704-7.19-15.392-12.1-24.361-15.91-5.318-2.26-10.769-4.18-16.221-6.085-.969-.339-1.385 1.198-.423 1.534zM122.287 335.004a143.383 143.383 0 0 1 13.792 30.616 145.117 145.117 0 0 1 6.36 32.968c.464 6.27.515 12.562.152 18.837-.059 1.024 1.533 1.02 1.592 0a145.194 145.194 0 0 0-2.04-33.82 146.927 146.927 0 0 0-9.77-32.44 143.25 143.25 0 0 0-8.711-16.964.796.796 0 0 0-1.375.803zM200.188 344.147a232.044 232.044 0 0 0-17.116 57.579 234.487 234.487 0 0 0-2.078 16.811c-.09 1.02 1.503 1.015 1.591 0a231.21 231.21 0 0 1 18.977-73.587c.412-.928-.959-1.738-1.374-.803z"
          fill="#FFF"
        />
        <path fill="#E6E6E6" d="M482.345 431.91l.128 9.88-46.584.603-.128-9.88z" />
        <path
          d="M499.027 443.996l-6.653-.02-4.112-7.337 4.445-7.312 6.18.018a11.999 11.999 0 1 0 .14 14.65zM419.208 443.996l6.653-.02 4.112-7.337-4.445-7.312-6.18.018a11.999 11.999 0 1 1-.14 14.65z"
          fill="#E6E6E6"
        />
        <path d="M689 420.97H1a1 1 0 1 1 0-2h688a1 1 0 1 1 0 2z" fill="#3F3D56" />
        <circle fill="#2F2E41" cx={540.486} cy={197.969} r={33} />
        <path fill="#FFB8B8" d="M553.859 383.226l2.542 11.993 47.47-4.095-3.751-17.701z" />
        <path
          d="M550.166 420.71l-7.988-37.694 14.563-3.086 4.902 23.13c1.704 8.043-3.434 15.945-11.477 17.65z"
          fill="#2F2E41"
        />
        <path fill="#FFB8B8" d="M524.12 382.586l-5.343 11.035-45.104-15.357 7.886-16.285z" />
        <path
          d="M511.808 399.976l10.304-21.28 13.399 6.487-16.791 34.68a14.887 14.887 0 0 1-6.912-19.887z"
          fill="#2F2E41"
        />
        <path
          d="M435.26 360.793a10.743 10.743 0 0 0 12.708-10.48l74.374-68.571-18.47-14.306-67.337 71.944a10.8 10.8 0 0 0-1.276 21.413z"
          fill="#FFB8B8"
        />
        <circle fill="#FFB8B8" cx={536.344} cy={207.898} r={24.561} />
        <path
          d="M558.386 352.273c-17.228 0-37.978-3.629-50.778-18.477l-.289-.335.297-.328c.097-.107 9.514-10.868.11-30.06L494.8 307.05l-12.87-16.989 7.13-21.39 29.177-23.503a26.75 26.75 0 0 1 14.61-5.79 80.21 80.21 0 0 0 27.785-7.912 27.906 27.906 0 0 1 12.8-2.799l.574.017a9.95 9.95 0 0 1 9.64 10.707c-1.978 25.63-5.472 87.544 4.792 108.861l.265.551-.592.15a136.113 136.113 0 0 1-29.724 3.32z"
          fill={primaryColor}
        />
        <path
          d="M510.986 333.47s-65-6-72 13 1 28 13 32 41 9 41 9l13-16 34 2s37.885 21.972 48.36 45.473a30.762 30.762 0 0 0 25.777 18.239c8.398.584 15.863-2.588 15.863-15.713 0-30-42-73-42-73l-77-15zM461.486 362.97s17-5 44 8M511.843 192.235a73.041 73.041 0 0 0 31.599 10.412l-3.33-3.991a24.478 24.478 0 0 0 7.56 1.501 8.28 8.28 0 0 0 6.75-3.159 7.702 7.702 0 0 0 .515-7.115 14.589 14.589 0 0 0-4.589-5.738 27.323 27.323 0 0 0-25.43-4.545 16.33 16.33 0 0 0-7.596 4.872 9.236 9.236 0 0 0-1.863 8.56M540.31 172.604a75.485 75.485 0 0 1 19.136-26.522c5.292-4.702 11.472-8.743 18.446-9.962 6.974-1.22 14.833.87 19.11 6.51 3.499 4.614 4.153 10.794 3.768 16.57-.386 5.776-1.677 11.496-1.553 17.284a35.468 35.468 0 0 0 50.527 31.351c-6.022 3.329-10.714 8.598-16.305 12.608-5.59 4.01-12.963 6.76-19.312 4.11-6.718-2.804-9.8-10.42-12.206-17.29l-10.732-30.64c-1.824-5.208-3.739-10.57-7.462-14.644s-9.765-6.557-14.89-4.51c-3.883 1.553-6.412 5.258-8.63 8.805-2.217 3.546-4.557 7.32-8.303 9.18-3.747 1.859-9.299.714-10.523-3.286"
          fill="#2F2E41"
        />
        <path fill="#E6E6E6" d="M532.729 328.27l6.896-7.077 33.366 32.513-6.896 7.078z" />
        <path
          d="M566.51 354.289c-3.809 3.909-3.728 10.166.18 13.975 3.91 3.809 10.166 3.728 13.975-.181 3.81-3.91 3.729-10.166-.18-13.975-3.91-3.809-10.166-3.728-13.975.181zm10.111 9.853a4.235 4.235 0 1 1-6.067-5.912 4.235 4.235 0 0 1 6.067 5.912zM529.48 307.928l4.69 4.718-2.28 8.096-8.314 2.028-4.358-4.384a11.999 11.999 0 1 0 10.262-10.458z"
          fill="#E6E6E6"
        />
        <path
          d="M548.736 352.359c-.424 0-.847-.024-1.268-.07a11.023 11.023 0 0 1-9.68-9.682 11.003 11.003 0 0 1 10.93-12.25c.491.003.981.04 1.467.11l33.578-35.745-3.505-11.685 17.605-7.892 4.934 12.06a20.49 20.49 0 0 1-6.177 23.673l-36.961 29.4c.039.359.059.719.06 1.08a11.018 11.018 0 0 1-3.664 8.196 10.872 10.872 0 0 1-7.319 2.805z"
          fill="#FFB8B8"
        />
        <path
          d="M574.986 233.47a9.453 9.453 0 0 1 12.628 5.155l17.372 43.844-27 18-3-67z"
          fill={primaryColor}
        />
        <path
          d="M448.486 243.707V25.262C448.466 11.318 437.168.02 423.224 0H79.954C66.01.02 54.71 11.318 54.692 25.262v218.445c.02 13.944 11.318 25.242 25.262 25.262h343.27c13.944-.02 25.242-11.318 25.262-25.262zm-368.532 22.29c-12.305-.015-22.275-9.986-22.29-22.29V25.262c.015-12.304 9.985-22.275 22.29-22.29h343.27c12.304.015 22.275 9.986 22.29 22.29v218.445c-.015 12.304-9.986 22.275-22.29 22.29H79.954z"
          fill="#3F3D56"
        />
        <path
          d="M445.514 39.303H57.664a1.486 1.486 0 0 1 0-2.972h387.85a1.486 1.486 0 0 1 0 2.972z"
          fill="#3F3D56"
        />
        <circle fill={primaryColor} cx={82.926} cy={20.804} r={7.43} />
        <circle fill={primaryColor} cx={108.188} cy={20.804} r={7.43} />
        <circle fill={primaryColor} cx={133.451} cy={20.804} r={7.43} />
        <path
          d="M188.979 199.232a1 1 0 0 1-1-1c0-10.427-4.571-16.243-11.946-22.247a1 1 0 1 1 1.263-1.551c7.83 6.375 12.683 12.58 12.683 23.798a1 1 0 0 1-1 1zM103.317 199.232a1 1 0 0 1-1-1c0-11.218 4.852-17.423 12.682-23.798a1 1 0 1 1 1.263 1.55c-7.375 6.005-11.945 11.822-11.945 22.248a1 1 0 0 1-1 1zM180.413 130.701a1 1 0 0 1-.632-1.775c7.581-6.171 8.198-14.938 8.198-24.923a1 1 0 1 1 2 0c0 10.505-.673 19.747-8.935 26.474a.994.994 0 0 1-.63.224zM111.882 130.166a.994.994 0 0 1-.63-.224c-8.263-6.727-8.935-15.727-8.935-25.94a1 1 0 1 1 2 0c0 10.037.603 18.206 8.197 24.389a1 1 0 0 1-.632 1.775zM201.828 152.152h-21.415a1 1 0 1 1 0-2h21.415a1 1 0 1 1 0 2zM111.883 152.152H90.467a1 1 0 1 1 0-2h21.416a1 1 0 1 1 0 2zM146.148 194.948a1 1 0 0 1-1-1v-68.53a1 1 0 1 1 2 0v68.53a1 1 0 0 1-1 1z"
          fill={bugColor}
        />
        <path
          d="M146.148 194.948c-19.467-.022-35.243-15.798-35.265-35.265v-25.717c0-16.468 13.513-26.699 35.265-26.699 22.74 0 35.265 9.482 35.265 26.699v25.717c-.022 19.468-15.798 35.243-35.265 35.265zm0-85.68c-16.081 0-33.265 6.488-33.265 24.698v25.717a33.265 33.265 0 1 0 66.53 0v-25.717c0-21.49-20.842-24.699-33.265-24.699z"
          fill={bugColor}
        />
        <path
          d="M125.651 113.441a1 1 0 0 1-.93-.63 14.088 14.088 0 0 1-.987-4.531c.036-12.34 10.015-22.344 22.355-22.41h.115c12.323.06 22.299 10.034 22.36 22.357a12.227 12.227 0 0 1-.911 4.211 1 1 0 0 1-1.85-.76c.457-1.11.715-2.29.761-3.488-.073-11.208-9.154-20.27-20.363-20.32h-.11c-11.221.056-20.304 9.14-20.359 20.362.071 1.317.357 2.614.847 3.838a1.001 1.001 0 0 1-.928 1.371z"
          fill={bugColor}
        />
        <path
          d="M247.71 108.003h-12a7 7 0 0 1 0-14h12a7 7 0 0 1 0 14zM329.71 137.503h-12a7 7 0 0 1 0-14h12a7 7 0 0 1 0 14zM367.71 137.503h-12a7 7 0 0 1 0-14h12a7 7 0 0 1 0 14zM405.71 137.503h-12a7 7 0 0 1 0-14h12a7 7 0 0 1 0 14zM330.71 108.503h-56a7 7 0 0 1 0-14h56a7 7 0 0 1 0 14zM291.71 136.503h-56a7 7 0 0 1 0-14h56a7 7 0 0 1 0 14zM401.71 108.503h-43a7 7 0 0 1 0-14h43a7 7 0 0 1 0 14zM247.71 167.003h-12a7 7 0 0 1 0-14h12a7 7 0 0 1 0 14zM330.71 167.503h-56a7 7 0 0 1 0-14h56a7 7 0 0 1 0 14zM401.71 167.503h-43a7 7 0 0 1 0-14h43a7 7 0 0 1 0 14zM382.71 191.003a7.008 7.008 0 0 1 7-7h12a7 7 0 0 1 0 14h-12a7.008 7.008 0 0 1-7-7zM299.71 191.503a7.008 7.008 0 0 1 7-7h56a7 7 0 0 1 0 14h-56a7.008 7.008 0 0 1-7-7zM228.71 191.503a7.008 7.008 0 0 1 7-7h43a7 7 0 0 1 0 14h-43a7.008 7.008 0 0 1-7-7z"
          fill={primaryColor}
        />
      </g>
    </svg>
  );
};

export default SvgSketchSelectedSvg;
