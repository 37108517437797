import { FC, useEffect } from 'react';
import { useLocation } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
// Material components
import { Box, Stack, Drawer, Divider, Toolbar, useTheme, useMediaQuery } from '@mui/material';
// Components
import { UsersInvite } from './UsersInvite';
import { UserSidebar } from './UserSidebar';
import { Support } from './Support';
import { Logo } from '../../Logo';
import { Menu } from './Menu';
// Hooks
import useHasAnyRole from 'common/hooks/useHasAnyRole';

interface SidebarProps {
  open: boolean;
  onClose: () => void;
}

/**
 * Компонент бокового меню
 */
export const Sidebar: FC<SidebarProps> = ({ open, onClose }) => {
  /**
   * Hooks
   */
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const { hasRole: hasCompanyOwnerRole } = useHasAnyRole(['COMPANY_OWNER']);
  const { hasRole: hasOfficeManager } = useHasAnyRole(['OFFICE_MANAGER']);

  /**
   * Constants
   */
  const drawerWidth = 256;

  /**
   * Effects:
   * 1. Закрывает сайдбар при переходе по разделам меню
   * 2. Закрывает сайдбар при переходе на десктоп версию и в обратном случае
   */
  useEffect(() => {
    if (open) onClose();
    // eslint-disable-next-line
  }, [location.pathname]);

  useEffect(() => {
    onClose();
    // eslint-disable-next-line
  }, [isMobile]);

  const content = (
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Stack>
        {isMobile && (
          <>
            <Box
              sx={{
                padding: ({ spacing }) => spacing(2),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Logo link="/" />
            </Box>
            <Divider />
          </>
        )}
        <Box sx={{ padding: ({ spacing }) => spacing(2) }}>
          <UserSidebar />
        </Box>
        <Divider />
        <Box sx={{ padding: ({ spacing }) => spacing(2) }}>
          <Menu />
        </Box>
        <Divider />
        {(hasCompanyOwnerRole || hasOfficeManager) && (
          <>
            <Box sx={{ padding: ({ spacing }) => spacing(2) }}>
              <UsersInvite />
            </Box>

            <Divider />
          </>
        )}
        <Box sx={{ padding: ({ spacing }) => spacing(2) }}>
          <Support />
        </Box>
      </Stack>
    </PerfectScrollbar>
  );

  return isMobile ? (
    <Drawer
      sx={{
        zIndex: ({ zIndex }) => zIndex.drawer + 200,
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          backgroundImage: 'none',
        },
      }}
      open={open}
      onClose={onClose}
      variant="temporary"
      anchor="left"
    >
      {content}
    </Drawer>
  ) : (
    <Drawer
      sx={{
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: { width: drawerWidth },
      }}
      variant="permanent"
    >
      <Toolbar />
      {content}
    </Drawer>
  );
};
