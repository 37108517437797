import { PaletteMode } from '@mui/material';

const names = {
  themeMui4: 'themeMui4',
  themeMui5: 'themeMui5',
};

/**
 * Объект методов для взаимодействия с localStorage
 */
export const ls = {
  /**
   * @deprecated
   * Функция берет данные о теме сайта из локального хранилища
   */
  getThemeModeMui4: () => localStorage.getItem(names.themeMui4) as PaletteMode | null,
  /**
   * @deprecated
   * Функция устанавливает данные о теме сайта в локальное хранилище
   */
  setThemeModeMui4: (mode: PaletteMode) => localStorage.setItem(names.themeMui4, mode),
  /**
   * Функция берет данные о теме сайта из локального хранилища
   */
  getThemeModeMui5: () => localStorage.getItem(names.themeMui5) as PaletteMode | null,
  /**
   * Функция устанавливает данные о теме сайта в локальное хранилище
   */
  setThemeModeMui5: (mode: PaletteMode) => localStorage.setItem(names.themeMui5, mode),
};
