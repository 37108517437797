import { FC, lazy } from 'react';
// Material components
import { Stack, Typography } from '@mui/material';

const UserInviteDialog = lazy(() => import('lib/components/UsersPage/OrganizationInviteDialog'));

/**
 * Компонент отображает информацию о приглашении Сотрудников для Владельца компании и Руководителя офиса
 */
export const UsersInvite: FC = () => {
  return (
    <Stack
      p={2}
      gap={2}
      sx={{
        borderRadius: ({ shape }) => `${shape.borderRadius}px`,
        background: ({ palette }) => palette.background.default,
      }}
    >
      <Typography variant="h5">Пригласите сотрудников</Typography>
      <Typography variant="caption">Приглашение также доступно в разделе Сотрудники</Typography>
      <UserInviteDialog />
    </Stack>
  );
};
