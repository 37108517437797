import { ChartSeries } from '../../../../model';
export const data_month: ChartSeries[] = [
  {
    name: 'Перекредитование',
    data: [
      { y: 254, x: 'Jul-2021' },
      { y: 835, x: 'Aug-2021' },
      { y: 2423, x: 'Sep-2021' },
      { y: 4733, x: 'Oct-2021' },
      { y: 8108, x: 'Nov-2021' },
      { y: 4593, x: 'Dec-2021' },
    ],
  },
  {
    name: 'Новостройки',
    data: [
      { y: 454, x: 'Jul-2021' },
      { y: 1235, x: 'Aug-2021' },
      { y: 2623, x: 'Sep-2021' },
      { y: 6033, x: 'Oct-2021' },
      { y: 10108, x: 'Nov-2021' },
      { y: 6593, x: 'Dec-2021' },
    ],
  },
  {
    name: 'Вторичка',
    data: [
      { y: 354, x: 'Jul-2021' },
      { y: 935, x: 'Aug-2021' },
      { y: 1423, x: 'Sep-2021' },
      { y: 5733, x: 'Oct-2021' },
      { y: 9108, x: 'Nov-2021' },
      { y: 5593, x: 'Dec-2021' },
    ],
  },
];
