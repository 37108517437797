import { FC } from 'react';
import { useHistory } from 'react-router-dom';
// Ui components
import { ActionButton } from 'lib/ui';
// Hooks
import useHasAnyRole from 'common/hooks/useHasAnyRole';

type Props = {
  route: string;
};

/**
 * Кнопка перехода на страницу вознаграждений
 */
export const MoreDetailButton: FC<Props> = ({ route }) => {
  /**
   * Hooks
   */
  const history = useHistory();
  const { hasRole: hasOfficeStaffRole } = useHasAnyRole(['OFFICE_STAFF']);
  const { hasRole: hasOfficeManagerRole } = useHasAnyRole(['OFFICE_MANAGER']);

  /**
   * Constants
   */
  const companyWorkers = !hasOfficeStaffRole && !hasOfficeManagerRole;

  /**
   * Handlers
   */
  const handleClick = () => history.push(route);

  return companyWorkers ? (
    <ActionButton title="Подробнее" variant="outlined" fullWidth onClick={handleClick} />
  ) : null;
};
