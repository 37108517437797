import { useState, createContext, useContext, useCallback, useMemo } from 'react';
// Mui components
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
// Themes
import { domrfThemes } from 'sites/domrf/settings';
import { zenitThemes } from 'sites/zenit/settings';
import { sovcomThemes } from 'sites/sovcom/settings';

// Constants
import { site } from 'lib/constants';
// Utils
import { ls } from 'lib/utils/ls';
// Types
import { Site } from 'lib/models/Site';
import { SiteThemes } from 'lib/models/SiteThemes';

type ThemeContextValue = {
  mode: PaletteMode;
  toggleMode: () => void;
};

const defaultMode: PaletteMode = 'light';

const themes: Partial<Record<Site, SiteThemes>> = {
  DOMRF: domrfThemes,
  ZENIT: zenitThemes,
  SOVCOM: sovcomThemes,
  MAIN: domrfThemes,
  MTSBANK: sovcomThemes,
};

/**
 * Контекст темы
 */
const ThemeContext = createContext<ThemeContextValue>({
  mode: defaultMode,
  toggleMode: () => {},
});

/**
 * Хук использующий контекст темы
 */
export const useThemeContext = () => useContext(ThemeContext);

/**
 * Провайдер для mui5 (компонент обертка) ThemeProvider
 */
export const ThemeProviderMui5 = ({ children }: any) => {
  const lsMode = ls.getThemeModeMui5(); // Тема из localStorage
  const [mode, setMode] = useState<PaletteMode>(lsMode ? lsMode : defaultMode);
  const { lightTheme, darkTheme } = themes[site];

  if (!lsMode) ls.setThemeModeMui5(defaultMode);

  const theme = useMemo(
    () => createTheme(mode === 'light' ? lightTheme : darkTheme),
    [mode, darkTheme, lightTheme]
  );

  const toggleMode = useCallback(() => {
    const condition = mode === 'light' ? 'dark' : 'light';
    setMode(condition);
    ls.setThemeModeMui5(condition);
  }, [mode]);

  return (
    <ThemeContext.Provider value={{ mode, toggleMode }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
