import Result from '../Result';
import { Button } from '@material-ui/core';

const NotFound = () => (
  <Result
    status={404}
    title="Страница не найдена"
    subTitle="Такой страницы не существует."
    extra={
      <Button href="/" variant="outlined" color="primary">
        На главную
      </Button>
    }
  />
);

export default NotFound;
