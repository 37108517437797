import { FC } from 'react';
// Material components
import { Stack, Typography, List, ListItem, Link, ListItemIcon, ListItemText } from '@mui/material';
// Utils
import { useSupportList } from './useSupportList';

/**
 * Блок с контактами поддержки
 */
export const Support: FC = () => {
  const list = useSupportList();

  return (
    <Stack gap={1}>
      <Typography sx={{ padding: ({ spacing }) => `0 ${spacing(1)}` }} variant="h5">
        Нужна помощь?
      </Typography>

      <List
        sx={{
          overflow: 'hidden',
          borderRadius: ({ shape }) => `${shape.borderRadius}px`,
          background: ({ palette }) => palette.background.default,
        }}
        dense
        disablePadding
      >
        {list?.map(({ id, Icon, link, primary, secondary }, index) => (
          <ListItem
            key={id}
            sx={{
              padding: ({ spacing }) => `0 ${spacing(1)}`,
              display: 'flex',
              gap: ({ spacing }) => spacing(2),
            }}
            component={Link}
            href={link}
            target="_blank"
            button
            disableGutters
            divider={index < list.length - 1}
          >
            <ListItemIcon sx={{ minWidth: 'auto' }}>
              <Icon color="primary" />
            </ListItemIcon>

            <ListItemText primary={primary} secondary={secondary} />
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};
