import { createTheme, Components } from '@mui/material/styles';
import { ShapeOptions } from '@mui/system';
// Colors
import { grey, common } from '@mui/material/colors';
// Utils
import { softShadows, strongShadows } from 'theme/shadows';
// Types
import { SiteTheme } from 'common/model/SiteTheme';

const shape: ShapeOptions = {
  borderRadius: 8,
};

const components: Components = {
  MuiTypography: {
    styleOverrides: {
      h1: { fontWeight: 800, fontSize: '2.25rem', letterSpacing: '0.2px' },
      h2: { fontWeight: 700, fontSize: '1.5rem', letterSpacing: '0.1px' },
      h3: { fontWeight: 700, fontSize: '1.25rem' },
      h4: { fontWeight: 500, fontSize: '1rem' },
      h5: { fontWeight: 500, fontSize: '0.875rem' },
      h6: { fontWeight: 500, fontSize: '0.675rem' },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },
};

/**
 * Светлая тема
 */
const lightTheme = createTheme({
  direction: 'ltr',
  shape,
  components,
  shadows: softShadows as any,
  palette: {
    mode: 'light',
    background: {
      default: '#E7E8ED',
      paper: common.white,
    },
    primary: {
      // TODO поправить стили
      // main: '#17479E',
      main: '#F15B61',

      // light: '#57bfe6', // гоолубой (это цвет hover)
      // dark: '#006184', // темно синий (это цвет hover)
      contrastText: common.white,
    },
    secondary: {
      // TODO поправить стили
      // main: '#F15B61',
      main: '#17479E',

      // light: '#67fbff',  (это цвет hover)
      // dark: '#0097a1',  (это цвет hover)
      contrastText: common.white,
    },
    text: {
      primary: grey[900],
      secondary: grey[800],
    },
  },
});

/**
 * Темная тема
 */
const darkTheme = createTheme({
  direction: 'ltr',
  shape,
  components,
  shadows: strongShadows as any,
  palette: {
    mode: 'dark',
    background: {
      default: '#1c2025',
      paper: '#282C34',
    },
    primary: {
      main: '#F15B61',
      // light: '#57bfe6',
      // dark: '#006184',
      contrastText: common.white,
    },
    secondary: {
      main: '#F15B61',
      // light: '#67fbff',
      // dark: '#0097a1',
      contrastText: common.white,
    },
    text: {
      primary: grey[50],
      secondary: grey[400],
    },
  },
});

/**
 * Экспорт тем для mui5
 */
export const sovcomThemes: SiteTheme = {
  lightTheme,
  darkTheme,
};
