import clsx from 'clsx';
import { Typography, Divider, Box, Hidden, makeStyles } from '@material-ui/core';
import { getPrimaryColor } from 'theme';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  bankLogo: {
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  partners: {
    color: getPrimaryColor(theme).logo.partners,
    textDecoration: 'none !important',
    marginLeft: theme.spacing(2),
  },
}));
type ThemeClasses = {
  logo?: string;
  divider?: string;
  parttners?: string;
};
type PartnersBankLogoProps = {
  className?: string;
  dividerHeight?: number;
  classNamePartners?: string;
  themeClasses?: ThemeClasses;
  dividerStyle?: {
    backgroundColor?: string;
    width?: string;
    height?: string;
    marginTop?: string;
  };
};
const PartnersBankLogo: React.FC<PartnersBankLogoProps> = ({
  className,
  themeClasses,
  classNamePartners,
  dividerHeight = 0,
  children,
  dividerStyle,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <Box className={classes.bankLogo}>{children}</Box>
      <Hidden mdDown>
        <Divider
          orientation="vertical"
          style={{
            height: `${dividerHeight}px`,
            marginTop: `${(42 - dividerHeight) / 2}px`,
            ...dividerStyle,
          }}
          flexItem
        />
        <Typography className={clsx(classes.partners, classNamePartners)} variant="h5">
          ПАРТНЕРЫ
        </Typography>
      </Hidden>
    </Box>
  );
};

export default PartnersBankLogo;
