import type { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
//https://mui.com/customization/color/
import { orange, blueGrey } from '@mui/material/colors';
import { alpha } from '@material-ui/core/styles';

export type LabelColor =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'warning'
  | 'success'
  | 'info'
  | 'gray'
  | 'blueGrey'
  | 'orange'
  | 'simple';
interface LabelProps {
  className?: string;
  color?: LabelColor;
  children?: ReactNode;
  style?: {};
}

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    alignItems: 'center',
    borderRadius: 2,
    display: 'inline-flex',
    flexGrow: 0,
    whiteSpace: 'nowrap',
    cursor: 'default',
    flexShrink: 0,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    height: 20,
    justifyContent: 'center',
    letterSpacing: 0.5,
    minWidth: 20,
    padding: theme.spacing(0.5, 1),
    textTransform: 'uppercase',
  },
  primary: {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
  secondary: {
    color: theme.palette.secondary.main,
    backgroundColor: alpha(theme.palette.secondary.main, 0.08),
  },
  error: {
    color: theme.palette.error.main,
    backgroundColor: alpha(theme.palette.error.main, 0.08),
  },
  success: {
    color: theme.palette.success.main,
    backgroundColor: alpha(theme.palette.success.main, 0.08),
  },
  warning: {
    color: theme.palette.warning.main,
    backgroundColor: alpha(theme.palette.warning.main, 0.08),
  },
  info: {
    color: theme.palette.info.main,
    backgroundColor: alpha(theme.palette.info.main, 0.08),
  },
  blueGrey: {
    color: `${blueGrey['500']}`,
    backgroundColor: alpha(`${blueGrey['500']}`, 0.08),
  },
  orange: {
    color: `${orange['500']}`,
    backgroundColor: alpha(`${orange['500']}`, 0.08),
  },
  simple: {
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.text.primary,
    backgroundColor: 'none',
    letterSpacing: 0.1,
    padding: theme.spacing(0.5, 0.1),
  },
}));

const Label: FC<LabelProps> = ({
  className = '',
  color = 'secondary',
  children,
  style,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <span className={clsx(classes.root, { [classes[color]]: color }, className)} {...rest}>
      {children}
    </span>
  );
};

export default Label;
