import apiClient, { apiMultipartClient } from 'common/services/apiClient';
// Types
import {
  UpdateProfileImageResponse,
  UpdateProfileImageParams,
  FetchMyProfileResponse,
  UpdateProfileResponse,
  CreateProfileResponse,
  ReassignAgentsParams,
  CreateProfileParams,
  SendProfileResponse,
  ReassignAgentParams,
  UpdateProfileParams,
  SendProfileParams,
} from './types';

/**
 * Общее апи (profile)
 */
export const profileApi = {
  updateProfileImage: ({ formData }: UpdateProfileImageParams) => {
    return apiMultipartClient.put<UpdateProfileImageResponse>('/users/avatar', formData);
  },

  fetchProfile: () => {
    return apiClient.get<FetchMyProfileResponse>('/profiles/me');
  },

  createProfile: ({ data }: CreateProfileParams) => {
    return apiClient.post<CreateProfileResponse>('/profiles', data || { canEdit: true });
  },

  updateProfile: ({ id, data }: UpdateProfileParams) => {
    return apiClient.patch<UpdateProfileResponse>(`/profiles/${id}`, data);
  },

  sendProfile: ({ id }: SendProfileParams) => {
    return apiClient.post<SendProfileResponse>(`/profiles/${id}/send`);
  },

  /**
   * Смена менеджера у агента
   */
  reassignAgent: (params: ReassignAgentParams) => apiClient.post('/people/reassign-agent', params),

  /**
   * Сменить менеджера у всех агентов
   */
  reassignAgents: (params: ReassignAgentsParams) =>
    apiClient.post('/people/reassign-agents', params),
};
