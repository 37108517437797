import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { DocumentWithContent } from 'common/model';
import api from './api';
import { UploadDocumentsArgs } from './ApiTypes';

interface UploadSingleFileStartedPayload {
  entityId: string;
  moduleId: string;
  trackId: string;
}
interface InitialDocsState {
  [key: string]: {
    [key: string]: DocumentWithContent[];
  };
}

interface UploadingDocState {
  [key: string]: {
    [key: string]: number;
  };
}

const documentsAdapter = createEntityAdapter<DocumentWithContent>({
  selectId: (document) => document._id,
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
});

// Documents slice
const documentsSlice = createSlice({
  name: 'documents',
  initialState: documentsAdapter.getInitialState({
    loading: 'idle',
    uploading: false,
    leadsDocs: {} as InitialDocsState,
    uploadingDocs: {} as UploadingDocState,
  }),
  reducers: {
    setUploading: (state, { payload }: PayloadAction<boolean>) => {
      state.uploading = payload;
    },
    loadingStarted: (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    },
    loadingEnded: (state) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
      }
    },

    resetCounter: (state) => {
      state.uploadingDocs = {};
      state.uploading = false;
    },
    resetLeadDocs: (state, { payload }: PayloadAction<string>) => {
      state.leadsDocs[payload] = {};
    },
    incrementUploading: (state, { payload }: PayloadAction<UploadSingleFileStartedPayload>) => {
      const { entityId, moduleId } = payload;
      var newState: UploadingDocState = { ...state.uploadingDocs };
      newState[entityId] = { ...newState[entityId] };
      newState[entityId][moduleId] = newState[entityId][moduleId] || 0;
      newState[entityId][moduleId] = newState[entityId][moduleId] + 1;
      state.uploadingDocs = {
        ...state.uploadingDocs,
        ...newState,
      };
    },
    decrementUploading: (state, { payload }: PayloadAction<DocumentWithContent>) => {
      const { entityId, moduleId } = payload;
      var newState: UploadingDocState = { ...state.uploadingDocs };
      newState[entityId] = { ...newState[entityId] };
      newState[entityId][moduleId] = newState[entityId][moduleId] - 1;
      state.uploadingDocs = {
        ...state.uploadingDocs,
        ...newState,
      };
    },
    fetchCompleted: (state, { payload }: PayloadAction<DocumentWithContent[]>) => {
      var newState: InitialDocsState = { ...state.leadsDocs };
      for (var i = 0; i < payload.length; ++i) {
        let doc = payload[i];
        let entityId = doc.entityId;
        let blockId = doc.moduleId;

        newState[entityId] = { ...newState[entityId] };
        newState[entityId][blockId] = newState[entityId][blockId] || [];
        if (newState[entityId][blockId]) {
          newState[entityId][blockId].push(doc);
        }
      }
      state.leadsDocs = {
        ...state.leadsDocs,
        ...newState,
      };
    },
    uploadAdded: (state, { payload }: PayloadAction<DocumentWithContent>) => {
      var newState: InitialDocsState = { ...state.leadsDocs };
      let entityId = payload.entityId;
      let blockId = payload.moduleId;

      newState[entityId] = { ...newState[entityId] };
      newState[entityId][blockId] = newState[entityId][blockId] || [];
      if (newState[entityId][blockId]) {
        newState[entityId][blockId].push(payload);
      }
      state.leadsDocs = newState;
    },
    uploadDeleted: (state, { payload }: PayloadAction<DocumentWithContent>) => {
      const entityId = payload.entityId;
      const moduleId = payload.moduleId;
      const entityDocs = state.leadsDocs[entityId];

      if (entityDocs) {
        const blockDocs = entityDocs[moduleId];
        state.leadsDocs[entityId][moduleId] = blockDocs.filter((doc) => doc._id !== payload._id);
      }
    },
    uploadUpdated: documentsAdapter.updateOne,
    uploadsRemoved: documentsAdapter.removeAll,
    uploadsReceived: documentsAdapter.setAll,
  },
});

// Actions
const {
  uploadAdded,
  setUploading,
  uploadDeleted,
  fetchCompleted,
  incrementUploading,
  decrementUploading,
  resetLeadDocs,
} = documentsSlice.actions;

/**
 * Fetch documents
 * @param entityId - parent document
 * @param moduleId - parent document block
 * @param trackId - progress track id
 */
export const getDocuments =
  (entityId: string, moduleId: string, trackId?: string): AppThunk =>
  (dispatch) => {
    dispatch(resetLeadDocs(entityId));
    api.fetchDocuments(entityId, moduleId, trackId).then(({ data }) => {
      dispatch(fetchCompleted(data));
    });
  };

/**
 * Upload Documents
 * @param args UploadDocumentsArgs
 */
export const uploadDocuments =
  (args: UploadDocumentsArgs): AppThunk =>
  (dispatch) => {
    const { entityId, moduleId, trackId, files } = args;

    dispatch(setUploading(true));
    // Можно было бы все сразу загружать,
    // Но когда много файлов пользователь слишком долго будет ждать результат
    // а в такой реализации файлы одни за одним будут появляется
    // в списке загруженных
    for (let index = 0; index < files.length; index++) {
      const formData = new FormData();
      const file = files[index];
      formData.append('files', file);
      formData.append('entityId', entityId);
      formData.append('moduleId', moduleId);
      formData.append('trackId', trackId);
      // Нам нужно показывать в каком блоке отображать loader для загружаемого фалв
      const uploadStartedPayload: UploadSingleFileStartedPayload = {
        entityId,
        moduleId,
        trackId,
      };
      // Увеличиваем счетчик для загружаемых фалов для блока
      dispatch(incrementUploading(uploadStartedPayload));
      api
        .uploadDocuments(formData)
        .then(({ data }) => {
          const result: DocumentWithContent[] = data;
          // Счетчик загружаемых файлов
          dispatch(setUploading(false));
          dispatch(decrementUploading(result[0]));
          dispatch(uploadAdded(result[0]));
        })
        .catch((err) => {
          console.log('uploadDocumentError:', err);
        });
    }
  };

/**
 * Deletes Document
 * @param id - document id
 * @param trackId - progress track id
 */
export const deleteDocument =
  (document: DocumentWithContent, trackId?: string): AppThunk =>
  (dispatch) => {
    api.deleteDocument(document._id, trackId).then(() => {
      dispatch(uploadDeleted(document));
    });
  };

export const removeAllDocuments =
  (documents: DocumentWithContent[]): AppThunk =>
  (dispatch) => {
    for (let index = 0; index < documents.length; index++) {
      const document = documents[index];
      dispatch(deleteDocument(document));
    }
  };

// Reducer
export default documentsSlice.reducer;

// State chunks exports
export const documentsState = (state: RootState) => state.documents.entities;
export const loadingState = (state: RootState) => state.documents.loading;
export const uploadingState = (state: RootState) => state.documents.uploading;
export const leadsDocsSelector = (state: RootState) => state.documents.leadsDocs;
export const uploadingDocsSelector = (state: RootState) => state.documents.uploadingDocs;

export const documentsSelectors = documentsAdapter.getSelectors(
  (state: RootState) => state.documents
);

export const allDocumentSelector = (state: RootState) => documentsSelectors.selectAll(state);
