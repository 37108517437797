import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import PhoneEnabledRoundedIcon from '@mui/icons-material/PhoneEnabledRounded';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';// Types

// Types
import { SupportList } from 'lib/ui/PrivateLayout/Sidebar/Support/types';

/**
 * Пункты поддержки в проекте Sovcom
 */
export const sovcomSupport: SupportList = [
  {
    id: 'sendEmail',
    Icon: EmailRoundedIcon,
    link: 'mailto:support@e-place.com',
    primary: 'support@e-place.com',
    secondary: 'Электронная почта',
  },
  {
    id: 'phoneCall',
    Icon: PhoneEnabledRoundedIcon,
    link: 'tel:88007074644',
    primary: '+7 (800) 707-46-44',
    secondary: 'Бесплатный звонок',
  },
  {
    id: 'sendWhatsUp',
    Icon: WhatsAppIcon,
    link: 'https://api.whatsapp.com/send?phone=79258142826',
    primary: '+7 (925) 814-28-26',
    secondary: 'WhatsApp',
  },
];
