import {
  ShoppingCart as ShoppingCartIcon,
  Briefcase as BriefcaseIcon,
  Users as UsersIcon,
  Bookmark as BookmarkIcon,
  UserCheck as PartnersIcon,
  //Server as AdminIcon
} from 'react-feather';
// Routes
import { privateRoutes } from '../routes/privateRoutes';
// Types
import { MenuList } from 'lib/ui/PrivateLayout/Sidebar/Menu/types';

/**
 * Меню в проекте Sovcom
 */
export const sovcomMenu: MenuList = [
  {
    id: 'calculator',
    title: 'Продукты',
    link: privateRoutes.applications.products,
    roles: [
      'ADMIN',
      'DEPARTMENT_HEAD',
      'MANAGER',

      'DEAL_ADMIN',
      'DEAL_HEAD',
      'DEAL_MANAGER',

      'AGENT',
      'COMPANY_OWNER',
      'OFFICE_MANAGER',
      'OFFICE_STAFF',
    ],
    Icon: ShoppingCartIcon,
  },
  {
    id: 'allApplications',
    title: 'Все заявки',
    link: privateRoutes.applications.list,
    roles: [
      'ADMIN',
      'DEPARTMENT_HEAD',
      'MANAGER',

      'DEAL_ADMIN',
      'DEAL_HEAD',
      'DEAL_MANAGER',

      'AGENT',
      'COMPANY_OWNER',
      'OFFICE_MANAGER',
      'OFFICE_STAFF',
    ],
    Icon: BriefcaseIcon,
  },
  {
    id: 'services',
    title: 'Сервисы',
    link: privateRoutes.services.root,
    roles: [
      'ADMIN',
      'DEPARTMENT_HEAD',
      'MANAGER',

      'DEAL_ADMIN',
      'DEAL_HEAD',
      'DEAL_MANAGER',

      'AGENT',
      'COMPANY_OWNER',
      'OFFICE_MANAGER',
      'OFFICE_STAFF',
    ],
    Icon: BookmarkIcon,
  },
  {
    id: 'users',
    title: 'Пользователи',
    link: privateRoutes.users.root,
    roles: ['ADMIN', 'DEPARTMENT_HEAD', 'MANAGER', 'COMPANY_OWNER', 'OFFICE_MANAGER'],
    Icon: UsersIcon,
  },
  {
    id: 'profiles',
    title: 'Профили',
    link: privateRoutes.profiles.root,
    Icon: PartnersIcon,
    roles: ['ADMIN'],
    label: 'ADMIN',
  },
];
