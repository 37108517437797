import apiClient from 'common/services/apiClient';
// Types
import type {
  ChangeApplicationStatusParams,
  CreateApplicationResponse,
  CreateApplicationParams,
  FetchApplicationsParams,
  UpdateApplicationParams,
  FetchApplicationParams,
  SendApplicationParams,
} from './types';
import { Id } from 'lib/models';

/**
 * Общее апи (application)
 */
export const applicationApi = {
  /**
   * Загрузить список заявок
   * TODO протипизировать запрос который возращается
   */
  fetchApplications: (params: FetchApplicationsParams) => {
    return apiClient.get('/applications', { params });
  },

  /**
   * Загрузить конкретную заявку
   */
  fetchApplication: ({ id }: FetchApplicationParams) => {
    return apiClient.get(`/applications/${id}`);
  },

  /**
   * Создание заявки
   */
  createApplication: (params: CreateApplicationParams) => {
    return apiClient.post<CreateApplicationResponse>('/applications', params);
  },

  /**
   * Обновляет поля заявки
   */
  updateApplication: ({ id, update }: UpdateApplicationParams) => {
    return apiClient.patch(`/applications/${id}`, update);
  },

  /**
   * Клонирование заявки
   */
  cloneApplication: (id) => {
    return apiClient.post(`/applications/${id}/clone`);
  },

  /**
   * Удаление заявки
   */
  deleteApplication: (id: Id) => {
    return apiClient.post(`/applications/${id}/cancel`);
  },

  /**
   * Смена статуса заявки
   * Доступ:
   * Админ заявок, руководитель заявок, менеджер заявок
   */
  changeApplicationStatus: ({ id, changes }: ChangeApplicationStatusParams) => {
    return apiClient.post(`/applications/${id}/change-status`, changes);
  },

  /**
   * Отправить заявку
   */
  sendApplication: ({ id, banks }: SendApplicationParams) => {
    return apiClient.post(`/applications/${id}/send`, { banks });
  },
};
