import { useMutation, useQueryClient } from 'react-query';
// Api
import { applicationApi } from 'lib/api';
// Hooks
import { useNotifications } from 'modules/notification';
// // Types
import { Id } from 'lib/models';
// Enums
import { QueryKeysEnum } from 'lib/enums';

/**
 * Хук (мутация) удаляет заявку
 */
export const useDeleteApplication = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useNotifications();

  return useMutation((applicationId: Id) => applicationApi.deleteApplication(applicationId), {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeysEnum.applications);
      showSuccess('Заявка была удалена');
    },
    onError: (error) => console.error('Error delete application: ', error),
  });
};
