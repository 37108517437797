import { ChartSeries } from '../../../../model';
export const data_day: ChartSeries[] = [
  {
    name: 'Новостройки',
    data: [
      { y: 4, x: '01.07.2021' },
      { y: 1, x: '02.07.2021' },
      { y: 3, x: '04.07.2021' },
      { y: 4, x: '05.07.2021' },
      { y: 4, x: '06.07.2021' },
      { y: 4, x: '07.07.2021' },
      { y: 7, x: '08.07.2021' },
      { y: 3, x: '10.07.2021' },
      { y: 4, x: '12.07.2021' },
      { y: 4, x: '13.07.2021' },
      { y: 8, x: '14.07.2021' },
      { y: 9, x: '15.07.2021' },
      { y: 10, x: '16.07.2021' },
      { y: 2, x: '17.07.2021' },
      { y: 3, x: '18.07.2021' },
      { y: 10, x: '19.07.2021' },
      { y: 21, x: '20.07.2021' },
      { y: 12, x: '21.07.2021' },
      { y: 14, x: '22.07.2021' },
      { y: 11, x: '23.07.2021' },
      { y: 17, x: '26.07.2021' },
      { y: 14, x: '27.07.2021' },
      { y: 27, x: '28.07.2021' },
      { y: 30, x: '29.07.2021' },
      { y: 27, x: '30.07.2021' },
      { y: 1, x: '31.07.2021' },
      { y: 1, x: '01.08.2021' },
      { y: 30, x: '02.08.2021' },
      { y: 48, x: '03.08.2021' },
      { y: 37, x: '04.08.2021' },
      { y: 27, x: '05.08.2021' },
      { y: 30, x: '06.08.2021' },
      { y: 6, x: '07.08.2021' },
      { y: 5, x: '08.08.2021' },
      { y: 30, x: '09.08.2021' },
      { y: 27, x: '10.08.2021' },
      { y: 27, x: '11.08.2021' },
      { y: 44, x: '12.08.2021' },
      { y: 28, x: '13.08.2021' },
      { y: 2, x: '14.08.2021' },
      { y: 4, x: '15.08.2021' },
      { y: 35, x: '16.08.2021' },
      { y: 40, x: '17.08.2021' },
      { y: 39, x: '18.08.2021' },
      { y: 46, x: '19.08.2021' },
      { y: 34, x: '20.08.2021' },
      { y: 49, x: '23.08.2021' },
      { y: 47, x: '24.08.2021' },
      { y: 47, x: '25.08.2021' },
      { y: 39, x: '26.08.2021' },
      { y: 39, x: '27.08.2021' },
      { y: 2, x: '28.08.2021' },
      { y: 4, x: '29.08.2021' },
      { y: 32, x: '30.08.2021' },
      { y: 36, x: '31.08.2021' },
      { y: 42, x: '01.09.2021' },
      { y: 49, x: '02.09.2021' },
      { y: 44, x: '03.09.2021' },
      { y: 2, x: '04.09.2021' },
      { y: 2, x: '05.09.2021' },
      { y: 48, x: '06.09.2021' },
      { y: 45, x: '07.09.2021' },
      { y: 32, x: '08.09.2021' },
      { y: 38, x: '09.09.2021' },
      { y: 71, x: '10.09.2021' },
      { y: 6, x: '11.09.2021' },
      { y: 5, x: '12.09.2021' },
      { y: 102, x: '13.09.2021' },
      { y: 119, x: '14.09.2021' },
      { y: 122, x: '15.09.2021' },
      { y: 163, x: '16.09.2021' },
      { y: 159, x: '17.09.2021' },
      { y: 13, x: '18.09.2021' },
      { y: 15, x: '19.09.2021' },
      { y: 126, x: '20.09.2021' },
      { y: 151, x: '21.09.2021' },
      { y: 108, x: '22.09.2021' },
      { y: 179, x: '23.09.2021' },
      { y: 155, x: '24.09.2021' },
      { y: 18, x: '25.09.2021' },
      { y: 14, x: '26.09.2021' },
      { y: 151, x: '27.09.2021' },
      { y: 149, x: '28.09.2021' },
      { y: 164, x: '29.09.2021' },
      { y: 131, x: '30.09.2021' },
      { y: 146, x: '01.10.2021' },
      { y: 22, x: '02.10.2021' },
      { y: 10, x: '03.10.2021' },
      { y: 159, x: '04.10.2021' },
      { y: 137, x: '05.10.2021' },
      { y: 175, x: '06.10.2021' },
      { y: 202, x: '07.10.2021' },
      { y: 172, x: '08.10.2021' },
      { y: 20, x: '09.10.2021' },
      { y: 10, x: '10.10.2021' },
      { y: 189, x: '11.10.2021' },
      { y: 207, x: '12.10.2021' },
      { y: 227, x: '13.10.2021' },
      { y: 197, x: '14.10.2021' },
      { y: 165, x: '15.10.2021' },
      { y: 13, x: '16.10.2021' },
      { y: 13, x: '17.10.2021' },
      { y: 240, x: '18.10.2021' },
      { y: 189, x: '19.10.2021' },
      { y: 202, x: '20.10.2021' },
      { y: 194, x: '21.10.2021' },
      { y: 187, x: '22.10.2021' },
      { y: 29, x: '23.10.2021' },
      { y: 13, x: '24.10.2021' },
      { y: 209, x: '25.10.2021' },
      { y: 353, x: '26.10.2021' },
      { y: 349, x: '27.10.2021' },
      { y: 367, x: '28.10.2021' },
      { y: 284, x: '29.10.2021' },
      { y: 36, x: '30.10.2021' },
      { y: 17, x: '31.10.2021' },
      { y: 336, x: '01.11.2021' },
      { y: 311, x: '02.11.2021' },
      { y: 340, x: '03.11.2021' },
      { y: 52, x: '04.11.2021' },
      { y: 90, x: '05.11.2021' },
      { y: 35, x: '06.11.2021' },
      { y: 22, x: '07.11.2021' },
      { y: 351, x: '08.11.2021' },
      { y: 376, x: '09.11.2021' },
      { y: 395, x: '10.11.2021' },
      { y: 363, x: '11.11.2021' },
      { y: 342, x: '12.11.2021' },
      { y: 40, x: '13.11.2021' },
      { y: 40, x: '14.11.2021' },
      { y: 423, x: '15.11.2021' },
      { y: 420, x: '16.11.2021' },
      { y: 396, x: '17.11.2021' },
      { y: 377, x: '18.11.2021' },
      { y: 460, x: '19.11.2021' },
      { y: 51, x: '20.11.2021' },
      { y: 58, x: '21.11.2021' },
      { y: 435, x: '22.11.2021' },
      { y: 429, x: '23.11.2021' },
      { y: 460, x: '24.11.2021' },
      { y: 350, x: '25.11.2021' },
      { y: 328, x: '26.11.2021' },
      { y: 37, x: '27.11.2021' },
      { y: 26, x: '28.11.2021' },
      { y: 417, x: '29.11.2021' },
      { y: 348, x: '30.11.2021' },
      { y: 347, x: '01.12.2021' },
      { y: 318, x: '02.12.2021' },
      { y: 275, x: '03.12.2021' },
      { y: 60, x: '04.12.2021' },
      { y: 34, x: '05.12.2021' },
      { y: 320, x: '06.12.2021' },
      { y: 376, x: '07.12.2021' },
      { y: 339, x: '08.12.2021' },
      { y: 386, x: '09.12.2021' },
      { y: 308, x: '10.12.2021' },
      { y: 38, x: '11.12.2021' },
      { y: 51, x: '12.12.2021' },
      { y: 350, x: '13.12.2021' },
      { y: 365, x: '14.12.2021' },
      { y: 323, x: '15.12.2021' },
      { y: 379, x: '16.12.2021' },
      { y: 324, x: '17.12.2021' },
    ],
  },
  {
    name: 'Вторичка',
    data: [
      { y: 4, x: '01.07.2021' },
      { y: 1, x: '02.07.2021' },
      { y: 3, x: '04.07.2021' },
      { y: 4, x: '05.07.2021' },
      { y: 4, x: '06.07.2021' },
      { y: 4, x: '07.07.2021' },
      { y: 7, x: '08.07.2021' },
      { y: 3, x: '10.07.2021' },
      { y: 4, x: '12.07.2021' },
      { y: 4, x: '13.07.2021' },
      { y: 8, x: '14.07.2021' },
      { y: 9, x: '15.07.2021' },
      { y: 10, x: '16.07.2021' },
      { y: 2, x: '17.07.2021' },
      { y: 3, x: '18.07.2021' },
      { y: 10, x: '19.07.2021' },
      { y: 21, x: '20.07.2021' },
      { y: 12, x: '21.07.2021' },
      { y: 14, x: '22.07.2021' },
      { y: 11, x: '23.07.2021' },
      { y: 17, x: '26.07.2021' },
      { y: 14, x: '27.07.2021' },
      { y: 27, x: '28.07.2021' },
      { y: 30, x: '29.07.2021' },
      { y: 27, x: '30.07.2021' },
      { y: 1, x: '31.07.2021' },
      { y: 1, x: '01.08.2021' },
      { y: 30, x: '02.08.2021' },
      { y: 48, x: '03.08.2021' },
      { y: 37, x: '04.08.2021' },
      { y: 27, x: '05.08.2021' },
      { y: 30, x: '06.08.2021' },
      { y: 6, x: '07.08.2021' },
      { y: 5, x: '08.08.2021' },
      { y: 30, x: '09.08.2021' },
      { y: 27, x: '10.08.2021' },
      { y: 27, x: '11.08.2021' },
      { y: 44, x: '12.08.2021' },
      { y: 28, x: '13.08.2021' },
      { y: 2, x: '14.08.2021' },
      { y: 4, x: '15.08.2021' },
      { y: 35, x: '16.08.2021' },
      { y: 40, x: '17.08.2021' },
      { y: 39, x: '18.08.2021' },
      { y: 46, x: '19.08.2021' },
      { y: 34, x: '20.08.2021' },
      { y: 49, x: '23.08.2021' },
      { y: 47, x: '24.08.2021' },
      { y: 47, x: '25.08.2021' },
      { y: 39, x: '26.08.2021' },
      { y: 39, x: '27.08.2021' },
      { y: 2, x: '28.08.2021' },
      { y: 4, x: '29.08.2021' },
      { y: 32, x: '30.08.2021' },
      { y: 36, x: '31.08.2021' },
      { y: 42, x: '01.09.2021' },
      { y: 49, x: '02.09.2021' },
      { y: 44, x: '03.09.2021' },
      { y: 2, x: '04.09.2021' },
      { y: 2, x: '05.09.2021' },
      { y: 48, x: '06.09.2021' },
      { y: 45, x: '07.09.2021' },
      { y: 32, x: '08.09.2021' },
      { y: 38, x: '09.09.2021' },
      { y: 71, x: '10.09.2021' },
      { y: 6, x: '11.09.2021' },
      { y: 5, x: '12.09.2021' },
      { y: 102, x: '13.09.2021' },
      { y: 119, x: '14.09.2021' },
      { y: 122, x: '15.09.2021' },
      { y: 163, x: '16.09.2021' },
      { y: 159, x: '17.09.2021' },
      { y: 13, x: '18.09.2021' },
      { y: 15, x: '19.09.2021' },
      { y: 126, x: '20.09.2021' },
      { y: 151, x: '21.09.2021' },
      { y: 108, x: '22.09.2021' },
      { y: 179, x: '23.09.2021' },
      { y: 155, x: '24.09.2021' },
      { y: 18, x: '25.09.2021' },
      { y: 14, x: '26.09.2021' },
      { y: 151, x: '27.09.2021' },
      { y: 149, x: '28.09.2021' },
      { y: 164, x: '29.09.2021' },
      { y: 131, x: '30.09.2021' },
      { y: 146, x: '01.10.2021' },
      { y: 22, x: '02.10.2021' },
      { y: 10, x: '03.10.2021' },
      { y: 159, x: '04.10.2021' },
      { y: 137, x: '05.10.2021' },
      { y: 175, x: '06.10.2021' },
      { y: 202, x: '07.10.2021' },
      { y: 172, x: '08.10.2021' },
      { y: 20, x: '09.10.2021' },
      { y: 10, x: '10.10.2021' },
      { y: 189, x: '11.10.2021' },
      { y: 207, x: '12.10.2021' },
      { y: 227, x: '13.10.2021' },
      { y: 197, x: '14.10.2021' },
      { y: 165, x: '15.10.2021' },
      { y: 13, x: '16.10.2021' },
      { y: 13, x: '17.10.2021' },
      { y: 240, x: '18.10.2021' },
      { y: 189, x: '19.10.2021' },
      { y: 202, x: '20.10.2021' },
      { y: 194, x: '21.10.2021' },
      { y: 187, x: '22.10.2021' },
      { y: 29, x: '23.10.2021' },
      { y: 13, x: '24.10.2021' },
      { y: 209, x: '25.10.2021' },
      { y: 353, x: '26.10.2021' },
      { y: 349, x: '27.10.2021' },
      { y: 367, x: '28.10.2021' },
      { y: 284, x: '29.10.2021' },
      { y: 36, x: '30.10.2021' },
      { y: 17, x: '31.10.2021' },
      { y: 336, x: '01.11.2021' },
      { y: 311, x: '02.11.2021' },
      { y: 340, x: '03.11.2021' },
      { y: 52, x: '04.11.2021' },
      { y: 90, x: '05.11.2021' },
      { y: 35, x: '06.11.2021' },
      { y: 22, x: '07.11.2021' },
      { y: 351, x: '08.11.2021' },
      { y: 376, x: '09.11.2021' },
      { y: 395, x: '10.11.2021' },
      { y: 363, x: '11.11.2021' },
      { y: 342, x: '12.11.2021' },
      { y: 40, x: '13.11.2021' },
      { y: 40, x: '14.11.2021' },
      { y: 423, x: '15.11.2021' },
      { y: 420, x: '16.11.2021' },
      { y: 396, x: '17.11.2021' },
      { y: 377, x: '18.11.2021' },
      { y: 460, x: '19.11.2021' },
      { y: 51, x: '20.11.2021' },
      { y: 58, x: '21.11.2021' },
      { y: 435, x: '22.11.2021' },
      { y: 429, x: '23.11.2021' },
      { y: 460, x: '24.11.2021' },
      { y: 350, x: '25.11.2021' },
      { y: 328, x: '26.11.2021' },
      { y: 37, x: '27.11.2021' },
      { y: 26, x: '28.11.2021' },
      { y: 417, x: '29.11.2021' },
      { y: 348, x: '30.11.2021' },
      { y: 347, x: '01.12.2021' },
      { y: 318, x: '02.12.2021' },
      { y: 275, x: '03.12.2021' },
      { y: 60, x: '04.12.2021' },
      { y: 34, x: '05.12.2021' },
      { y: 320, x: '06.12.2021' },
      { y: 376, x: '07.12.2021' },
      { y: 339, x: '08.12.2021' },
      { y: 386, x: '09.12.2021' },
      { y: 308, x: '10.12.2021' },
      { y: 38, x: '11.12.2021' },
      { y: 51, x: '12.12.2021' },
      { y: 350, x: '13.12.2021' },
      { y: 365, x: '14.12.2021' },
      { y: 323, x: '15.12.2021' },
      { y: 379, x: '16.12.2021' },
      { y: 324, x: '17.12.2021' },
    ],
  },
  {
    name: 'Перекредитование',
    data: [
      { y: 4, x: '01.07.2021' },
      { y: 1, x: '02.07.2021' },
      { y: 3, x: '04.07.2021' },
      { y: 4, x: '05.07.2021' },
      { y: 4, x: '06.07.2021' },
      { y: 4, x: '07.07.2021' },
      { y: 7, x: '08.07.2021' },
      { y: 3, x: '10.07.2021' },
      { y: 4, x: '12.07.2021' },
      { y: 4, x: '13.07.2021' },
      { y: 8, x: '14.07.2021' },
      { y: 9, x: '15.07.2021' },
      { y: 10, x: '16.07.2021' },
      { y: 2, x: '17.07.2021' },
      { y: 3, x: '18.07.2021' },
      { y: 10, x: '19.07.2021' },
      { y: 21, x: '20.07.2021' },
      { y: 12, x: '21.07.2021' },
      { y: 14, x: '22.07.2021' },
      { y: 11, x: '23.07.2021' },
      { y: 17, x: '26.07.2021' },
      { y: 14, x: '27.07.2021' },
      { y: 27, x: '28.07.2021' },
      { y: 30, x: '29.07.2021' },
      { y: 27, x: '30.07.2021' },
      { y: 1, x: '31.07.2021' },
      { y: 1, x: '01.08.2021' },
      { y: 30, x: '02.08.2021' },
      { y: 48, x: '03.08.2021' },
      { y: 37, x: '04.08.2021' },
      { y: 27, x: '05.08.2021' },
      { y: 30, x: '06.08.2021' },
      { y: 6, x: '07.08.2021' },
      { y: 5, x: '08.08.2021' },
      { y: 30, x: '09.08.2021' },
      { y: 27, x: '10.08.2021' },
      { y: 27, x: '11.08.2021' },
      { y: 44, x: '12.08.2021' },
      { y: 28, x: '13.08.2021' },
      { y: 2, x: '14.08.2021' },
      { y: 4, x: '15.08.2021' },
      { y: 35, x: '16.08.2021' },
      { y: 40, x: '17.08.2021' },
      { y: 39, x: '18.08.2021' },
      { y: 46, x: '19.08.2021' },
      { y: 34, x: '20.08.2021' },
      { y: 49, x: '23.08.2021' },
      { y: 47, x: '24.08.2021' },
      { y: 47, x: '25.08.2021' },
      { y: 39, x: '26.08.2021' },
      { y: 39, x: '27.08.2021' },
      { y: 2, x: '28.08.2021' },
      { y: 4, x: '29.08.2021' },
      { y: 32, x: '30.08.2021' },
      { y: 36, x: '31.08.2021' },
      { y: 42, x: '01.09.2021' },
      { y: 49, x: '02.09.2021' },
      { y: 44, x: '03.09.2021' },
      { y: 2, x: '04.09.2021' },
      { y: 2, x: '05.09.2021' },
      { y: 48, x: '06.09.2021' },
      { y: 45, x: '07.09.2021' },
      { y: 32, x: '08.09.2021' },
      { y: 38, x: '09.09.2021' },
      { y: 71, x: '10.09.2021' },
      { y: 6, x: '11.09.2021' },
      { y: 5, x: '12.09.2021' },
      { y: 102, x: '13.09.2021' },
      { y: 119, x: '14.09.2021' },
      { y: 122, x: '15.09.2021' },
      { y: 163, x: '16.09.2021' },
      { y: 159, x: '17.09.2021' },
      { y: 13, x: '18.09.2021' },
      { y: 15, x: '19.09.2021' },
      { y: 126, x: '20.09.2021' },
      { y: 151, x: '21.09.2021' },
      { y: 108, x: '22.09.2021' },
      { y: 179, x: '23.09.2021' },
      { y: 155, x: '24.09.2021' },
      { y: 18, x: '25.09.2021' },
      { y: 14, x: '26.09.2021' },
      { y: 151, x: '27.09.2021' },
      { y: 149, x: '28.09.2021' },
      { y: 164, x: '29.09.2021' },
      { y: 131, x: '30.09.2021' },
      { y: 146, x: '01.10.2021' },
      { y: 22, x: '02.10.2021' },
      { y: 10, x: '03.10.2021' },
      { y: 159, x: '04.10.2021' },
      { y: 137, x: '05.10.2021' },
      { y: 175, x: '06.10.2021' },
      { y: 202, x: '07.10.2021' },
      { y: 172, x: '08.10.2021' },
      { y: 20, x: '09.10.2021' },
      { y: 10, x: '10.10.2021' },
      { y: 189, x: '11.10.2021' },
      { y: 207, x: '12.10.2021' },
      { y: 227, x: '13.10.2021' },
      { y: 197, x: '14.10.2021' },
      { y: 165, x: '15.10.2021' },
      { y: 13, x: '16.10.2021' },
      { y: 13, x: '17.10.2021' },
      { y: 240, x: '18.10.2021' },
      { y: 189, x: '19.10.2021' },
      { y: 202, x: '20.10.2021' },
      { y: 194, x: '21.10.2021' },
      { y: 187, x: '22.10.2021' },
      { y: 29, x: '23.10.2021' },
      { y: 13, x: '24.10.2021' },
      { y: 209, x: '25.10.2021' },
      { y: 353, x: '26.10.2021' },
      { y: 349, x: '27.10.2021' },
      { y: 367, x: '28.10.2021' },
      { y: 284, x: '29.10.2021' },
      { y: 36, x: '30.10.2021' },
      { y: 17, x: '31.10.2021' },
      { y: 336, x: '01.11.2021' },
      { y: 311, x: '02.11.2021' },
      { y: 340, x: '03.11.2021' },
      { y: 52, x: '04.11.2021' },
      { y: 90, x: '05.11.2021' },
      { y: 35, x: '06.11.2021' },
      { y: 22, x: '07.11.2021' },
      { y: 351, x: '08.11.2021' },
      { y: 376, x: '09.11.2021' },
      { y: 395, x: '10.11.2021' },
      { y: 363, x: '11.11.2021' },
      { y: 342, x: '12.11.2021' },
      { y: 40, x: '13.11.2021' },
      { y: 40, x: '14.11.2021' },
      { y: 423, x: '15.11.2021' },
      { y: 420, x: '16.11.2021' },
      { y: 396, x: '17.11.2021' },
      { y: 377, x: '18.11.2021' },
      { y: 460, x: '19.11.2021' },
      { y: 51, x: '20.11.2021' },
      { y: 58, x: '21.11.2021' },
      { y: 435, x: '22.11.2021' },
      { y: 429, x: '23.11.2021' },
      { y: 460, x: '24.11.2021' },
      { y: 350, x: '25.11.2021' },
      { y: 328, x: '26.11.2021' },
      { y: 37, x: '27.11.2021' },
      { y: 26, x: '28.11.2021' },
      { y: 417, x: '29.11.2021' },
      { y: 348, x: '30.11.2021' },
      { y: 347, x: '01.12.2021' },
      { y: 318, x: '02.12.2021' },
      { y: 275, x: '03.12.2021' },
      { y: 60, x: '04.12.2021' },
      { y: 34, x: '05.12.2021' },
      { y: 320, x: '06.12.2021' },
      { y: 376, x: '07.12.2021' },
      { y: 339, x: '08.12.2021' },
      { y: 386, x: '09.12.2021' },
      { y: 308, x: '10.12.2021' },
      { y: 38, x: '11.12.2021' },
      { y: 51, x: '12.12.2021' },
      { y: 350, x: '13.12.2021' },
      { y: 365, x: '14.12.2021' },
      { y: 323, x: '15.12.2021' },
      { y: 379, x: '16.12.2021' },
      { y: 324, x: '17.12.2021' },
    ],
  },
];
