import { colors, PaletteType, Theme } from '@material-ui/core';
import { typography } from 'theme/typography';
import { merge } from 'lodash';
import { softShadows, strongShadows } from 'theme/shadows';
import { ThemeSettings } from 'theme/types';

/**
 * BASE CONFIG FOR LIGHT & DARK THEME
 */
const baseConfig: Partial<Theme> = {
  direction: 'ltr',
  shape: {
    borderRadius: 16,
  },
  overrides: {
    MuiTypography: typography,
    MuiLinearProgress: {
      root: {
        borderRadius: 12,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

/**
 * CONFIG FOR LIGHT THEME
 */
const lightTheme = {
  palette: {
    overrides: {
      MuiInputBase: {
        focused: { color: '#30454F' },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#000',
          },
        },
      },
    },
    type: 'light' as PaletteType,
    action: {
      active: colors.blueGrey[900],
    },
    background: {
      default: '#D2DBE3',
      dark: '#E7E8ED',
      paper: colors.common.white,
    },
    primary: {
      main: '#30454F',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#8bc540',
      contrastText: '#000000',
    },
    text: {
      primary: '#072833',
      secondary: '#253A40',
    },
  },

  shadows: softShadows,
};

/**
 * CONFIG FOR DARK THEME
 */
const darkTheme = {
  palette: {
    type: 'dark',

    action: {
      active: 'rgba(255, 255, 255, 0.54)',
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
      default: '#151D20',
      dark: '#151D20',
      paper: '#222B2F',
    },
    primary: {
      main: '#8bc540',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#8bc540',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#F8FBF5',
      secondary: '#A9A9A9',
    },
  },
  shadows: strongShadows,
};

const getThemes = () => {
  const light = merge({}, baseConfig, lightTheme);
  const dark = merge({}, baseConfig, darkTheme);
  return { light, dark };
};

// Use for more detailed theme settings
export const getDomRfThemeSettings = (theme: Theme): ThemeSettings => {
  const isDark = theme.palette.type === 'dark';
  return {
    logo: {
      partners: isDark ? '#fff' : '#000',
    },
    sidebar: {
      icons: isDark ? 'secondary' : 'secondary',
      primaryText: isDark ? 'secondary' : 'secondary',
      primaryTextActive: isDark ? 'secondary' : 'secondary',
      badge: isDark ? 'secondary' : 'secondary',
    },
    products: {
      badge: isDark ? 'primary' : 'secondary',
      images: isDark ? 'primary' : 'secondary',
      button: isDark ? 'primary' : 'secondary',
      infoImportant: isDark ? 'primary' : 'secondary',
    },
    support: {
      icons: isDark ? 'secondary' : 'primary',
      links: isDark ? 'secondary' : 'primary',
      background: isDark ? darkTheme.palette.background.dark : lightTheme.palette.background.dark,
    },
    emptyList: {
      image: isDark ? 'primary' : 'secondary',
      button: isDark ? 'primary' : 'secondary',
    },
    services: {
      title: isDark ? 'secondary' : 'secondary',
    },
  };
};

export default getThemes;
