import { trackProgress } from 'common/services/trackProgress';
import apiClient from 'common/services/apiClient';
import { User } from 'common/model';
import {
  AuthorizationResponse,
  SignUpArgs,
  LoginArgs,
  ForgottenPasswordArgs,
  LogoutResponse,
  ResetPasswordArgs,
  RequstSmsCodeArgs,
  LoginByPhoneArgs,
  RequstVerifyCodeArgs,
  CheckSmsCodeArgs,
} from 'common/services/auth/ApiTypes';

export const apiCallIds = {
  LOGIN: 'LOGIN',
  SIGN_UP: 'SIGN_UP',
  FORGOTTEN_PASSWORD: 'FORGOTTEN_PASSWORD',
  RESET_PASSWORD: 'RESET_PASSWORD',
  REQUEST_SMS_CODE: 'REQUEST_SMS_CODE',
  LOGIN_BY_PHONE: 'LOGIN_BY_PHONE',
  REQUEST_VERIFY_CODE: 'REQUEST_VERIFY_CODE',
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  CHECK_SMS_CODE: 'CHECK_SMS_CODE',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
};

const api = {
  /**
   * Регистрация пользователя
   * @param data
   */
  signUp: (data: SignUpArgs) =>
    trackProgress(
      apiClient.post<AuthorizationResponse>('/accounts/register', data),
      apiCallIds.SIGN_UP
    ),

  /**
   * Состояние авторизации
   */
  getAuthState: () => trackProgress(apiClient.get<User>('/auth/state')),

  /**
   * Авторизация по email
   * @param data
   */
  login: (data: LoginArgs) => apiClient.post<AuthorizationResponse>('/auth/login', data),

  /**
   * Выслать сcылку для восстановления пароля на email
   * @param data
   */
  forgottenPassword: (data: ForgottenPasswordArgs) =>
    trackProgress(
      apiClient.post('/accounts/request-password-reset', data),
      apiCallIds.FORGOTTEN_PASSWORD
    ),

  resetPassword: (data: ResetPasswordArgs) =>
    trackProgress(apiClient.post('/accounts/reset-password', data), apiCallIds.FORGOTTEN_PASSWORD),

  /**
   * Logout
   */
  logout: () => trackProgress(apiClient.post<LogoutResponse>('/auth/logout')),

  /**
   * Отправка смс для верификации телефона
   * @param data
   */
  requestVerifyPhone: (data: RequstVerifyCodeArgs) =>
    trackProgress(
      apiClient.post('/accounts/request-verify-phone', data),
      apiCallIds.REQUEST_VERIFY_CODE
    ),
  /**
   * Проверка смс кода для верификации телефона
   * @param data
   */
  checkSmsCode: (data: CheckSmsCodeArgs) =>
    trackProgress(apiClient.post('/accounts/check-sms-code', data), apiCallIds.CHECK_SMS_CODE),
  /**
   * Запрос смс на авторизацию по телефону
   * @param data
   */
  requestSmsCode: (data: RequstSmsCodeArgs) =>
    trackProgress(apiClient.post('/auth/request-sms-code', data), apiCallIds.REQUEST_SMS_CODE),
  /**
   * Авторизация по телефону
   * @param data
   */
  loginByPhone: (data: LoginByPhoneArgs) =>
    trackProgress(
      apiClient.post<LogoutResponse>('/auth/login-by-phone', data),
      apiCallIds.LOGIN_BY_PHONE
    ),

  /**
   * Подтверждение email
   * @param param0
   */
  confirmEmail: (token: string) =>
    trackProgress(apiClient.post(`/accounts/confirm-email`, { token }), apiCallIds.CONFIRM_EMAIL),

  refreshToken: () => trackProgress(apiClient.get(`/auth/refresh`), apiCallIds.REFRESH_TOKEN),
};

export default api;
