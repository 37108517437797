/**
 * This delays the rendering of your app's UI until your persisted state has been retrieved and saved to redux.
 * NOTE the PersistGate loading prop can be null, or any react instance, e.g. loading={<Loading />}
 */
import { ReactNode } from 'react';
import { connect } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { relogin, selectIsLoggedIn } from 'common/services/auth';
import { LoadingScreen } from 'common/components';
import { AUTH_ROUTER_PATHS } from 'features/auth/constants';
import history from 'app/history';
import { persistor, RootState } from '.';

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: selectIsLoggedIn(state),
});

const mapDispatchToProps = {
  relogin: relogin,
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    children: ReactNode;
  };

const StorePersistGate = ({ children, isLoggedIn, relogin }: Props) => (
  <PersistGate
    loading={<LoadingScreen />}
    onBeforeLift={() => {
      if (isLoggedIn && history.location.pathname !== AUTH_ROUTER_PATHS.logout) {
        relogin();
      }
    }}
    persistor={persistor}
  >
    {children}
  </PersistGate>
);

export default connect(mapStateToProps, mapDispatchToProps)(StorePersistGate);
