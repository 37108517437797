import { useMutation, useQueryClient } from 'react-query';
// Api
import { usersApi } from 'lib/api';
import { UpdateUserParams } from 'lib/api/usersApi';
// Utils
import { useNotifications } from 'modules/notification';
// Enums
import { QueryKeysEnum } from 'lib/enums';
// Types
import { Id } from 'lib/models';

export const useUpdateUser = (userId: Id) => {
  const queryClient = useQueryClient();
  const { showSuccess } = useNotifications();

  return useMutation(
    ({ userId, updates }: UpdateUserParams) => usersApi.updateSingleUser({ userId, updates }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeysEnum.userId, { userId }]);
        showSuccess('Данные пользователя сохранены');
      },
    }
  );
};
