import { ChartSeries } from '../../../../model';
export const data_day: ChartSeries[] = [
  {
    name: 'Объем выданных кредитов',
    data: [
      { y: 6230000, x: '2021-08-03T00:00:00.000Z' },
      { y: 2414355, x: '2021-08-12T00:00:00.000Z' },
      { y: 6350000, x: '2021-08-16T00:00:00.000Z' },
      { y: 3528743.1, x: '2021-08-23T00:00:00.000Z' },
      { y: 2675919, x: '2021-08-24T00:00:00.000Z' },
      { y: 6764183.35, x: '2021-08-26T00:00:00.000Z' },
      { y: 3625000, x: '2021-08-27T00:00:00.000Z' },
      { y: 2057398.89, x: '2021-09-01T00:00:00.000Z' },
      { y: 2614000, x: '2021-09-02T00:00:00.000Z' },
      { y: 2472500, x: '2021-09-03T00:00:00.000Z' },
      { y: 6785000, x: '2021-09-07T00:00:00.000Z' },
      { y: 2115000, x: '2021-09-10T00:00:00.000Z' },
      { y: 4249473, x: '2021-09-14T00:00:00.000Z' },
      { y: 15102421, x: '2021-09-15T00:00:00.000Z' },
      { y: 1820000, x: '2021-09-16T00:00:00.000Z' },
      { y: 13287482.65, x: '2021-09-17T00:00:00.000Z' },
      { y: 6033226.3, x: '2021-09-18T00:00:00.000Z' },
      { y: 12201700, x: '2021-09-20T00:00:00.000Z' },
      { y: 1000000, x: '2021-09-21T00:00:00.000Z' },
      { y: 10215131, x: '2021-09-22T00:00:00.000Z' },
      { y: 18162130, x: '2021-09-23T00:00:00.000Z' },
      { y: 11010200, x: '2021-09-24T00:00:00.000Z' },
      { y: 31164739.65, x: '2021-09-27T00:00:00.000Z' },
      { y: 8164560.3100000005, x: '2021-09-28T00:00:00.000Z' },
      { y: 18511007, x: '2021-09-29T00:00:00.000Z' },
      { y: 20549900, x: '2021-09-30T00:00:00.000Z' },
      { y: 25783542.77, x: '2021-10-01T00:00:00.000Z' },
      { y: 1000000, x: '2021-10-02T00:00:00.000Z' },
      { y: 22669490.65, x: '2021-10-04T00:00:00.000Z' },
      { y: 21569500, x: '2021-10-05T00:00:00.000Z' },
      { y: 40739006.59, x: '2021-10-06T00:00:00.000Z' },
      { y: 97364254.00999999, x: '2021-10-07T00:00:00.000Z' },
      { y: 20413400, x: '2021-10-08T00:00:00.000Z' },
      { y: 63198104.55, x: '2021-10-11T00:00:00.000Z' },
      { y: 75558360.06, x: '2021-10-12T00:00:00.000Z' },
      { y: 54235159.1, x: '2021-10-13T00:00:00.000Z' },
      { y: 54919895.15, x: '2021-10-14T00:00:00.000Z' },
      { y: 33387926, x: '2021-10-15T00:00:00.000Z' },
      { y: 2404545, x: '2021-10-16T00:00:00.000Z' },
      { y: 4520732.43, x: '2021-10-17T00:00:00.000Z' },
      { y: 134787811.97, x: '2021-10-18T00:00:00.000Z' },
      { y: 77319511, x: '2021-10-19T00:00:00.000Z' },
      { y: 33828616.08, x: '2021-10-20T00:00:00.000Z' },
      { y: 75044067.99, x: '2021-10-21T00:00:00.000Z' },
      { y: 103734202.65, x: '2021-10-22T00:00:00.000Z' },
      { y: 27140910.86, x: '2021-10-23T00:00:00.000Z' },
      { y: 20724930, x: '2021-10-24T00:00:00.000Z' },
      { y: 78687052, x: '2021-10-25T00:00:00.000Z' },
      { y: 160628416.94, x: '2021-10-26T00:00:00.000Z' },
      { y: 182852755.41, x: '2021-10-27T00:00:00.000Z' },
      { y: 167349362.72, x: '2021-10-28T00:00:00.000Z' },
      { y: 172435473.31, x: '2021-10-29T00:00:00.000Z' },
      { y: 35277288.91, x: '2021-10-30T00:00:00.000Z' },
      { y: 7420447, x: '2021-10-31T00:00:00.000Z' },
      { y: 148252576.52, x: '2021-11-01T00:00:00.000Z' },
      { y: 213029016.39, x: '2021-11-02T00:00:00.000Z' },
      { y: 147657424.31, x: '2021-11-03T00:00:00.000Z' },
      { y: 31448358, x: '2021-11-04T00:00:00.000Z' },
      { y: 17939518, x: '2021-11-05T00:00:00.000Z' },
      { y: 4878500, x: '2021-11-06T00:00:00.000Z' },
      { y: 3000000, x: '2021-11-07T00:00:00.000Z' },
      { y: 189341472.45, x: '2021-11-08T00:00:00.000Z' },
      { y: 194685307.08, x: '2021-11-09T00:00:00.000Z' },
      { y: 172413308.12, x: '2021-11-10T00:00:00.000Z' },
      { y: 125506037.5, x: '2021-11-11T00:00:00.000Z' },
      { y: 185112614, x: '2021-11-12T00:00:00.000Z' },
      { y: 11508165, x: '2021-11-13T00:00:00.000Z' },
      { y: 6311000, x: '2021-11-14T00:00:00.000Z' },
      { y: 104351509.52, x: '2021-11-15T00:00:00.000Z' },
      { y: 79733555.17, x: '2021-11-16T00:00:00.000Z' },
      { y: 116197510, x: '2021-11-17T00:00:00.000Z' },
      { y: 60366372.89, x: '2021-11-18T00:00:00.000Z' },
      { y: 45460944.2, x: '2021-11-19T00:00:00.000Z' },
      { y: 2777884, x: '2021-11-20T00:00:00.000Z' },
      { y: 7369060, x: '2021-11-21T00:00:00.000Z' },
      { y: 96215962.05, x: '2021-11-22T00:00:00.000Z' },
      { y: 41480761.17, x: '2021-11-23T00:00:00.000Z' },
      { y: 15711249, x: '2021-11-24T00:00:00.000Z' },
      { y: 10121638, x: '2021-11-25T00:00:00.000Z' },
    ],
  },
];
