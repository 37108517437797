// Api
import apiClient from 'common/services/apiClient';
// Types
import { FetchProfilesParams } from './types';

/**
 * Апи страницы Профили
 */
export const profilesApi = {
  /**
   * Запрашивает список профилей пользователей
   */
  fetchProfiles: (params: FetchProfilesParams) => {
    return apiClient.get(`/profiles`, { params });
  },
};
