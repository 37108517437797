import { useQuery } from 'react-query';
// Api
import { usersApi } from 'lib/api';
// Enums
import { QueryKeysEnum } from 'lib/enums';
// Types
import { Id } from 'lib/models';

/**
 * Хук запрашивает данные о пользователе по айди
 */
export const useSingleUser = (userId: Id) => {
  const { data, ...res } = useQuery(
    [QueryKeysEnum.userId, { userId }],
    async () => {
      const { data } = await usersApi.fetchSingleUser({ userId });
      return data;
    },
    {
      // enabled: !!userId,
      refetchOnWindowFocus: false,
      onError: (error) => console.error('Fetch user error: ', error),
    }
  );

  return {
    user: data,
    ...res,
  };
};
