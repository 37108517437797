import { useMutation, useQueryClient } from 'react-query';
// Api
import { applicationApi } from 'lib/api';
// Hooks
import { useNotifications } from 'modules/notification';
// Enums
import { QueryKeysEnum } from 'lib/enums';
// Constants
import { site } from 'lib/constants';
// Types
import { Id } from 'lib/models';

/**
 * Хук (мутация) отправляет форму заявки в банк
 */
export const useSendApplication = (id: Id) => {
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useNotifications();

  return useMutation(() => applicationApi.sendApplication({ id, banks: [site] }), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeysEnum.application, { id }]);
      showSuccess('Заявка отправлена');
    },
    onError: () => showError('Не удалось отправить заявку'),
  });
};
