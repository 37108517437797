import mock from 'common/utils/mock';
import { Site } from 'lib/models';
// Справочники для дом RF
// import domrf from '../../sites/domrf/refs/data-domrf';

const getSiteData = () => {
  const site = process.env.REACT_APP_PROJECT as Site;
  switch (site) {
    case 'DOMRF':
      return {};
    default:
      return {};
  }
};
export const refs = getSiteData();
mock.onGet('/api/refs').reply(200, refs);
