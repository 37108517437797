import { zenitSupport } from 'sites/zenit/settings';
import { sovcomSupport } from 'sites/sovcom/settings';
import { mtsSupport } from 'sites/mtsbank/settings';

// Constants
import { site } from 'lib/constants';
// Types
import { Site } from 'lib/models';
import { SupportList } from './types';

const supports: Partial<Record<Site, SupportList>> = {
  ZENIT: zenitSupport,
  MTSBANK: mtsSupport,
  SOVCOM: sovcomSupport,
};

/**
 * Возвращает пункты поддержки в зависимости от проекта
 */
export const useSupportList = (): SupportList => supports[site];
