import { ThemeMessages } from 'theme/types';
const themeMessages: ThemeMessages = {
  refInviteUser: 'Подключение пользователя',
  rewardsLevel: 'Уровень вознаграждения (%)',
  refListEmptyTitle: 'Список Агентов пока пуст',
  refListEmptyMessage: 'Здесь появится список приглашенных Агентов',
  leadSendStatusMessage: 'После отправления заявки в Банк здесь появится статус рассмотрения.',
};

export default themeMessages;
