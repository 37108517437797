export const debtsBookmarks = [
  {
    title: 'Банк данных исполнительных производств',
    label: 'fssp.gov.ru',
    url: 'https://fssp.gov.ru/iss/ip',
  },
  {
    title: 'Проверка и оплата услуг ЖКХ Москвы и МО',
    label: 'жкхмосква.онлайн',
    url: 'https://жкхмосква.онлайн',
  },
  {
    title: 'Задолженность по ЖКУ в Москве',
    label: 'tinkoff.ru',
    url: 'https://www.tinkoff.ru/zhku-moskva/',
  },
  {
    title: 'Задолженности за услуги Мосэнергосбыта',
    label: 'pay.vbrr.ru',
    url: 'https://pay.vbrr.ru/mes/mos_energo_sbyt/register.html',
  },
  {
    title: 'Задолженность по налог по ИНН или УИН',
    label: 'peney.net',
    url: 'https://peney.net',
  },
  {
    title: 'Проверка и оплата штрафов ГИБДД, АМПП и МАДИ',
    label: 'mos.ru',
    url: 'https://www.mos.ru/shtrafy/',
  },
  {
    title: 'Проверка сведений о банкротстве',
    label: 'bankrot.fedresurs.ru',
    url: 'https://bankrot.fedresurs.ru/DebtorsSearch.aspx',
  },
  {
    title: 'Проверка судебных исков, связанных с квартирой или собственником',
    label: 'sudrf.ru',
    url: 'https://sudrf.ru',
  },
];
