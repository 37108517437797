import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { selectIsLoggedIn, selectIsAuthenticating } from 'common/services/auth';
import { UserRole } from 'common/model';
import { intersection } from 'lodash';
import { useAuth } from './useAuth';
import { UserRole as DefaultUserRole } from 'lib/models';

export default function useHasAnyRole(roles: UserRole[] | DefaultUserRole[]) {
  const [hasRole, setHasRole] = useState<boolean>(false);
  const auth = useAuth();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isAuthenticating = useSelector(selectIsAuthenticating);

  useEffect(() => {
    if (auth.authState) {
      const userRoles = auth.authState.roles;
      const userHasRole = intersection(userRoles, roles);
      if (userHasRole.length > 0) {
        setHasRole(true);
      } else {
        setHasRole(false);
      }
    }
  }, [auth.authState, auth.authState.roles, roles]);

  return {
    hasRole,
    isAuthenticating,
    isLoggedIn,
  };
}
