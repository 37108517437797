import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// Mui components
import { Link, Box, Avatar, Skeleton, Typography } from '@mui/material';
// Hooks
import { useAuth } from 'common/hooks/useAuth';

/**
 * Элемент сайдбара с аватаркой и именем пользователя
 */
export const UserSidebar: FC = () => {
  const { user } = useAuth();

  return (
    <Link
      sx={{ color: ({ palette }) => palette.text.primary, textDecoration: 'none' }}
      component={RouterLink}
      to="/private/profile/me"
    >
      <Box
        sx={{
          padding: ({ spacing }) => spacing(1),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: ({ spacing }) => spacing(1),
          borderRadius: ({ shape }) => `${shape.borderRadius}px`,
          backgroundColor: ({ palette }) => palette.background.default,
        }}
      >
        <Avatar
          sx={{ borderRadius: ({ shape }) => `calc(${shape.borderRadius}px / 2)` }}
          src={`${process.env.REACT_APP_API_URL}/users/avatar` || null}
        />

        {user ? (
          <Typography variant="subtitle2">
            {user?.lastName} {user?.firstName}
          </Typography>
        ) : (
          <Skeleton sx={{ width: '100%' }} variant="text" />
        )}
      </Box>
    </Link>
  );
};
