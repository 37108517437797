import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { SnackbarKey, OptionsObject } from 'notistack';

interface Notification {
  key?: SnackbarKey;
  message: string;
  options?: OptionsObject;
  dismissed?: boolean;
}

export interface IndexedNotifications {
  [key: string]: Notification;
}
interface InitialNotistackState {
  notifications: IndexedNotifications;
}

// Notistack Initial State
const initialState: InitialNotistackState = {
  notifications: {},
};

// Notistack slice
const notistackSlice = createSlice({
  name: 'notistack',
  initialState,
  reducers: {
    enqueueSnackbarSuccess: (state, { payload }: PayloadAction<Notification>) => {
      let newNotification = {};
      newNotification[payload.key] = payload;
      state.notifications = Object.assign({}, state.notifications, newNotification);
    },

    closeSnackbarSuccess: (
      state,
      { payload }: PayloadAction<{ dismissAll: boolean; key?: SnackbarKey }>
    ) => {
      const newState = Object.assign({}, state.notifications);
      if (!payload.key) {
        for (const [key] of Object.entries(newState)) {
          newState[key].dismissed = true;
        }
      } else {
        newState[payload.key].dismissed = true;
      }
      state.notifications = Object.assign({}, state.notifications, newState);
    },

    removeSnackbarSuccess: (state, { payload }: PayloadAction<SnackbarKey>) => {
      const after = Object.assign({}, state.notifications);
      delete after[payload];
      state.notifications = after;
    },
  },
});

// Actions
export const { enqueueSnackbarSuccess, closeSnackbarSuccess, removeSnackbarSuccess } =
  notistackSlice.actions;

export const enqueueSnackbar =
  (notification: Notification): AppThunk =>
  (dispatch) => {
    const key = notification.options && notification.options.key;
    const newNotification = {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    };
    dispatch(enqueueSnackbarSuccess(newNotification));
  };

export const closeSnackbar =
  (key?: SnackbarKey): AppThunk =>
  (dispatch) => {
    dispatch(
      closeSnackbarSuccess({
        dismissAll: !key,
        key,
      })
    );
  };

export const removeSnackbar =
  (key: SnackbarKey): AppThunk =>
  (dispatch) => {
    dispatch(removeSnackbarSuccess(key));
  };

// Reducer
export default notistackSlice.reducer;

// State chunks exports

export const notistackState = (state: RootState) => state.notistack.notifications;
