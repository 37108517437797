import { configureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './rootReducer';
//
import { createMigrate, MigrationManifest, persistReducer, persistStore } from 'redux-persist';
import localForage from 'localforage';

import history from 'app/history';

/**
 * Transforms allow you to customize the state object that gets persisted and rehydrated.
 */
const migrations: MigrationManifest = {
  // Add redux migrations here
};
const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 0,
    storage: localForage,
    whitelist: [],
    debug: false,
    migrate: createMigrate(migrations, { debug: false }),
  },
  rootReducer(history)
);

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST'],
    },
  }),
  routerMiddleware(history),
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});


export type AppDispatch = typeof store.dispatch;
export { default as StorePersistGate } from './StorePersistGate';
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
export default store;
