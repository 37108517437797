import { RewardMethod } from './ApiTypes';
import { trackProgress } from 'common/services/trackProgress';
import apiClient from 'common/services/apiClient';
import { rewardMethods } from 'mocks/rewardMethods';
import { Contragent } from 'common/model';

export const apiCallIds = {
  GET_REF_CODE: 'GET_REF_CODE',
  FETCH_REWARD_METHODS: 'FETCH_REWARD_METHODS',
  FETCH_INVITED_USERS: 'FETCH_INVITED_USERS',
  REGISTER_INVITE: 'REGISTER_INVITE',
  REQUEST_EMAIL_CONFIRMATION: 'REQUEST_EMAIL_CONFIRMATION',
};

const api = {
  getCurrentUserProfile: () => apiClient.get<Contragent>('/profiles/me'),

  getRefCode: (trackId?: string) =>
    trackProgress(apiClient.get('/referrals/get-code'), trackId || apiCallIds.GET_REF_CODE),

  getInvitedUsers: (trackId?: string) =>
    trackProgress(apiClient.get('/referrals'), trackId || apiCallIds.FETCH_INVITED_USERS),

  registerInvite: (code: string, trackId?: string) =>
    trackProgress(
      apiClient.post('/referrals/register-invite', { code }),
      trackId || apiCallIds.REGISTER_INVITE
    ),

  requestConfirmEmail: (trackId?: string) =>
    trackProgress(
      apiClient.post('/accounts/request-confirm-email'),
      trackId || apiCallIds.REQUEST_EMAIL_CONFIRMATION
    ),

  // Thist is mock api method
  fetchRewardMethods: (): RewardMethod[] => {
    return rewardMethods;
  },
};

export default api;
