import { useQuery } from 'react-query';
// Api
import { applicationApi } from 'lib/api';
// Enums
import { QueryKeysEnum } from 'lib/enums';
// Types
import { Id } from 'lib/models';

/**
 * Хук запрашивающий данные о заявке
 */
export const useApplication = (id: Id) => {
  const { data, ...res } = useQuery(
    [QueryKeysEnum.application, { id }],
    async () => {
      const { data } = await applicationApi.fetchApplication({ id });
      return data;
    },
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      onError: (error) => console.error('Fetch application error: ', error),
    }
  );

  return {
    ...res,
    application: data,
  };
};
