import { format, parseISO, isValid } from 'date-fns';

export const convertToValidDate = (date: Date | string): Date => {
  return 'string' === typeof date ? parseISO(date) : date;
};

export const formatDate = (isoDate: string) => {
  const date = parseISO(isoDate);
  if (isValid(date)) {
    return format(parseISO(isoDate), 'dd.MM.yyyy HH:mm:ss');
  } else {
    return '―';
  }
};

export const formatDateShort = (isoDate: string) => {
  const date = parseISO(isoDate);
  if (isValid(date)) {
    return format(parseISO(isoDate), 'dd.MM.yyyy');
  } else {
    return '―';
  }
};

export const dateToString = (strDate: Date): string => {
  const date = new Date(strDate);
  if (isValid(date)) {
    return format(date, 'dd.MM.yyyy');
  } else {
    return '―';
  }
};
