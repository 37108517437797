/**
 * Для админа
 */
export const ACTS_ROUTER_PREFIX_ADMIN = '/private/admin/acts';
export const ACTS_ROUTER_PATH_ADMIN = {
  create: `${ACTS_ROUTER_PREFIX_ADMIN}/list/create/new`,
  edit: `${ACTS_ROUTER_PREFIX_ADMIN}/list/:id/edit`,
  list: `${ACTS_ROUTER_PREFIX_ADMIN}/list`,
  detail: `${ACTS_ROUTER_PREFIX_ADMIN}/list/:id`,
  deals: `${ACTS_ROUTER_PREFIX_ADMIN}/list/:id/deals`,
};

/**
 * Для пользователя
 */
export const ACTS_ROUTER_PREFIX_AGENT = '/private/acts';
export const ACTS_ROUTER_PATH_AGENT = {
  list: `${ACTS_ROUTER_PREFIX_AGENT}/list`,
  detail: `${ACTS_ROUTER_PREFIX_AGENT}/list/:id`,
};
