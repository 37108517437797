// Api
import { authApi } from 'features/auth/api/index';

/**
 * Валидатор проверяющий зарегистрирован ли уже данный email
 */
export const isEmailExistsValidator = async (email: string) => {
  try {
    await authApi.checkExistEmail({ email });
    return true;
  } catch (error) {
    return 'Аккаунт с такой электронной почтой уже зарегистрирован';
  }
};
