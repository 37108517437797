import { useQuery } from 'react-query';
// Api
import { usersApi } from 'lib/api';
// Hooks
import useHasAnyRole from 'common/hooks/useHasAnyRole';
// Enums
import { QueryKeysEnum } from 'lib/enums';

export const useUsersRoles = () => {
  const { hasRole } = useHasAnyRole(['ADMIN', 'DEPARTMENT_HEAD', 'COMPANY_OWNER']);

  const { data, ...res } = useQuery(
    QueryKeysEnum.usersRoles,
    async () => {
      const { data } = await usersApi.getUserRole();
      return data;
    },
    {
      enabled: !!hasRole,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return {
    usersRoles: data,
    ...res,
  };
};
