import { createTheme, Components } from '@mui/material/styles';
import { ShapeOptions } from '@mui/system';
// Colors
import { blueGrey, common } from '@mui/material/colors';
// Utils
import { softShadows, strongShadows } from 'theme/shadows';
// Types
import { SiteTheme } from 'common/model/SiteTheme';

const shape: ShapeOptions = {
  borderRadius: 16,
};

const components: Components = {
  MuiTypography: {
    styleOverrides: {
      h1: { fontWeight: 800, fontSize: '2.25rem', letterSpacing: '0.2px' },
      h2: { fontWeight: 700, fontSize: '1.5rem', letterSpacing: '0.1px' },
      h3: { fontWeight: 700, fontSize: '1.25rem' },
      h4: { fontWeight: 500, fontSize: '1rem' },
      h5: { fontWeight: 500, fontSize: '0.875rem' },
      h6: { fontWeight: 500, fontSize: '0.675rem' },
    },
  },
  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
  },
};

/**
 * Светлая тема
 */
const lightTheme = createTheme({
  direction: 'ltr',
  shape,
  components,
  shadows: softShadows as any,
  palette: {
    mode: 'light',
    action: {
      active: blueGrey[900],
    },
    background: {
      default: '#D2DBE3',
      paper: common.white,
    },
    primary: {
      main: '#8bc540',
      light: '#bef871',
      dark: '#599400',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#30454f',
      light: '#5b707b',
      dark: '#081e27',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#072833',
      secondary: '#253A40',
    },
  },
});

/**
 * Темная тема
 */
const darkTheme = createTheme({
  direction: 'ltr',
  shape,
  components,
  shadows: strongShadows as any,
  palette: {
    mode: 'dark',
    action: {
      active: 'rgba(255, 255, 255, 0.54)',
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
      default: '#151D20',
      paper: '#222B2F',
    },
    primary: {
      main: '#8bc540',
      light: '#bef871',
      dark: '#599400',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#30454f',
      light: '#5b707b',
      dark: '#081e27',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#F8FBF5',
      secondary: '#A9A9A9',
    },
  },
});

/**
 * Экспорт тем для mui5
 */
export const domrfThemes: SiteTheme = {
  lightTheme,
  darkTheme,
};
