import { useMutation } from 'react-query';
// Api
import { applicationApi } from 'lib/api';
// Types
import { Id } from 'lib/models';

/**
 * Хук (мутация) обновляет данные заявки
 */
export const useUpdateApplication = (id: Id) => {
  return useMutation(
    ({ update }: Record<string, any>) => applicationApi.updateApplication({ id, update }),
    {
      onError: (error) => console.error(error),
    }
  );
};
