import { FC, useEffect, useRef } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router';
// Mui components
import { Stack, Toolbar } from '@mui/material';

/**
 * Контентная часть страницы
 */
export const Content: FC = ({ children }) => {
  /**
   * Hooks
   */
  const location = useLocation();
  const scroll = useRef(null);

  /**
   * Effects
   */
  useEffect(() => {
    scroll.current.scrollTop = 0;
  }, [location.pathname]);

  return (
    <Stack sx={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
      <PerfectScrollbar
        options={{ suppressScrollX: true }}
        containerRef={(ref) => (scroll.current = ref)}
      >
        <Toolbar />
        <Stack>{children}</Stack>
      </PerfectScrollbar>
    </Stack>
  );
};
