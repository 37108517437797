import { ChartSeries } from '../../../../model';
export const data_month: ChartSeries[] = [
  {
    data: [
      { y: 31588200.45, x: 'Aug-2021' },
      { y: 187515869.8, x: 'Sep-2021' },
      { y: 1794994763.15, x: 'Oct-2021' },
      { y: 2030869743.37, x: 'Nov-2021' },
    ],
  },
];
