import { FC, ReactNode } from 'react';
// Material components
import { Card, Stack, Typography } from '@mui/material';

interface FormBlockProps {
  /**
   * Заголовок (также можно добавлять кнопки и прочее)
   */
  title?: ReactNode;
  /**
   * Описание блока
   */
  description?: ReactNode;
  /**
   * Признак Обертки карточкой
   */
  withCard?: boolean;
}

/**
 * Компонент FormBlock. Используется для построения форм.
 *
 * @example Пример использования
 * <FormBlock title="Комментарий к заявке" withCard>
 *   <TextFieldController
 *     name="comments"
 *     label="Комментарий к заявке"
 *   />
 * </FormBlock>
 */
export const FormBlock: FC<FormBlockProps> = ({ title, description, withCard, children }) => {
  const content = (
    <Stack gap={3}>
      {(title || description) && (
        <Stack gap={1}>
          {title &&
            (typeof title === 'string' ? <Typography variant="h3">{title}</Typography> : title)}

          {description &&
            (typeof description === 'string' ? (
              <Typography variant="body2" color="textSecondary">
                {description}
              </Typography>
            ) : (
              description
            ))}
        </Stack>
      )}

      {children}
    </Stack>
  );

  return withCard ? (
    <Card sx={({ spacing }) => ({ padding: spacing(3) })} variant="outlined">
      {content}
    </Card>
  ) : (
    content
  );
};
