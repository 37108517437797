import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';
// Api
import { profilesApi } from 'lib/api';

type FilterState = {
  pagination: {
    page?: number;
    limit?: number;
  };
  sort: {
    sortField?: string;
    sortDirection?: 'asc' | 'desc';
  };
  filters: {
    user?: string;
    status?: string;
  };
};

const profilesListState = atom<FilterState>({
  key: 'profilesListState',
  default: {
    pagination: {
      page: 0,
      limit: 10,
    },
    sort: {
      sortField: 'status',
      sortDirection: 'desc',
    },
    filters: {
      user: undefined,
      status: null,
    },
  },
});

/**
 * Запрашивает список профилей, фильтрует их по пользователю и статусу
 */
export const useProfilesList = () => {
  const location = useLocation();
  const [{ pagination, sort, filters }, setFilterState] = useRecoilState(profilesListState);
  const resetFilter = useResetRecoilState(profilesListState);

  useEffect(() => {
    resetFilter();
    // eslint-disable-next-line
  }, [location]);

  const { data, ...res } = useQuery(
    ['profiles', { pagination, sort, filters }],
    async () => {
      const { page, limit } = pagination;
      const { sortField, sortDirection } = sort;
      const { user, status } = filters;

      const { data } = await profilesApi.fetchProfiles({
        page,
        limit,
        sort: `${sortField}:${sortDirection}`,
        q: user,
        status,
      });
      return data;
    },
    {
      keepPreviousData: true,
      refetchInterval: 10 * 60 * 1000,
      onError: (error) => console.error('Fetch profiles error: ', error),
    }
  );

  /**
   * Pagination
   */
  const setPage = (page: number) => {
    setFilterState((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, page },
    }));
  };

  const setLimit = (limit: number) => {
    setFilterState((prev) => ({
      ...prev,
      pagination: { ...prev.pagination, limit },
    }));
  };

  /**
   * Sort
   */
  const setSortField = (sortField: string) => {
    setFilterState((prev) => ({
      ...prev,
      sort: { ...prev.sort, sortField },
    }));
  };

  const toggleSortDirection = () => {
    setFilterState((prev) => ({
      ...prev,
      sort: { ...prev.sort, sortDirection: prev.sort.sortDirection === 'asc' ? 'desc' : 'asc' },
    }));
  };

  /**
   * Filters
   */
  const setUser = (user: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, user },
    }));
  };

  const setStatus = (status: string) => {
    setPage(0);
    setFilterState((prev) => ({
      ...prev,
      filters: { ...prev.filters, status },
    }));
  };

  return {
    ...res,
    profiles: data?.data || [],
    total: data?.totalCount || 0,
    resetFilter,
    pagination: {
      ...pagination,
      setPage,
      setLimit,
    },
    sort: {
      ...sort,
      setSortField,
      toggleSortDirection,
    },
    filters: {
      ...filters,
      setStatus,
      setUser,
    },
  };
};
