import { FC } from 'react';
import { useTheme } from '@material-ui/core';
import PartnersBank from './PartnersBank';
import MainLogo from 'sites/main/settings/Logo';
import loadable from '@loadable/component';

import { Site } from 'lib/models';
const site = process.env.REACT_APP_PROJECT as Site;

const SiteLogo = loadable(
  () => {
    return site === 'MAIN'
      ? import('sites/main/settings/Logo')
      : site === 'GAZPROM'
      ? import('sites/gazprom/settings/Logo')
      : site === 'MTSBANK'
      ? import('sites/mtsbank/settings/Logo')
      : import(`sites/${site.toLowerCase()}/settings/Logo`);
  },
  {
    fallback: <MainLogo />,
  }
);

type LogoProps = {
  className?: string;
};

const Logo: FC<LogoProps> = ({ className }) => {
  const theme = useTheme();

  const getDividerHeight = () => {
    switch (site) {
      case 'DOMRF':
        return 37;
      case 'GAZPROM':
        return 35;
      case 'MTSBANK':
        return 35;
      default:
        return 0;
    }
  };

  const getDividerStyle = () => {
    const isDark = theme.palette.type === 'dark';
    switch (site) {
      case 'DOMRF':
        return {
          backgroundColor: isDark ? theme.palette.primary.main : theme.palette.secondary.main,
          width: '2px',
          marginTop: '-2px',
          height: '40px',
        };
      default:
        return {};
    }
  };

  const dividerHeight = getDividerHeight();
  const dividerStyele = getDividerStyle();

  return (
    <div className={className}>
      {site === 'MAIN' && <SiteLogo />}
      {site !== 'MAIN' && (
        <PartnersBank dividerHeight={dividerHeight} dividerStyle={dividerStyele}>
          <SiteLogo />
        </PartnersBank>
      )}
    </div>
  );
};

export default Logo;
