import { FC } from 'react';
// Mui components
import { AppBar, Toolbar, Box, IconButton, Tooltip, useTheme, useMediaQuery } from '@mui/material';
// Mui icons
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
// Context
import { useThemeContext } from 'modules/theme';
import { useThemeContext as useThemeContextMui5 } from 'lib/contexts/ThemeContext';
// Ui componnets
import { Logo } from 'lib/ui';
// Components
import { Account } from './Account';

interface Props {
  onClickMenuIcon?: () => void;
}

/**
 * Компонент шапки всей страницы
 */
export const Header: FC<Props> = ({ onClickMenuIcon }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const { mode, toggleMode } = useThemeContext();
  const { mode: modeMui5, toggleMode: toggleModeMui5 } = useThemeContextMui5();

  const handleClickToggleTheme = () => {
    toggleMode();
    toggleModeMui5();
  };
  const modeIsDark = mode === 'dark' && modeMui5 === 'dark';

  return (
    <AppBar
      sx={({ zIndex, palette }) => ({
        zIndex: zIndex.appBar + 200,
        background: palette.background.paper,
        backgroundImage: 'none',
      })}
    >
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          {isMobile ? (
            <IconButton onClick={onClickMenuIcon}>
              <MenuRoundedIcon />
            </IconButton>
          ) : (
            <Logo link="/" />
          )}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: ({ spacing }) => spacing(1) }}>
          <Tooltip title={modeIsDark ? 'Светлая тема' : 'Темная тема'} followCursor>
            <IconButton
              sx={{ color: ({ palette }) => palette.text.primary }}
              onClick={handleClickToggleTheme}
            >
              {modeIsDark ? <LightModeRoundedIcon /> : <DarkModeRoundedIcon />}
            </IconButton>
          </Tooltip>

          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
