import { FC } from 'react';
import { useProfile } from 'lib/hooks/queries';
import { CreateApplicationButton } from 'lib/components/CreateApplicationButton';
import useHasAnyRole from 'common/hooks/useHasAnyRole';

interface Props {
  invalidateKey?: string;
  route?: (id: string) => string;
}

/**
 * Кнопка создания заявки
 * С добавленной функциональностью для зенита, блокировать создание если отсутствует профиль
 */
export const CreateApplicationButtonZenit: FC<Props> = ({ invalidateKey, route }) => {
  const { profile } = useProfile();
  const { hasRole: hasOfficeStaffRole } = useHasAnyRole(['OFFICE_STAFF', 'OFFICE_MANAGER']);
  const { hasRole: hasAdminRole } = useHasAnyRole(['ADMIN']);

  const checkDIs =
    hasOfficeStaffRole || hasAdminRole ? true : profile && profile?.status === 'VERIFIED';
  return (
    <CreateApplicationButton
      invalidateKey={invalidateKey}
      route={route}
      {...(!checkDIs && { disabled: true })}
    />
  );
};
