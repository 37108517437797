import { useQuery } from 'react-query';
// Api
import { profileApi } from 'lib/api';
// Enums
import { QueryKeysEnum } from 'lib/enums';

/**
 * Хук получает информацию о профиле пользователя
 */
export const useProfile = () => {
  const { data, ...res } = useQuery(
    QueryKeysEnum.profile,
    async () => {
      const { data } = await profileApi.fetchProfile();
      return data;
    },
    {
      refetchOnWindowFocus: false,
      onError: (error) => console.error('Fetch my profile error: ', error),
    }
  );

  return {
    profile: data,
    ...res,
  };
};
