export const users = {
  totalCount: 10,
  page: 1,
  limit: 10,
  data: [
    {
      phoneConfirmed: true,
      confirmed: true,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: true,
      _id: 'd68ce687-eece-4ed7-9ac7-b39c3e2a2f1f',
      roles: ['AGENT_OWNER', 'BANK_OWNER', 'ADMIN'],
      email: 'kodermax@gmail.com',
      firstName: 'max',
      lastName: 'alex',
      middleName: 'alex',
      phone: '89037767523',
      invitedByReferralCode: '77648820-58b5-4ee7-a634-c7c59efad22f',
      createdAt: '2021-01-13T13:34:08.179Z',
      lastLogin: '2021-01-28T10:16:40.697Z',
      bankId: 'ABSOLUTBANK',
      confirmedOn: '2021-01-19T15:12:57.655Z',
      referralCode: 'e15f0883-1384-4a57-aff1-1d5503ddd080',
      avatar: '5dab1639-6376-43e6-a3ff-f9f356ea6def',
    },
    {
      phoneConfirmed: true,
      confirmed: true,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: true,
      _id: '29bc6d8f-c7ac-4ed4-99d4-ee22f8c8e1a0',
      roles: ['AGENT_OWNER', 'ADMIN'],
      registered: false,
      email: 'at@bizmedia.ru',
      firstName: 'Анатолий',
      lastName: 'Туков',
      phone: '79253335102',
      rulesAccepted: true,
      invitedByReferralCode: '9ad58440-92c6-428f-8e28-c769cb8102f5',
      createdAt: '2021-01-10T17:55:22.835Z',
      confirmedOn: '2021-01-10T17:58:11.389Z',
      lastLogin: '2021-01-28T10:00:41.046Z',
      referralCode: '16cd3a0d-e6f1-4800-90f8-bb1bf5295362',
      bankId: 'ROSBANK_DOM',
      middleName: 'Русланович',
      avatar: 'a796ec20-e0ab-4165-bc4e-0877f8fe907a',
    },
    {
      phoneConfirmed: true,
      confirmed: false,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: false,
      _id: 'd54e9814-d947-4f5e-9da4-7995564ed755',
      roles: ['AGENT_OWNER'],
      registered: false,
      email: 'ceo@saleschain.ru',
      firstName: 'Юля',
      lastName: 'Муля',
      phone: '79959999999',
      rulesAccepted: true,
      invitedByReferralCode: '84d7f8d1-ef68-499f-a26f-fe53ef6fd9d4',
      createdAt: '2021-01-10T18:05:26.743Z',
      referralCode: '9eb299d9-a29b-4627-9fad-19d78961f025',
    },
    {
      phoneConfirmed: true,
      confirmed: false,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: false,
      _id: 'be6c7c3d-d3b6-46bf-ae06-f51327a29792',
      roles: ['ADMIN'],
      registered: false,
      email: '5456948@gmail.com',
      firstName: 'Юля',
      lastName: 'Шамоновa',
      phone: '79255456948',
      rulesAccepted: true,
      createdAt: '2021-01-10T17:18:30.699Z',
      referralCode: '9ad58440-92c6-428f-8e28-c769cb8102f5',
      lastLogin: '2021-01-15T11:50:42.372Z',
    },
    {
      phoneConfirmed: true,
      confirmed: false,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: false,
      _id: 'adfe07f9-b3a2-40a4-9580-adca90aca2b9',
      roles: ['AGENT_OWNER'],
      email: 'softddl@gmail.com',
      firstName: 'max',
      lastName: 'alex',
      middleName: 'adsa',
      phone: '74747489787',
      invitedByReferralCode: '77648820-58b5-4ee7-a634-c7c59efad22f',
      createdAt: '2021-01-14T08:55:09.623Z',
    },
    {
      phoneConfirmed: true,
      confirmed: true,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: true,
      _id: 'a16e5205-7dd9-4f9d-9f7a-4b59ab9b8484',
      roles: ['AGENT_OWNER'],
      registered: false,
      email: 'matrena-nuk@mail.ru',
      firstName: 'Анна',
      lastName: 'Поташовa',
      phone: '79032926447',
      rulesAccepted: true,
      invitedByReferralCode: '9ad58440-92c6-428f-8e28-c769cb8102f5',
      createdAt: '2021-01-10T17:58:05.588Z',
      referralCode: 'b09f6746-25f1-43fb-8844-935e004b43c4',
      confirmedOn: '2021-01-10T18:11:45.556Z',
    },
    {
      phoneConfirmed: true,
      confirmed: false,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: false,
      _id: '0f47a7f9-b92b-46fd-b3cd-b3ec5ed6960b',
      roles: ['AGENT_OWNER'],
      registered: false,
      email: '1@e-place.io',
      firstName: 'ЛО',
      lastName: 'Дезертир',
      phone: '79999999999',
      rulesAccepted: true,
      invitedByReferralCode: '3e9de460-5099-4589-92f5-50fb18d6a3dd',
      createdAt: '2021-01-10T18:03:09.380Z',
      lastLogin: '2021-01-10T19:08:44.656Z',
      referralCode: '0313140a-4d3e-48dd-9506-499703d841b7',
    },
    {
      phoneConfirmed: true,
      confirmed: false,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: false,
      _id: '2a3023d5-e348-4989-82d1-d401ad62a598',
      roles: ['AGENT_OWNER'],
      registered: false,
      email: 'mr_max@bk.ru',
      firstName: 'max',
      lastName: 'zub',
      middleName: 'alex',
      phone: '89034545656',
      invitedByReferralCode: '77648820-58b5-4ee7-a634-c7c59efad22f',
      createdAt: '2021-01-10T22:02:34.330Z',
    },
    {
      phoneConfirmed: true,
      confirmed: false,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: false,
      _id: 'eb8d16f1-3e21-477a-b0a7-727b490fb74b',
      roles: ['AGENT_OWNER'],
      registered: false,
      email: 'a.semenova@e-place.io',
      firstName: 'Анастасия ',
      lastName: 'Семёнова ',
      phone: '79019825102',
      rulesAccepted: true,
      invitedByReferralCode: '9ad58440-92c6-428f-8e28-c769cb8102f5',
      createdAt: '2021-01-10T17:59:27.165Z',
      referralCode: '84d7f8d1-ef68-499f-a26f-fe53ef6fd9d4',
    },
    {
      phoneConfirmed: true,
      confirmed: false,
      blocked: false,
      balance: {
        amount: 5000,
        currency: 'RUB',
      },
      active: false,
      _id: '27acf6fb-b52a-429e-9831-716b6a3481e5',
      roles: ['AGENT_OWNER'],
      registered: false,
      email: 'mvazhina@yandex.ru',
      firstName: 'Мария',
      lastName: 'Важина',
      phone: '79172555105',
      rulesAccepted: true,
      invitedByReferralCode: '1420c34f-c70c-4658-b2da-6c1b6d09581c',
      createdAt: '2021-01-10T17:26:43.591Z',
    },
  ],
};
