import { useQueryClient, useMutation } from 'react-query';
// Hooks
import { useNotifications } from 'modules/notification';
// Enums
import { QueryKeysEnum } from 'lib/enums';
// Api
import { applicationApi } from 'lib/api';

/**
 * Хук (мутация обернутая в хук) открепляет менеджера сделки от заявки
 */
export const useDetachApplication = (id: string) => {
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useNotifications();

  return useMutation(
    () => applicationApi.updateApplication({ id, update: { dealManagerId: null } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeysEnum.application, { id }]);
        showSuccess('Вы успешно открепили заявку');
      },
      onError: (error) => {
        showError('Открепить заявку не удалось');
        console.error('Detach application error: ', error);
      },
    }
  );
};
