import { lazy, ReactElement } from 'react';
// Constants
import { site } from 'lib/constants';
// Types
import { Site } from 'lib/models';

// Index files lazy
const MainIndex = lazy(() => import('sites/main'));
const GazpromIndex = lazy(() => import('sites/gazprom'));
const DomrfIndex = lazy(() => import('sites/domrf'));
const ZenitIndex = lazy(() => import('sites/zenit'));
const SovcomIndex = lazy(() => import('sites/sovcom'));
const MtsIndex = lazy(() => import('sites/mtsbank'));

const indexComponents: Partial<Record<Site, ReactElement>> = {
  MAIN: <MainIndex />,
  GAZPROM: <GazpromIndex />,
  DOMRF: <DomrfIndex />,
  ZENIT: <ZenitIndex />,
  SOVCOM: <SovcomIndex />,
  MTSBANK: <MtsIndex />,
};

/**
 * Компонент определяющий корневой файл проекта и его путей
 */
export const Routes = () => indexComponents[site];
