import { useMutation } from 'react-query';
import { v4 as uuidv4 } from 'uuid';
import FormData from 'form-data';
// Hooks
import { useNotifications } from 'modules/notification';
// Api
import { profileApi } from 'lib/api';
// States
import { useSetUserAvatar } from 'lib/recoil/atoms';

/**
 * Хук (мутация) обновления аватарки профиля
 */
export const useUpdateProfileImage = () => {
  const { showSuccess, showError } = useNotifications();
  const setUserAvatar = useSetUserAvatar();

  return useMutation((formData: FormData) => profileApi.updateProfileImage({ formData }), {
    onSuccess: () => {
      setUserAvatar((prev) => ({
        ...prev,
        src: `${process.env.REACT_APP_API_URL}/users/avatar?${uuidv4()}`,
      }));
      showSuccess('Изображение профиля успешно изменено.');
    },
    onError: (error) => {
      showError('Изображение профиля изменить не удалось.');
      console.error('Update profile image error: ', error);
    },
  });
};
