// Mui components
import { Typography } from '@mui/material';
// Ui components
import { MortgageIcon } from 'lib/ui/Icons';
// Components
import { CreateApplicationButton } from 'lib/components/CreateApplicationButton';
import { MoreDetailButton } from 'lib/components/ProductsPage/MoreDetailButton';
// Types
import { ProductCard } from 'lib/models/ProductCard';

/**
 * Карточки продуктов проекта Sovcom
 */
export const sovcomProductCards: ProductCard[] = [
  {
    id: 'sovcom-product-card-1',
    icon: <MortgageIcon />,
    title: 'Ипотека / Рефинансирование / Кредит под залог',
    description: (
      <>
        <Typography variant="body2">
          Вознаграждение до{' '}
          <Typography variant="body2" component="span" color="primary">
            до 0,5%
          </Typography>
        </Typography>
      </>
    ),
    actions: (
      <>
        {false && <MoreDetailButton route="/private/profile/rewards" />}

        <CreateApplicationButton
          invalidateKey="applications"
          route={(id: string) => `/private/applications/list/${id}`}
        />
      </>
    ),
  },
];
