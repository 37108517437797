// Api
import apiClient from 'common/services/apiClient';

/**
 * Organizations Api
 */
export const organizationsApi = {
  /**
   * Запрос на создание филиала за которым закрепляются пользователи
   */
  addOrganizationOffice: (name: string) => {
    return apiClient.post('/organizations/offices', { name });
  },

  /**
   * Загрузить списко филиалов за которым закреплены пользователе
   */
  fetchOrganizationOffice: () => {
    return apiClient.get('/organizations/offices');
  },
};
