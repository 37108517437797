export enum QueryKeysEnum {
  application = 'application',
  applications = 'applications',
  profile = 'profile',
  organizations = 'organizations',
  mortgageCenters = 'mortgageCenters',
  usersRoles = 'usersRoles',
  dealers = 'dealers',
  userInfo = 'userInfo',
  userId = 'userId',
}
