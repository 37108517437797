export enum ProfileStatusEnum {
  DRAFT = 'Заполняется',
  PENDING = 'На верификации',
  REVISION = 'Требует доработки',
  VERIFIED = 'Верифицирован',
  CANCELED = 'Отклонен',
}

export enum ProfileFNSStatusEnum {
  ACTIVE = 'Действующая',
  LIQUIDATING = 'Ликвидируется',
  LIQUIDATED = 'Ликвидирована',
  BANKRUPT = 'Банкротство',
}
