import { FC, useEffect } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';
// Mui components
import {
  ToggleButtonGroupProps,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  FormHelperText,
} from '@mui/material';
// Contexts
//import { useFormUpdateContext } from 'lib/contexts';
import { useFormUpdateContext } from 'common/components/FormBuilder/Helpers/FormUpdateContext';
// Types
import { Controller } from 'lib/models';

type Value = any;

type Option = {
  value: Value;
  title: string;
  disabled?: boolean;
};

type ChangeObj = {
  invalid: boolean;
  value: Value;
  name: string;
  fullName: string;
};

export interface Props extends Controller, Omit<ToggleButtonGroupProps, 'defaultValue'> {
  options: Option[];
  onChangeValid?: (obj: ChangeObj) => void;
  onChangeValue?: (obj: ChangeObj) => void;
}

/**
 * Компонент ToggleButtonGroupController.
 *
 * @example
 * <ToggleButtonGroupController
 *   name="name"
 *   options={[
 *     { value: '1', title: 'title1' },
 *     { value: '2', title: 'title2' },
 *     { value: '3', title: 'title3' },
 *     { value: '4', title: 'title4' },
 *   ]}
 *   fullWidth
 * />
 */
export const ToggleButtonGroupController: FC<Props> = ({
  path,
  name: propName,
  defaultValue,
  rules,
  disabled,
  required,
  options,
  onChangeValid,
  onChangeValue,
  ...toggleButtonGroupProps
}) => {
  const { control } = useFormContext();
  const { onUpdateFormControl } = useFormUpdateContext();

  const {
    field: { ref, name, value, onChange, onBlur },
    fieldState: { invalid, error },
  } = useController({
    name: path ? `${path}.${propName}` : propName,
    control,
    defaultValue,
    rules: {
      required: required ? 'Обязательное поле' : undefined,
      ...rules,
    },
  });

  /**
   * Helping callbacks
   */
  const debounceUpdate = useDebouncedCallback((value, name) => {
    if (onUpdateFormControl) onUpdateFormControl({ value, name });
  }, 500);

  const onChangeValueCallback = (value: Value) => {
    if (onChangeValue) onChangeValue({ invalid, value, name: propName, fullName: name });
  };

  /**
   * Effects
   */
  useEffect(() => {
    onChangeValueCallback(value);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (onChangeValid) onChangeValid({ invalid, value, name: propName, fullName: name });
    // eslint-disable-next-line
  }, [invalid]);

  /**
   * Handlers
   */
  const handleChange = (_, newValue: Value | null) => {
    if (newValue != null) {
      onChange(newValue);
      onBlur();
      debounceUpdate(newValue, name);
      onChangeValueCallback(newValue);
    }
  };

  return (
    <FormControl fullWidth={toggleButtonGroupProps.fullWidth}>
      <ToggleButtonGroup
        ref={ref}
        value={value}
        color="primary"
        exclusive
        disabled={disabled}
        onChange={handleChange}
        {...toggleButtonGroupProps}
      >
        {options.map(({ value, title, disabled }) => (
          <ToggleButton key={value} value={value} disabled={disabled}>
            {title}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {invalid && <FormHelperText variant="filled">{error?.message}</FormHelperText>}
    </FormControl>
  );
};
