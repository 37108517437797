import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';

interface InitialCountDownTimerState {
  left: number;
  start_time: number;
  status: 'PAUSED' | 'COUNTING_DOWN' | 'FINISHED';
}

// CountDownTimer Initial State
const initialState: InitialCountDownTimerState = {
  left: 0,
  start_time: 0,
  status: 'PAUSED',
};

// CountDownTimer slice
const countDownTimerSlice = createSlice({
  name: 'countDownTimer',
  initialState,
  reducers: {
    startCountDownTimer: (state, { payload }: PayloadAction<number>) => {
      state.left = payload;
      state.status = 'COUNTING_DOWN';
    },
    stopCountDownTimer: (state) => {
      state.status = 'FINISHED';
    },
    tick: (state) => {
      state.left = state.left - 1000;
    },
  },
});

// Actions
const { startCountDownTimer, stopCountDownTimer, tick } = countDownTimerSlice.actions;

// Async side effects

export const startTimer =
  (minutes: number): AppThunk =>
  (dispatch) => {
    // Длительность в миллисекундах
    const lenghtInMs = minutes * 60 * 1000;
    // Установить длительность и поменять статус
    dispatch(startCountDownTimer(lenghtInMs));
    // повторить с интервалом 1 сек
    let timer = setInterval(() => dispatch(tick()), 1000);
    // остановить таймер через minutes минут
    setTimeout(() => {
      clearInterval(timer);
      dispatch(stopCountDownTimer());
    }, lenghtInMs);
  };

// Reducer
export default countDownTimerSlice.reducer;

// State chunks exports
export const leftTimeState = (state: RootState) => state.countDownTimer.left;
export const statusState = (state: RootState) => state.countDownTimer.status;
