import apiClient from 'common/services/apiClient';
// types
import {
  FetchUserParams,
  FetchUsersParams,
  UpdateUserParams,
  LoginAsUserParams,
  InviteUsersParams,
  FetchDealersParams,
} from './types';

/**
 * Users Api
 */
export const usersApi = {
  /**
   * Загружает данные о конкретном пользователе.
   * доступ: Админ
   */
  fetchUser: ({ userId }: FetchUserParams) => apiClient.get(`/admin/users/${userId}`),

  /**
   * Загружает список всех пользователей.(для каждой роли загружаются разные списки)
   */
  fetchUsers: (params: FetchUsersParams) => {
    return apiClient.get('/people', { params });
  },

  /**
   * Загружает данные о пользователе
   * доступ: Админ, Владелец компании
   */
  fetchSingleUser: ({ userId }: FetchUserParams) => apiClient.get(`/users/user/${userId}`),

  /**
   * Обновить данные пользователя
   * доступ: Админ, Владелец компании
   */
  updateSingleUser: ({ userId, updates }: UpdateUserParams) => {
    return apiClient.put(`/users/user/${userId}`, updates);
  },

  /**
   * Запрос на обновление данные о пользователе.
   * доступ: Админ
   */
  updateUser: ({ userId, updates }: UpdateUserParams) => {
    return apiClient.put(`/admin/users/${userId}`, updates);
  },

  /**
   * Залогиниться под конкретным пользователем.
   */
  loginAsUser: ({ userId }: LoginAsUserParams) => {
    return apiClient.post('/admin/auth-under-user/', { userId });
  },

  /**
   * Сгенерировать ссылку для приглашения. (в ссылке лежит token)
   */
  inviteUsers: (params: InviteUsersParams) => {
    return apiClient.post('/users/invite', params);
  },

  /**
   * Запращивает все роли
   */
  getUserRole: () => {
    return apiClient.get('/users/roles');
  },

  /**
   * Cписок пользователей с ролью DEAL
   */
  fetchDealers: (params: FetchDealersParams) => {
    return apiClient.get('/users/dealers', { params });
  },

  /**
   * Запрашивает персональные данные пользователя
   */
  fetchUserInfo: () => {
    return apiClient.get('/users/me');
  },

  /**
   * Запрос на обновление данных о персональных данных
   */
  updateUserInfo: (updates) => {
    return apiClient.put('users/me', updates);
  },
};
