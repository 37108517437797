import { ReactNode } from 'react';
// Mui components
import { ButtonProps, Button, CircularProgress } from '@mui/material';

export interface Props extends ButtonProps {
  title?: string;
  loading?: boolean;
  icon?: ReactNode;
  component?: 'span' | 'label';
  target?: string;
}

/**
 * Кнопка с индекатором загрузки
 */
export const ActionButton = ({
  children,
  title,
  loading,
  icon,
  component,
  ...buttonProps
}: Props) => {
  return (
    <Button
      startIcon={loading ? <CircularProgress size={22} color="inherit" /> : icon}
      component={component}
      {...buttonProps}
    >
      {title}
      {children}
    </Button>
  );
};
