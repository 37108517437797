import { ChartSeries } from '../../../../model';
export const data_year: ChartSeries[] = [
  {
    name: 'Количество заявок в год',
    data: [
      { y: 3000, x: '2020' },
      { y: 20946, x: '2021' },
    ],
  },
];
