export enum ApplicationStatusEnum {
  EP_DRAFT = 'Черновик',
  EP_SENT_TO_BANK = 'Отправлена в Банк',
  BANK_BANK_CLIENT_FAILURED = 'Отказ, т.к. Клиент Банка',
  EP_RETURNED = 'Необходима доработка',
  BANK_IN_PROCESS_BANK = 'В работе Банка',
  BANK_CREDIT_APPROVED = 'Кредит одобрен',
  BANK_BANK_FAILURED = 'Отказ банка',
  BANK_CLIENT_FAILURED = 'Отказ клиента',
  BANK_DEAL_PREPARATION = 'Подготовка к сделке',
  BANK_DEAL_BUNDLING = 'Комплектация сделки',
  BANK_DEAL_SUBSCRIBED = 'Договор подписан',
  BANK_DEAL_DONE = 'Кредит выдан',
  EP_PAYMENT_PREPARE = 'Подготовка оплаты',
  EP_PAYMENT_DONE = 'Вознаграждение олачено',
  CANCELED = 'Отменена',
  CHECK_AGREEMENT = 'Проверка согласия',
  BANK_LOAD_ISSUED_DEC_22 = 'Кредит выдан в декабре 2022',
  BANK_LOAD_ISSUED_JAN_23 = 'Кредит выдан в январе 2023',
  BANK_LOAD_ISSUED_FEB_23 = 'Кредит выдан в феврале 2023',
  BANK_LOAD_ISSUED_MAR_23 = 'Кредит выдан в марте 2023',
  BANK_LOAD_ISSUED_APR_23 = 'Кредит выдан в апреле 2023',
  BANK_LOAD_ISSUED_MAY_23 = 'Кредит выдан в мае 2023',
  BANK_LOAD_ISSUED_JUN_23 = 'Кредит выдан в июне 2023'
}
