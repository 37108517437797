const rootPrivate = '/private';
const rootApplications = `${rootPrivate}/applications` as const;
const rootUsers = `${rootPrivate}/users` as const;
const rootProfile = `${rootPrivate}/profile` as const;
const rootProfiles = `${rootPrivate}/profiles` as const;
const rootServices = `${rootPrivate}/services` as const;

export const privateRoutes = {
  root: rootPrivate,
  exports: `${rootPrivate}/exports`,
  applications: {
    root: rootApplications,
    products: `${rootApplications}/products`,
    list: `${rootApplications}/list`,
    application: (id?: string) => {
      if (id) return `${rootApplications}/list/${id}` as const;
      return `${rootApplications}/list/:id` as const;
    },
  },

  users: {
    root: rootUsers,
    list: `${rootUsers}/list`,
    user: (id?: string) => {
      if (id) return `${rootUsers}/list/${id}` as const;
      return `${rootUsers}/list/:id` as const;
    },
    invited: `${rootUsers}/invite`,
    invitation: `${rootUsers}/invitation`,
  },

  profiles: {
    root: rootProfiles,
    list: `${rootProfiles}/list`,
    profile: (id?: string) => {
      if (id) return `${rootProfiles}/list/${id}` as const;
      return `${rootProfiles}/list/:id` as const;
    },
  },

  profile: {
    root: rootProfile,
    me: `${rootProfile}/me`,
    transactions: `${rootProfile}/transactions`,
    agents: `${rootProfile}/agents`,
    settings: `${rootProfile}/settings`,
    legalInfo: `${rootProfile}/legal-info`,
    rewards: `${rootProfile}/rewards`,
  },

  services: {
    root: rootServices,
  },
} as const;
