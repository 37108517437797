import { ChartSeries } from '../../../../model';
export const data_day: ChartSeries[] = [
  {
    data: [
      { x: '2021-08-03T00:00:00.000Z', y: 1 },
      { x: '2021-08-12T00:00:00.000Z', y: 1 },
      { x: '2021-08-16T00:00:00.000Z', y: 2 },
      { x: '2021-08-23T00:00:00.000Z', y: 2 },
      { x: '2021-08-24T00:00:00.000Z', y: 1 },
      { x: '2021-08-26T00:00:00.000Z', y: 2 },
      { x: '2021-08-27T00:00:00.000Z', y: 1 },
      { x: '2021-09-01T00:00:00.000Z', y: 1 },
      { x: '2021-09-02T00:00:00.000Z', y: 1 },
      { x: '2021-09-03T00:00:00.000Z', y: 1 },
      { x: '2021-09-07T00:00:00.000Z', y: 2 },
      { x: '2021-09-10T00:00:00.000Z', y: 1 },
      { x: '2021-09-14T00:00:00.000Z', y: 2 },
      { x: '2021-09-15T00:00:00.000Z', y: 3 },
      { x: '2021-09-16T00:00:00.000Z', y: 1 },
      { x: '2021-09-17T00:00:00.000Z', y: 7 },
      { x: '2021-09-18T00:00:00.000Z', y: 2 },
      { x: '2021-09-20T00:00:00.000Z', y: 3 },
      { x: '2021-09-21T00:00:00.000Z', y: 1 },
      { x: '2021-09-22T00:00:00.000Z', y: 4 },
      { x: '2021-09-23T00:00:00.000Z', y: 5 },
      { x: '2021-09-24T00:00:00.000Z', y: 3 },
      { x: '2021-09-27T00:00:00.000Z', y: 8 },
      { x: '2021-09-28T00:00:00.000Z', y: 3 },
      { x: '2021-09-29T00:00:00.000Z', y: 6 },
      { x: '2021-09-30T00:00:00.000Z', y: 6 },
      { x: '2021-10-01T00:00:00.000Z', y: 9 },
      { x: '2021-10-02T00:00:00.000Z', y: 1 },
      { x: '2021-10-04T00:00:00.000Z', y: 7 },
      { x: '2021-10-05T00:00:00.000Z', y: 5 },
      { x: '2021-10-06T00:00:00.000Z', y: 11 },
      { x: '2021-10-07T00:00:00.000Z', y: 15 },
      { x: '2021-10-08T00:00:00.000Z', y: 9 },
      { x: '2021-10-11T00:00:00.000Z', y: 13 },
      { x: '2021-10-12T00:00:00.000Z', y: 14 },
      { x: '2021-10-13T00:00:00.000Z', y: 18 },
      { x: '2021-10-14T00:00:00.000Z', y: 12 },
      { x: '2021-10-15T00:00:00.000Z', y: 14 },
      { x: '2021-10-16T00:00:00.000Z', y: 1 },
      { x: '2021-10-17T00:00:00.000Z', y: 1 },
      { x: '2021-10-18T00:00:00.000Z', y: 26 },
      { x: '2021-10-19T00:00:00.000Z', y: 26 },
      { x: '2021-10-20T00:00:00.000Z', y: 12 },
      { x: '2021-10-21T00:00:00.000Z', y: 21 },
      { x: '2021-10-22T00:00:00.000Z', y: 20 },
      { x: '2021-10-23T00:00:00.000Z', y: 4 },
      { x: '2021-10-24T00:00:00.000Z', y: 3 },
      { x: '2021-10-25T00:00:00.000Z', y: 21 },
      { x: '2021-10-26T00:00:00.000Z', y: 41 },
      { x: '2021-10-27T00:00:00.000Z', y: 65 },
      { x: '2021-10-28T00:00:00.000Z', y: 47 },
      { x: '2021-10-29T00:00:00.000Z', y: 51 },
      { x: '2021-10-30T00:00:00.000Z', y: 9 },
      { x: '2021-10-31T00:00:00.000Z', y: 2 },
      { x: '2021-11-01T00:00:00.000Z', y: 44 },
      { x: '2021-11-02T00:00:00.000Z', y: 45 },
      { x: '2021-11-03T00:00:00.000Z', y: 39 },
      { x: '2021-11-04T00:00:00.000Z', y: 10 },
      { x: '2021-11-05T00:00:00.000Z', y: 5 },
      { x: '2021-11-06T00:00:00.000Z', y: 2 },
      { x: '2021-11-07T00:00:00.000Z', y: 1 },
      { x: '2021-11-08T00:00:00.000Z', y: 52 },
      { x: '2021-11-09T00:00:00.000Z', y: 46 },
      { x: '2021-11-10T00:00:00.000Z', y: 52 },
      { x: '2021-11-11T00:00:00.000Z', y: 33 },
      { x: '2021-11-12T00:00:00.000Z', y: 31 },
      { x: '2021-11-13T00:00:00.000Z', y: 2 },
      { x: '2021-11-14T00:00:00.000Z', y: 3 },
      { x: '2021-11-15T00:00:00.000Z', y: 32 },
      { x: '2021-11-16T00:00:00.000Z', y: 29 },
      { x: '2021-11-17T00:00:00.000Z', y: 23 },
      { x: '2021-11-18T00:00:00.000Z', y: 16 },
      { x: '2021-11-19T00:00:00.000Z', y: 13 },
      { x: '2021-11-20T00:00:00.000Z', y: 1 },
      { x: '2021-11-21T00:00:00.000Z', y: 2 },
      { x: '2021-11-22T00:00:00.000Z', y: 18 },
      { x: '2021-11-23T00:00:00.000Z', y: 12 },
      { x: '2021-11-24T00:00:00.000Z', y: 7 },
      { x: '2021-11-25T00:00:00.000Z', y: 3 },
    ],
  },
];
