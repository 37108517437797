import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
// Hooks
import { useNotifications } from 'modules/notification';
// Api
import { applicationApi } from 'lib/api';

interface Props {
  invalidateKey?: string;
  route?: (id: string) => string;
}

/**
 * Хук (мутация) создания заявки
 */
export const useCreateApplication = ({ invalidateKey, route }: Props) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const { showSuccess } = useNotifications();

  return useMutation(() => applicationApi.createApplication({ type: 'MORTGAGE' }), {
    onSuccess: ({ data }) => {
      showSuccess('Заявка создана. Данные заявки сохранятся автоматически.');
      if (invalidateKey) queryClient.invalidateQueries(invalidateKey);
      if (route) {
        const { _id } = data;
        history.push(route(_id));
      }
    },
  });
};
