import { FC } from 'react';
import { useTheme } from '@mui/material';

/**
 * Иконка продукта "Ипотека"
 */
export const MortgageIcon: FC = () => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '100%', maxWidth: 200 }}
      viewBox="0 0 965 487"
    >
      <defs>
        <linearGradient id="mortgage-a" x1="50.005%" x2="50.005%" y1="99.986%" y2="-.018%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-b" x1="50.038%" x2="50.038%" y1="100.004%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-c" x1="50.005%" x2="50.005%" y1="100.004%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-d" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-e" x1="50.017%" x2="50.017%" y1="100%" y2=".014%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-f" x1="50.056%" x2="50.056%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-g" x1="50.007%" x2="50.007%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-h" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-i" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-j" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-k" x1="50%" x2="50%" y1="100.002%" y2=".002%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-l" x1="50%" x2="50%" y1="100.006%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-m" x1="50.004%" x2="50.004%" y1="99.968%" y2="-.006%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-n" x1="50%" x2="50%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-o" x1="50.03%" x2="50.03%" y1="100.032%" y2=".04%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-p" x1="50.09%" x2="50.09%" y1="100%" y2="0%">
          <stop offset="0%" stopColor="gray" stopOpacity=".25" />
          <stop offset="54%" stopColor="gray" stopOpacity=".12" />
          <stop offset="100%" stopColor="gray" stopOpacity=".1" />
        </linearGradient>
        <linearGradient id="mortgage-q" x1="50.004%" x2="50.004%" y1="100.007%" y2=".003%">
          <stop offset="0%" stopColor="#B3B3B3" stopOpacity=".25" />
          <stop offset="54%" stopColor="#B3B3B3" stopOpacity=".1" />
          <stop offset="100%" stopColor="#B3B3B3" stopOpacity=".05" />
        </linearGradient>
        <linearGradient id="mortgage-r" x1="49.99%" x2="49.99%" y1="100.052%" y2="0%">
          <stop offset="0%" stopOpacity=".12" />
          <stop offset="55%" stopOpacity=".09" />
          <stop offset="100%" stopOpacity=".02" />
        </linearGradient>
        <linearGradient id="mortgage-s" x1="49.995%" x2="49.995%" y1="99.921%" y2="-.01%">
          <stop offset="0%" stopOpacity=".12" />
          <stop offset="55%" stopOpacity=".09" />
          <stop offset="100%" stopOpacity=".02" />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" transform="translate(-1 -1)">
        <path
          stroke="#FF9800"
          strokeDasharray="12"
          strokeWidth="4"
          d="M216.7 417.54C317.215824 417.54 398.7 336.055824 398.7 235.54 398.7 135.024176 317.215824 53.54 216.7 53.54 116.184176 53.54 34.7 135.024176 34.7 235.54 34.7 336.055824 116.184176 417.54 216.7 417.54zM696.7 381.54C797.215824 381.54 878.7 300.055824 878.7 199.54 878.7 99.0241755 797.215824 17.54 696.7 17.54 596.184176 17.54 514.7 99.0241755 514.7 199.54 514.7 300.055824 596.184176 381.54 696.7 381.54z"
          opacity=".3"
        />
        <path
          fill="url(#mortgage-a)"
          fillRule="nonzero"
          d="M21.06,112.87 C13.2197975,104.533838 8.85010565,93.5237877 8.84,82.08 C8.9320777,66.0227104 17.4429471,51.1913248 31.26,43.01 C28.2211251,38.5972858 26.5895458,33.3678649 26.58,28.01 C26.58,12.92 39.24,0.68852145 54.86,0.68852145 C67.2460457,0.563633408 78.2592366,8.54850126 81.99,20.36 C89.8275383,8.02829366 103.458726,0.596878199 118.07,0.68852145 C141.34,0.68852145 160.21,18.92 160.21,41.4 C160.213274,46.2014148 159.338906,50.9629926 157.63,55.45 C158.48,55.39 159.34,55.32 160.21,55.32 C178.89,55.32 194.03,69.95 194.03,88 C194.03031,89.1021964 193.973567,90.20367 193.86,91.3 C208.8,97.52 219.26,111.87 219.26,128.58 C219.26,150.99 200.46,169.16 177.26,169.16 C174.82361,169.156589 172.391783,168.949198 169.99,168.54 C170.11,169.89 170.19,171.26 170.19,172.64 C170.19,197.79 149.09,218.180209 123.06,218.180209 C113.505575,218.208186 104.160118,215.384354 96.22,210.07 C89.7952555,215.33719 81.7378298,218.204488 73.43,218.180209 C53.98,218.180209 38.22,202.95 38.22,184.18 C38.22,183.37 38.29,182.57 38.35,181.77 L38.22,181.77 C17.4,181.77 0.52,165.46 0.52,145.34 C0.608063758,131.483442 8.57712808,118.885802 21.06,112.87 L21.06,112.87 Z"
          transform="translate(746 157)"
        />
        <path
          fill="#009688"
          fillRule="nonzero"
          d="M770.87,269.72 C763.370668,261.738669 759.190953,251.201781 759.18,240.25 C759.266561,224.87907 767.413282,210.68115 780.64,202.85 C777.724647,198.617319 776.162475,193.599539 776.16,188.46 C776.16,174.02 788.28,162.308652 803.23,162.308652 C815.089485,162.192053 825.637424,169.827138 829.23,181.13 C836.724757,169.353753 849.741254,162.250755 863.7,162.32 C885.98,162.32 904.03,179.77 904.03,201.32 C904.03302,205.916378 903.195933,210.474604 901.56,214.77 C902.38,214.71 903.2,214.65 904.03,214.65 C921.91,214.65 936.4,228.65 936.4,245.93 C936.397928,246.985562 936.341185,248.040308 936.23,249.09 C950.53,255.09 960.54,268.78 960.54,284.77 C960.54,306.22 942.54,323.61 920.34,323.61 C917.994241,323.610191 915.652633,323.412827 913.34,323.02 C913.46,324.31 913.53,325.62 913.53,326.94 C913.53,351.01 893.33,370.52 868.42,370.52 C859.275713,370.544283 850.33168,367.842621 842.73,362.76 C836.604954,367.789341 828.925305,370.54 821,370.54 C802.39,370.54 787.3,355.96 787.3,337.98 C787.3,337.2 787.36,336.44 787.42,335.67 L787.3,335.67 C767.37,335.67 751.21,320.06 751.21,300.8 C751.295102,287.537059 758.922432,275.479206 770.87,269.72 L770.87,269.72 Z"
        />
        <line x1="847.33" x2="847.33" y1="224.13" y2="466.37" fill="#795548" fillRule="nonzero" />
        <rect
          width="13.19"
          height="242.23"
          x="840.51"
          y="224.13"
          fill="url(#mortgage-b)"
          fillRule="nonzero"
        />
        <rect width="7.3" height="242.23" x="843.68" y="224.13" fill="#795548" fillRule="nonzero" />
        <line x1="848.07" x2="804.81" y1="296.68" y2="252.56" fill="#795548" fillRule="nonzero" />
        <rect
          width="7.3"
          height="61.79"
          x="822.795"
          y="243.729"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-44.43 826.445 274.624)"
        />
        <line x1="848.07" x2="892.91" y1="334.6" y2="303.14" fill="#795548" fillRule="nonzero" />
        <rect
          width="54.78"
          height="7.3"
          x="843.099"
          y="315.194"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-35.06 870.49 318.844)"
        />
        <path
          fill="url(#mortgage-c)"
          fillRule="nonzero"
          d="M192.15,115.12 C201.249137,104.711661 205.389449,90.8699537 203.500126,77.174762 C201.610804,63.4795703 193.877397,51.2759041 182.3,43.72 C185.259331,39.1406649 186.829226,33.8023267 186.82,28.35 C186.82,12.93 174.6,0.43 159.52,0.43 C147.275585,0.520416917 136.578674,8.72682625 133.32,20.53 C126.092767,8.13066854 112.841775,0.483670536 98.49,0.43 C76.02,0.43 57.8,19.06 57.8,42.04 C57.7943397,46.9345577 58.6368004,51.7930859 60.29,56.4 C59.47,56.34 58.64,56.27 57.8,56.27 C39.8,56.27 25.14,71.27 25.14,89.67 C25.14,90.81 25.2,91.93 25.31,93.04 C10.3112481,99.7633399 0.692287423,114.703511 0.779405151,131.14 C0.779405151,154.05 18.94,172.62 41.33,172.62 C43.6773294,172.616471 46.0197616,172.405652 48.33,171.99 C48.21,173.37 48.14,174.77 48.14,176.18 C48.14,201.88 68.51,222.720002 93.65,222.720002 C102.939341,222.723015 111.997698,219.824761 119.56,214.43 C125.646299,219.768253 133.464325,222.714228 141.56,222.720002 C160.34,222.720002 175.56,207.15 175.56,187.95 C175.56,187.12 175.49,186.3 175.44,185.48 L175.56,185.48 C195.67,185.48 211.960401,168.81 211.960401,148.25 C212.024095,134.384027 204.395246,121.625634 192.15,115.12 L192.15,115.12 Z"
          transform="translate(662 159)"
        />
        <path
          fill="#00897B"
          fillRule="nonzero"
          d="M848.89,273.9 C857.423848,264.13053 861.305356,251.142313 859.531293,238.29234 C857.757229,225.442368 850.501699,213.991726 839.64,206.9 C842.417272,202.600426 843.889925,197.588543 843.88,192.47 C843.88,177.99 832.41,166.26 818.25,166.26 C806.774621,166.363089 796.757083,174.058843 793.7,185.12 C786.916595,173.477185 774.474698,166.297411 761,166.25 C739.91,166.25 722.81,183.74 722.81,205.31 C722.805447,209.904771 723.59716,214.465578 725.15,218.79 C724.38,218.73 723.6,218.67 722.81,218.67 C705.88,218.67 692.16,232.67 692.16,250.02 C692.16,251.09 692.21,252.14 692.32,253.18 C678.255172,259.506914 669.239755,273.527847 669.319469,288.95 C669.319469,310.45 686.32,327.88 707.39,327.88 C709.599776,327.878248 711.805056,327.68081 713.98,327.29 C713.87,328.59 713.8,329.9 713.8,331.22 C713.8,355.35 732.92,374.900003 756.51,374.900003 C765.229188,374.903447 773.731727,372.183474 780.83,367.12 C786.542987,372.130246 793.881266,374.894982 801.48,374.900003 C819.1,374.900003 833.39,360.29 833.39,342.26 C833.39,341.48 833.33,340.71 833.28,339.94 L833.39,339.94 C852.26,339.94 867.560254,324.29 867.560254,304.99 C867.609496,291.959181 860.415677,279.979758 848.89,273.9 Z"
        />
        <rect
          width="9.92"
          height="246.78"
          x="771.59"
          y="224.04"
          fill="url(#mortgage-d)"
          fillRule="nonzero"
        />
        <rect
          width="4.99"
          height="243.31"
          x="774.41"
          y="227.51"
          fill="#795548"
          fillRule="nonzero"
        />
        <rect
          width="60.28"
          height="4.99"
          x="766.109"
          y="276.249"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-47.2 796.249 278.744)"
        />
        <rect
          width="4.99"
          height="52.89"
          x="752.071"
          y="296.733"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-53.4 754.566 323.178)"
        />
        <path
          fill="url(#mortgage-e)"
          fillRule="nonzero"
          d="M14.01,77.28 C8.66781138,71.5922587 5.69277969,64.0831643 5.69,56.28 C5.75481582,45.3389452 11.5550722,35.2340483 20.97,29.66 C18.8934813,26.645081 17.7811042,23.0708285 17.78,19.41 C17.78,9.13 26.41,0.789010176 37.05,0.789010176 C45.4894365,0.704674601 52.9939574,6.14332144 55.54,14.19 C60.883312,5.78801387 70.173082,0.725674808 80.13,0.789010176 C95.99,0.789010176 108.85,13.21 108.85,28.53 C108.852304,31.8039288 108.255824,35.0506751 107.09,38.11 C107.67,38.11 108.25,38.02 108.85,38.02 C121.58,38.02 131.85,48.02 131.85,60.29 C131.848865,61.0415686 131.808812,61.7925742 131.73,62.54 C142.111376,66.7254659 148.945171,76.7570516 149.04,87.95 C149.04,103.22 136.23,115.6 120.42,115.6 C118.74448,115.60105 117.071866,115.46055 115.42,115.18 C115.51,116.1 115.56,117.03 115.56,117.97 C115.56,135.11 101.18,148.97 83.44,148.97 C76.966667,149.000057 70.6297051,147.110452 65.23,143.54 C60.8456597,147.112696 55.3555899,149.049952 49.7,149.020342 C36.45,149.020342 25.7,138.64 25.7,125.84 C25.7,125.28 25.7,124.74 25.79,124.2 L25.7,124.2 C11.51,124.2 0.01,113.09 0.01,99.38 C0.0815180673,89.9466519 5.51152222,81.3750025 14.01,77.28 L14.01,77.28 Z"
          transform="translate(649 269)"
        />
        <path
          fill="#009688"
          fillRule="nonzero"
          d="M665.6,346.18 C660.477747,340.746972 657.617171,333.566925 657.6,326.1 C657.661764,315.626289 663.21571,305.953387 672.23,300.62 C670.243483,297.734257 669.179911,294.313393 669.18,290.81 C669.18,280.97 677.44,272.988984 687.62,272.988984 C695.700591,272.905364 702.88645,278.114096 705.32,285.82 C710.432051,277.778237 719.321119,272.931372 728.85,272.988984 C744.03,272.988984 756.34,284.88 756.34,299.55 C756.343719,302.683525 755.774353,305.791313 754.66,308.72 C755.22,308.72 755.77,308.64 756.34,308.64 C768.52,308.64 778.4,318.18 778.4,329.95 C778.400417,330.668084 778.363701,331.385708 778.29,332.1 C788.225967,336.106469 794.767174,345.707082 794.86,356.42 C794.86,371.04 782.59,382.89 767.46,382.89 C765.871608,382.889127 764.286075,382.755326 762.72,382.49 C762.8,383.37 762.85,384.26 762.85,385.16 C762.85,401.56 749.09,414.860092 732.11,414.860092 C725.88056,414.875094 719.787945,413.033384 714.61,409.57 C710.420658,413.004387 705.167138,414.874583 699.75,414.860092 C687.07,414.860092 676.75,404.93 676.75,392.67 C676.75,392.14 676.75,391.62 676.83,391.1 L676.75,391.1 C663.17,391.1 652.16,380.46 652.16,367.34 C652.233241,358.299326 657.447919,350.089312 665.6,346.18 Z"
        />
        <line x1="717.71" x2="717.71" y1="315.11" y2="480.18" fill="#795548" fillRule="nonzero" />
        <rect
          width="8.99"
          height="165.07"
          x="713.06"
          y="315.11"
          fill="url(#mortgage-f)"
          fillRule="nonzero"
        />
        <rect
          width="4.97"
          height="165.07"
          x="715.22"
          y="315.11"
          fill="#795548"
          fillRule="nonzero"
        />
        <line x1="718.21" x2="688.74" y1="364.55" y2="334.48" fill="#795548" fillRule="nonzero" />
        <rect
          width="4.97"
          height="42.1"
          x="700.982"
          y="328.46"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-44.43 703.467 349.51)"
        />
        <line x1="718.21" x2="748.77" y1="390.39" y2="368.95" fill="#795548" fillRule="nonzero" />
        <rect
          width="37.33"
          height="4.97"
          x="714.83"
          y="377.158"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-35.06 733.495 379.643)"
        />
        <path
          fill="url(#mortgage-g)"
          fillRule="nonzero"
          d="M128.58,77.18 C134.700174,70.2000681 137.487641,60.9064104 136.219157,51.7103823 C134.950673,42.5143543 129.751271,34.3223912 121.97,29.26 C123.94234,26.1804013 124.984018,22.5970285 124.97,18.94 C125.191356,9.70173968 118.483358,1.75120485 109.339648,0.414405256 C100.195939,-0.922394343 91.492626,4.77502481 89.06,13.69 C84.2115738,5.37395118 75.3261143,0.242750498 65.7,0.2 C50.62,0.2 38.39,12.7 38.39,28.13 C38.3856318,31.4156759 38.9506571,34.6772595 40.06,37.77 C39.51,37.77 38.95,37.68 38.39,37.68 C32.5100316,37.7459316 26.897126,40.1451636 22.7862485,44.3498112 C18.6753709,48.5544587 16.4033207,54.22004 16.47,60.1 C16.47,60.86 16.47,61.62 16.58,62.36 C6.51192027,66.8748226 0.0570486085,76.906148 0.119543668,87.94 C0.119543668,103.31 12.31,115.78 27.34,115.78 C28.9193177,115.779261 30.4954698,115.638713 32.05,115.36 C31.97,116.29 31.92,117.22 31.92,118.17 C31.92,135.42 45.6,149.410004 62.46,149.410004 C68.6942582,149.413019 74.7738601,147.469225 79.85,143.85 C83.9367635,147.432162 89.1855255,149.407999 94.62,149.410004 C100.74132,149.341431 106.584643,146.843785 110.864306,142.466601 C115.143969,138.089417 117.509346,132.191311 117.44,126.07 C117.44,125.51 117.44,124.96 117.36,124.41 L117.44,124.41 C130.94,124.41 141.880228,113.22 141.880228,99.41 C141.919594,90.1049043 136.798057,81.5446209 128.58,77.18 Z"
          transform="translate(619 274)"
        />
        <path
          fill="#00897B"
          fillRule="nonzero"
          d="M744.05,351.04 C749.787232,344.480967 752.398076,335.75623 751.20681,327.12387 C750.015545,318.491511 745.139258,310.799998 737.84,306.04 C739.704383,303.156042 740.694207,299.794111 740.69,296.36 C740.899087,287.687138 734.602243,280.222441 726.018175,278.967043 C717.434107,277.711646 709.263316,283.060489 706.98,291.43 C702.424613,283.617589 694.073471,278.800939 685.03,278.77 C670.87,278.77 659.4,290.51 659.4,304.99 C659.396419,308.074727 659.927616,311.136727 660.97,314.04 C660.45,314.04 659.97,313.96 659.4,313.96 C647.926354,314.091848 638.724479,323.486082 638.83,334.96 C638.83,335.68 638.83,336.39 638.93,337.08 C629.483082,341.316165 623.42491,350.726917 623.479627,361.08 C623.479627,375.51 634.92,387.21 649.03,387.21 C650.512249,387.207333 651.991354,387.073477 653.45,386.81 C653.37,387.68 653.33,388.56 653.33,389.45 C653.33,405.64 666.17,418.77 682,418.77 C687.854295,418.774197 693.563617,416.949172 698.33,413.55 C702.162755,416.915466 707.089376,418.77 712.19,418.77 C724.150452,418.632689 733.737742,408.830558 733.61,396.87 C733.61,396.35 733.61,395.87 733.53,395.32 L733.61,395.32 C739.763145,395.251429 745.636989,392.74114 749.939157,388.341451 C754.241324,383.941763 756.619344,378.013137 756.55,371.86 C756.567582,363.138692 751.755854,355.124278 744.05,351.04 L744.05,351.04 Z"
        />
        <rect
          width="6.66"
          height="165.64"
          x="692.17"
          y="317.57"
          fill="url(#mortgage-h)"
          fillRule="nonzero"
        />
        <rect width="3.35" height="163.31" x="694.06" y="319.9" fill="#795548" fillRule="nonzero" />
        <rect
          width="40.46"
          height="3.35"
          x="688.482"
          y="352.602"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-47.2 708.712 354.277)"
        />
        <rect
          width="3.35"
          height="35.5"
          x="679.058"
          y="366.363"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-53.4 680.733 384.113)"
        />
        <rect
          width="282"
          height="251"
          x="406.26"
          y="236.33"
          fill="url(#mortgage-i)"
          fillRule="nonzero"
        />
        <rect width="460" height="243" x="221.26" y="236.33" fill="#FFF" fillRule="nonzero" />
        <path
          fill="url(#mortgage-j)"
          fillRule="nonzero"
          d="M703.27,260.33 L251.27,260.33 L251.27,142.33 L686.77,142.33 C695.882698,142.33 703.27,149.717302 703.27,158.83 L703.27,260.33 Z"
        />
        <path
          fill={primaryColor}
          fillRule="nonzero"
          d="M251.26,147 L682.88,147 C691.992698,147 699.38,154.387302 699.38,163.5 L699.38,257.29 L251.26,257.29 L251.26,147 Z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M251.26,147 L682.88,147 C691.992698,147 699.38,154.387302 699.38,163.5 L699.38,257.29 L251.26,257.29 L251.26,147 Z"
          opacity=".7"
        />
        <polygon
          fill="url(#mortgage-k)"
          fillRule="nonzero"
          points="250.26 14.32 94.26 124.18 94.26 216.75 94.26 487.32 406.26 487.32 406.26 216.75 406.26 124.18"
        />
        <polygon
          fill={primaryColor}
          fillRule="nonzero"
          points="399.26 122.33 251.26 14.32 103.26 122.33 103.26 213.32 399.26 213.32"
        />
        <polygon
          fill="#FFF"
          fillRule="nonzero"
          points="399.26 122.33 251.26 14.32 103.26 122.33 103.26 213.32 399.26 213.32"
          opacity=".5"
        />
        <rect width="296" height="266" x="103.26" y="213.33" fill="#FFF" fillRule="nonzero" />
        <polygon
          fill="url(#mortgage-l)"
          fillRule="nonzero"
          points="418.26 176.32 251.26 57.07 82.26 176.32 71.26 134.38 251.26 3.37 431.26 134.38"
        />
        <polygon
          fill={primaryColor}
          fillRule="nonzero"
          points="416.73 170.18 251.26 49.75 85.8 170.18 78.73 132.47 251.26 6.91 423.8 132.47"
        />
        <polygon
          fill="#000"
          fillRule="nonzero"
          points="416.73 170.18 251.26 49.75 85.8 170.18 78.73 132.47 251.26 6.91 423.8 132.47"
          opacity=".2"
        />
        <rect width="168" height="208" x="167.26" y="271.33" fill="#F2F2F2" fillRule="nonzero" />
        <rect
          width="168"
          height="36"
          x="167.26"
          y="271.33"
          fill={primaryColor}
          fillRule="nonzero"
        />
        <rect
          width="168"
          height="36"
          x="167.26"
          y="443.33"
          fill={primaryColor}
          fillRule="nonzero"
        />
        <path
          fill="#0F0"
          fillRule="nonzero"
          d="M532.6,404.54 C533.428427,404.54 534.1,403.868427 534.1,403.04 C534.1,402.211573 533.428427,401.54 532.6,401.54 C531.771573,401.54 531.1,402.211573 531.1,403.04 C531.1,403.868427 531.771573,404.54 532.6,404.54 L532.6,404.54 Z"
        />
        <rect width="82" height="152" x="491.26" y="327.33" fill="#F2F2F2" fillRule="nonzero" />
        <rect width="77" height="56" x="594.26" y="295.33" fill="#F2F2F2" fillRule="nonzero" />
        <rect
          width="77"
          height="25"
          x="594.26"
          y="295.33"
          fill={primaryColor}
          fillRule="nonzero"
          opacity=".2"
        />
        <path
          fill="url(#mortgage-m)"
          fillRule="nonzero"
          d="M12.7,64.09 C8.22666141,59.3627807 5.72340314,53.1082112 5.7,46.6 C5.76133861,37.4809516 10.5957283,29.0606136 18.44,24.41 C16.7094902,21.8981348 15.7819575,18.9202667 15.78,15.87 C15.9333303,7.15125466 23.1211095,0.205156641 31.84,0.35 C38.8899788,0.270769621 45.1609115,4.8155712 47.28,11.54 C51.7348857,4.53598773 59.4794401,0.316150029 67.78,0.37 C81,0.37 91.72,10.73 91.72,23.5 C91.7225516,26.2340514 91.2243319,28.9454509 90.25,31.5 C90.74,31.5 91.25,31.43 91.72,31.43 C102.33,31.43 110.93,39.74 110.93,49.99 C110.928867,50.6146388 110.89549,51.2388027 110.83,51.86 C119.483301,55.3488488 125.179912,63.7101942 125.26,73.04 C125.26,85.77 114.58,96.09 101.4,96.09 C100.015979,96.0887799 98.6344886,95.9717044 97.27,95.74 C97.34,96.51 97.38,97.28 97.38,98.07 C97.38,112.36 85.38,123.940096 70.61,123.940096 C65.1812905,123.95436 59.8716542,122.349283 55.36,119.33 C51.7095365,122.323673 47.1309932,123.953564 42.41,123.940096 C31.41,123.940096 22.41,115.29 22.41,104.61 C22.41,104.15 22.41,103.69 22.48,103.24 L22.41,103.24 C10.58,103.24 0.99,93.97 0.99,82.55 C1.04194458,74.6636257 5.58764673,67.4976427 12.7,64.09 Z"
          transform="translate(49 312)"
        />
        <path
          fill="#009688"
          fillRule="nonzero"
          d="M63.83,376.01 C59.5708989,371.47585 57.1969027,365.490805 57.19,359.27 C57.2404391,350.540309 61.8679222,342.477328 69.38,338.03 C67.7243833,335.623525 66.838633,332.770991 66.84,329.85 C66.9083665,325.839735 68.5673095,322.020949 71.4517497,319.234032 C74.33619,316.447115 78.2097551,314.920448 82.22,314.99 C88.9523323,314.922512 94.9386637,319.261081 96.97,325.68 C101.233096,318.976118 108.645615,314.937391 116.59,314.99 C129.24,314.99 139.5,324.9 139.5,337.13 C139.502596,339.740684 139.028138,342.32987 138.1,344.77 C138.56,344.77 139.03,344.7 139.5,344.7 C149.66,344.7 157.89,352.7 157.89,362.47 C157.890823,363.067818 157.860783,363.66528 157.8,364.26 C166.079089,367.60576 171.526254,375.610723 171.6,384.54 C171.6,396.73 161.38,406.61 148.76,406.61 C147.419457,406.609853 146.081336,406.496113 144.76,406.27 C144.83,407 144.87,407.75 144.87,408.5 C144.87,422.17 133.4,433.26 119.24,433.26 C114.06238,433.26 109.001469,431.72186 104.7,428.84 C101.207342,431.704047 96.8267746,433.263232 92.31,433.25 C81.74,433.25 73.17,424.97 73.17,414.75 C73.17,414.31 73.17,413.87 73.24,413.44 L73.17,413.44 C61.85,413.44 52.67,404.57 52.67,393.63 C52.7302402,386.110439 57.0575552,379.278244 63.83,376.01 L63.83,376.01 Z"
        />
        <line x1="107.27" x2="107.27" y1="350.11" y2="487.72" fill="#795548" fillRule="nonzero" />
        <rect
          width="7.5"
          height="137.61"
          x="103.39"
          y="350.11"
          fill="url(#mortgage-n)"
          fillRule="nonzero"
        />
        <rect
          width="4.14"
          height="137.61"
          x="105.19"
          y="350.11"
          fill="#795548"
          fillRule="nonzero"
        />
        <line x1="107.69" x2="83.11" y1="391.32" y2="366.25" fill="#795548" fillRule="nonzero" />
        <rect
          width="4.14"
          height="35.1"
          x="93.324"
          y="361.234"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-44.43 95.394 378.784)"
        />
        <line x1="107.69" x2="133.16" y1="412.86" y2="394.99" fill="#795548" fillRule="nonzero" />
        <rect
          width="31.12"
          height="4.14"
          x="104.865"
          y="401.838"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-35.06 120.425 403.908)"
        />
        <path
          fill="url(#mortgage-m)"
          fillRule="nonzero"
          d="M12.7,64.09 C8.22666141,59.3627807 5.72340314,53.1082112 5.7,46.6 C5.76133861,37.4809516 10.5957283,29.0606136 18.44,24.41 C16.7094902,21.8981348 15.7819575,18.9202667 15.78,15.87 C15.9333303,7.15125466 23.1211095,0.205156641 31.84,0.35 C38.8899788,0.270769621 45.1609115,4.8155712 47.28,11.54 C51.7348857,4.53598773 59.4794401,0.316150029 67.78,0.37 C81,0.37 91.72,10.73 91.72,23.5 C91.7225516,26.2340514 91.2243319,28.9454509 90.25,31.5 C90.74,31.5 91.25,31.43 91.72,31.43 C102.33,31.43 110.93,39.74 110.93,49.99 C110.928867,50.6146388 110.89549,51.2388027 110.83,51.86 C119.483301,55.3488488 125.179912,63.7101942 125.26,73.04 C125.26,85.77 114.58,96.09 101.4,96.09 C100.015979,96.0887799 98.6344886,95.9717044 97.27,95.74 C97.34,96.51 97.38,97.28 97.38,98.07 C97.38,112.36 85.38,123.940096 70.61,123.940096 C65.1812905,123.95436 59.8716542,122.349283 55.36,119.33 C51.7095365,122.323673 47.1309932,123.953564 42.41,123.940096 C31.41,123.940096 22.41,115.29 22.41,104.61 C22.41,104.15 22.41,103.69 22.48,103.24 L22.41,103.24 C10.58,103.24 0.99,93.97 0.99,82.55 C1.04194458,74.6636257 5.58764673,67.4976427 12.7,64.09 Z"
          transform="translate(0 312)"
        />
        <path
          fill="#009688"
          fillRule="nonzero"
          d="M14.83,376.01 C10.5708989,371.47585 8.19690269,365.490805 8.19,359.27 C8.24043908,350.540309 12.8679222,342.477328 20.38,338.03 C18.7243833,335.623525 17.838633,332.770991 17.84,329.85 C17.9083665,325.839735 19.5673095,322.020949 22.4517497,319.234032 C25.33619,316.447115 29.2097551,314.920448 33.22,314.99 C39.9523323,314.922512 45.9386637,319.261081 47.97,325.68 C52.2330961,318.976118 59.6456148,314.937391 67.59,314.99 C80.24,314.99 90.5,324.9 90.5,337.13 C90.5025962,339.740684 90.028138,342.32987 89.1,344.77 C89.56,344.77 90.03,344.7 90.5,344.7 C100.66,344.7 108.89,352.7 108.89,362.47 C108.890823,363.067818 108.860783,363.66528 108.8,364.26 C117.079089,367.60576 122.526254,375.610723 122.6,384.54 C122.6,396.73 112.38,406.61 99.76,406.61 C98.4194566,406.609853 97.0813363,406.496113 95.76,406.27 C95.83,407 95.87,407.75 95.87,408.5 C95.87,422.17 84.4,433.26 70.24,433.26 C65.0623798,433.26 60.0014686,431.72186 55.7,428.84 C52.2073417,431.704047 47.8267746,433.263232 43.31,433.25 C32.74,433.25 24.17,424.97 24.17,414.75 C24.17,414.31 24.17,413.87 24.24,413.44 L24.17,413.44 C12.85,413.44 3.67,404.57 3.67,393.63 C3.73024016,386.110439 8.05755519,379.278244 14.83,376.01 L14.83,376.01 Z"
        />
        <line x1="58.27" x2="58.27" y1="350.11" y2="487.72" fill="#795548" fillRule="nonzero" />
        <rect
          width="7.5"
          height="137.61"
          x="54.39"
          y="350.11"
          fill="url(#mortgage-n)"
          fillRule="nonzero"
        />
        <rect width="4.14" height="137.61" x="56.19" y="350.11" fill="#795548" fillRule="nonzero" />
        <line x1="58.69" x2="34.11" y1="391.32" y2="366.25" fill="#795548" fillRule="nonzero" />
        <rect
          width="4.14"
          height="35.1"
          x="44.333"
          y="361.236"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-44.43 46.403 378.786)"
        />
        <line x1="58.69" x2="84.16" y1="412.86" y2="394.99" fill="#795548" fillRule="nonzero" />
        <rect
          width="31.12"
          height="4.14"
          x="55.866"
          y="401.845"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-35.06 71.426 403.915)"
        />
        <path
          fill="url(#mortgage-o)"
          fillRule="nonzero"
          d="M107.17,65.18 C112.27977,59.3548403 114.608018,51.5977751 113.55062,43.9215683 C112.493223,36.2453615 108.154412,29.4066836 101.66,25.18 C103.31593,22.6177106 104.194636,19.6308049 104.19,16.58 C104.380224,8.87453438 98.7872942,2.2401146 91.1606597,1.12439452 C83.5340252,0.00867443977 76.2750139,4.76295056 74.25,12.2 C70.2060448,5.2610829 62.7912624,0.981128958 54.76,0.95 C48.6511635,1.01063499 42.8174266,3.4991276 38.5457177,7.86651382 C34.2740087,12.2339 31.915327,18.121319 31.99,24.23 C31.9898685,26.9567557 32.4600959,29.6631005 33.38,32.23 C32.92,32.23 32.46,32.16 31.99,32.16 C27.0693997,32.2099406 22.3719207,34.2203136 18.9386454,37.7455684 C15.5053701,41.2708232 13.6198626,46.0197989 13.7,50.94 C13.7,51.58 13.7,52.21 13.79,52.83 C5.39985936,56.5945642 0.0202480028,64.9541352 0.0690017143,74.15 C-0.0467774127,86.8141651 10.0964397,97.1898402 22.76,97.36 C24.0777658,97.3596273 25.3929074,97.2425027 26.69,97.01 C26.62,97.78 26.58,98.56 26.58,99.35 C26.58,113.73 37.98,125.35 52.04,125.35 C57.2381829,125.34842 62.3065999,123.726526 66.54,120.71 C69.9454185,123.697397 74.3199446,125.346284 78.85,125.35 C89.4644586,125.218226 97.9666811,116.514635 97.85,105.9 C97.85,105.43 97.85,104.98 97.78,104.52 L97.85,104.52 C109.225019,104.388187 118.342194,95.0651268 118.22,83.69 C118.252837,75.9486388 113.999897,68.8244833 107.17,65.18 Z"
          transform="translate(17 312)"
        />
        <path
          fill="#00897B"
          fillRule="nonzero"
          d="M121.23,377.06 C126.009461,371.593026 128.183646,364.322271 127.19001,357.128959 C126.196374,349.935646 122.132548,343.526569 116.05,339.56 C117.602245,337.155087 118.425354,334.352349 118.42,331.49 C118.612645,324.249546 113.361368,318.008441 106.194605,316.960206 C99.0278421,315.911972 92.209089,320.387674 90.32,327.38 C86.5231286,320.864989 79.560614,316.847275 72.02,316.82 C60.1028333,316.984632 50.5665598,326.762443 50.7,338.68 C50.697911,341.25013 51.1411324,343.80119 52.01,346.22 C51.58,346.22 51.14,346.15 50.7,346.15 C41.121538,346.25984 33.4444695,354.11149 33.55,363.69 C33.55,364.29 33.55,364.88 33.64,365.46 C25.7670747,368.990341 20.7171227,376.831882 20.76,385.46 C20.6322542,397.354286 30.1658204,407.102693 42.06,407.24 C43.297334,407.239236 44.5321655,407.128804 45.75,406.91 C45.69,407.64 45.65,408.37 45.65,409.11 C45.65,422.61 56.35,433.550005 69.55,433.550005 C74.4290266,433.553066 79.1871976,432.03227 83.16,429.2 C86.3547286,431.995484 90.4548832,433.537484 94.7,433.54 C99.4899031,433.487249 104.062635,431.533727 107.412098,428.109248 C110.76156,424.684769 112.613338,420.069897 112.56,415.28 C112.56,414.84 112.56,414.41 112.5,413.98 L112.56,413.98 C117.689392,413.921983 122.585613,411.828528 126.171379,408.160244 C129.757146,404.49196 131.738681,399.549385 131.68,394.42 C131.689973,387.141955 127.666301,380.457655 121.23,377.06 Z"
        />
        <rect
          width="5.55"
          height="138.09"
          x="77.97"
          y="349.16"
          fill="url(#mortgage-p)"
          fillRule="nonzero"
        />
        <rect width="2.79" height="136.15" x="79.55" y="351.1" fill="#795548" fillRule="nonzero" />
        <rect
          width="33.73"
          height="2.79"
          x="74.927"
          y="378.368"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-47.2 91.792 379.763)"
        />
        <rect
          width="2.79"
          height="29.6"
          x="67.047"
          y="389.83"
          fill="#795548"
          fillRule="nonzero"
          transform="rotate(-53.4 68.442 404.63)"
        />
        <path
          fill="url(#mortgage-q)"
          fillRule="nonzero"
          d="M714.33,53.78 C685.759925,24.0572532 646.403792,7.12228414 605.177655,6.81167626 C563.951518,6.50106838 524.344683,22.841111 495.33,52.13 C437.42,110.51 436.44,205.56 493.08,265.13 C544.450272,319.233767 627.353167,328.397786 689.3,286.82 L799.9,401.82 C801.267528,403.245988 803.146093,404.069474 805.121447,404.11035 C807.096801,404.148263 809.006705,403.400331 810.43,402.03 L837.98,375.54 C839.405988,374.172472 840.229474,372.293907 840.27035,370.318553 C840.308263,368.343199 839.560331,366.433295 838.19,365.01 L727.59,250.01 C771.365103,190.046197 765.774271,107.309471 714.33,53.78 Z M679.88,238.7 C636.324306,280.597934 567.050526,279.254307 525.152275,235.698917 C483.254024,192.143528 484.597147,122.869738 528.152231,80.9711702 C571.707316,39.0726023 640.981115,40.4152207 682.88,83.97 C703.000945,104.886149 713.988738,132.938729 713.426127,161.95633 C712.863516,190.973931 700.796588,218.579512 679.88,238.7 Z"
        />
        <path
          fill="url(#mortgage-r)"
          fillRule="nonzero"
          d="M522.99,129.39 C510.68,129.39 510.66,148.51 522.99,148.51 C535.32,148.51 535.31,129.39 522.99,129.39 Z"
        />
        <path
          fill="url(#mortgage-s)"
          fillRule="nonzero"
          d="M608.77,68.13 C575.65,67.38 545.5,84 529.48,109.13 C524.25,117.31 539.1,124.54 544.3,116.4 C557.57,95.63 582.79,82.4 610.04,83.13 C638.62,83.85 664.63,100.2 678.4,122.89 C683.63,131.5 697.31,123.67 692.12,115.11 C675.41,87.54 642.99,68.9 608.77,68.13 Z"
        />
        <path
          fill={primaryColor}
          fillRule="nonzero"
          d="M726.33,47.96 C697.759925,18.2372532 658.403792,1.30228414 617.177655,0.991676259 C575.951518,0.681068377 536.344683,17.021111 507.33,46.31 C449.42,104.69 448.44,199.74 505.08,259.31 C556.450272,313.413767 639.353167,322.577786 701.3,281 L811.9,396 C813.267528,397.425988 815.146093,398.249474 817.121447,398.29035 C819.096801,398.328263 821.006705,397.580331 822.43,396.21 L849.99,369.71 C851.415988,368.342472 852.239474,366.463907 852.28035,364.488553 C852.318263,362.513199 851.570331,360.603295 850.2,359.18 L739.6,244.18 C783.366773,184.215792 777.771869,101.485422 726.33,47.96 Z M691.88,232.88 C648.324306,274.777934 579.050526,273.434307 537.152275,229.878917 C495.254024,186.323528 496.597147,117.049738 540.152231,75.1511702 C583.707316,33.2526023 652.981115,34.5952207 694.88,78.15 C715.000945,99.0661488 725.988738,127.118729 725.426127,156.13633 C724.863516,185.153931 712.796588,212.759512 691.88,232.88 L691.88,232.88 Z"
        />
        <path
          fill={primaryColor}
          fillRule="nonzero"
          d="M534.99 123.54C522.68 123.54 522.66 142.66 534.99 142.66 547.32 142.66 547.31 123.54 534.99 123.54zM620.77 62.31C587.65 61.56 557.5 78.18 541.48 103.31 536.25 111.49 551.1 118.72 556.3 110.58 569.57 89.81 594.79 76.58 622.04 77.31 650.62 78.03 676.63 94.38 690.4 117.07 695.63 125.68 709.31 117.85 704.12 109.29 687.41 81.74 654.99 63.09 620.77 62.31z"
        />
      </g>
    </svg>
  );
};
