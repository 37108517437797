import { Site } from 'lib/models';

export const site = process.env.REACT_APP_PROJECT as Site;

export const siteUrl = process.env.REACT_APP_PUBLIC_URL;

export const siteOferta = process.env.REACT_APP_OFERTA;

export const sitePersonalData = process.env.REACT_APP_PERSONAL_DATA;

export const siteAgreement = process.env.REACT_APP_AGREEMENT;
