import { colors, PaletteType, Theme } from '@material-ui/core';
import { typography } from '../../../theme/typography';
import { merge } from 'lodash';
import { softShadows, strongShadows } from '../../../theme/shadows';
import { ThemeSettings } from '../../../theme/types';

/**
 * BASE CONFIG FOR LIGHT & DARK THEME
 */
const baseConfig: Partial<Theme> = {
  direction: 'ltr',
  shape: {
    borderRadius: 16,
  },
  overrides: {
    MuiTypography: typography,
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
  },
};

/**
 * CONFIG FOR LIGHT THEME
 */
const lightTheme = {
  palette: {
    overrides: {
      MuiInputBase: {
        focused: { color: '#000' },
        input: {
          '&::placeholder': {
            opacity: 1,
            color: '#000',
          },
        },
      },
    },
    type: 'light' as PaletteType,
    action: {
      active: colors.blueGrey[900],
    },
    background: {
      default: '#E7E8ED',
      dark: '#E7E8ED',
      paper: colors.common.white,
    },
    primary: {
      main: '#17479E',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#F15B61',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#212529',
      secondary: '#212529',
    },
  },

  shadows: softShadows,
};

/**
 * CONFIG FOR DARK THEME
 */
const darkTheme = {
  palette: {
    type: 'dark',

    action: {
      active: 'rgba(255, 255, 255, 0.54)',
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
      default: 'rgb(25, 30, 48)',
      dark: 'rgb(25, 30, 48)',
      paper: 'rgb(36, 40, 57)',
    },
    primary: {
      main: '#F15B61',
      contrastText: '#0A1524',
    },
    secondary: {
      main: '#F15B61',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#F8FBF5',
      secondary: '#A9A9A9',
    },
  },
  shadows: strongShadows,
};

const getThemes = () => {
  const light = merge({}, baseConfig, lightTheme);
  const dark = merge({}, baseConfig, darkTheme);
  return { light, dark };
};

export default getThemes;

// Use for more detailed theme settings
// export const getZenitThemeSettings = (theme: Theme): ThemeSettings => {
//   const isDark = theme.palette.type === 'dark';
//   return {
//     logo: {
//       partners: isDark ? '#fff' : theme.palette.primary.main,
//     },
//     sidebar: {
//       icons: isDark ? 'primary' : 'primary',
//       primaryText: isDark ? 'secondary' : 'secondary',
//       primaryTextActive: isDark ? 'primary' : 'primary',
//       badge: isDark ? 'secondary' : 'secondary',
//     },
//     products: {
//       badge: isDark ? 'primary' : 'primary',
//       images: isDark ? theme.palette.secondary.main : theme.palette.secondary.main,
//       button: isDark ? 'primary' : 'primary',
//       infoImportant: isDark ? 'primary' : 'primary',
//     },
//     support: {
//       icons: isDark ? 'primary' : 'primary',
//       links: isDark ? 'primary' : 'primary',
//       background: isDark ? darkTheme.palette.background.dark : lightTheme.palette.background.dark,
//     },
//     emptyList: {
//       image: isDark ? 'primary' : 'primary',
//       button: isDark ? 'primary' : 'primary',
//     },
//     services: {
//       title: isDark ? 'primary' : 'primary',
//     },
//   };
// };

export const getSovcombankThemeSettings = (theme: Theme): ThemeSettings => {
  const isDark = theme.palette.type === 'dark';
  return {
    logo: {
      partners: isDark ? '#fff' : theme.palette.primary.main,
    },
    sidebar: {
      icons: isDark ? 'secondary' : 'secondary',
      primaryText: isDark ? 'secondary' : 'secondary',
      primaryTextActive: isDark ? 'secondary' : 'secondary',
      badge: isDark ? 'secondary' : 'secondary',
    },
    products: {
      badge: isDark ? 'primary' : 'primary',
      images: isDark ? 'secondary' : 'secondary',
      button: isDark ? 'secondary' : 'secondary',
      infoImportant: isDark ? 'secondary' : 'secondary',
    },
    support: {
      icons: isDark ? 'secondary' : 'primary',
      links: isDark ? 'secondary' : 'primary',
      background: isDark ? darkTheme.palette.background.dark : lightTheme.palette.background.dark,
    },
    emptyList: {
      image: isDark ? 'primary' : 'secondary',
      button: isDark ? 'secondary' : 'secondary',
    },
    services: {
      title: isDark ? 'primary' : 'primary',
    },
  };
};
