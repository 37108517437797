import { trackProgress } from 'common/services/trackProgress';
import apiClient, { apiMultipartClient } from 'common/services/apiClient';
import { site } from 'lib/constants';
//import { UploadDocumentsArgs } from './ApiTypes';
export const apiCallIds = {
  DOCUMENTS_UPLOAD: 'DOCUMENTS_UPLOAD',
  DOCUMENTS_FETCH: 'DOCUMENTS_FETCH',
  DOCUMENTS_DELETE: 'DOCUMENTS_DELETE',
};

const api = {
  /**
   * Upload documents
   * @param files - files to upload
   * @param trackId  - progress track id
   */
  uploadDocuments: (data: any) => {
    switch (site) {
      case 'DOMRF':
        return trackProgress(apiMultipartClient.post('/files', data), apiCallIds.DOCUMENTS_UPLOAD);
      default:
        return trackProgress(
          apiMultipartClient.post('/documents', data),
          apiCallIds.DOCUMENTS_UPLOAD
        );
    }
  },

  /**
   * Fetch documents
   * @param entityId - parent document
   * @param moduleId - parent document block
   * @param trackId - progress track id
   */
  fetchDocuments: (entityId: string, moduleId: string, trackId?: string) =>
    trackProgress(
      apiClient.get('/documents', { params: { entityId, moduleId } }),
      trackId || apiCallIds.DOCUMENTS_FETCH
    ),

  /**
   * Delete Document
   * @param id
   * @param trackId
   */
  deleteDocument: (id: string, trackId?: string) =>
    trackProgress(apiClient.delete(`/documents/${id}`), trackId || apiCallIds.DOCUMENTS_DELETE),
};

export default api;
