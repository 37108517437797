import { useQuery } from 'react-query';
// Api
import { usersApi } from 'lib/api';
// Enums
import { QueryKeysEnum } from 'lib/enums';

/**
 * Хук возвращает пользователей с ролью DEAL
 */
export const useDealers = (mortgageCenter: string) => {
  const { data, ...res } = useQuery(
    [QueryKeysEnum.dealers, { mortgageCenter }],
    async () => {
      const { data } = await usersApi.fetchDealers({ mortgageCenter });
      return data;
    },
    {
      enabled: !!mortgageCenter,
      refetchOnWindowFocus: false,
      onError: (error) => console.error('Error fetch dealers', error),
    }
  );

  return {
    dealers: data,
    ...res,
  };
};
