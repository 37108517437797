import { createContext, useContext, FC } from 'react';

export type ControlData = {
  name: string;
  value: any;
};

/**
 *
 * Context
 *
 */

type TFormUpdateContext = {
  onUpdateFormControl: (_data: ControlData) => void;
};

/**
 * FormUpdateContext седержит метод, который вызывается при изменении значения
 * контрола, виджета, поля и т.д.
 */
const FormUpdateContext = createContext<TFormUpdateContext>({
  onUpdateFormControl: (_data: ControlData) => {},
});
export const useFormUpdateContext = () => useContext(FormUpdateContext);

/**
 *
 * Provider
 *
 */

interface FormUpdateProviderProps {
  onUpdateFormControl: (_data: ControlData) => void;
}

/**
 * Компонент FormUpdateProvider
 */
export const FormUpdateProvider: FC<FormUpdateProviderProps> = ({
  children,
  onUpdateFormControl,
}) => {
  return (
    <FormUpdateContext.Provider value={{ onUpdateFormControl }}>
      {children}
    </FormUpdateContext.Provider>
  );
};
