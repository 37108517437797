import { FC, ReactElement, ReactNode, useMemo } from 'react';
// Material components
import { Stack, Box, Container, Typography } from '@mui/material';
// Ui components
import { Helmet, ContentPreloader } from 'lib/ui';

interface Props {
  /**
   * Заголовок страницы
   */
  title?: ReactNode;
  /**
   * Кнопки или любые другие элементы
   */
  actions?: ReactElement;
  /**
   * Текст для заголовка в head
   */
  helmetTitle?: string;
  /**
   * Признак отображения прелоадера
   */
  isLoading?: boolean;
}

/**
 * Обертка каждой страницы с заголовком и контейнером
 */
export const Page: FC<Props> = ({ title, actions, helmetTitle, isLoading, children }) => {
  const memoHelmetTitle = useMemo(() => {
    if (helmetTitle) return helmetTitle;
    if (typeof title === 'string') return title;
    return undefined;
  }, [helmetTitle, title]);

  const memoTitle = useMemo(() => {
    if (!title) return null;
    if (typeof title === 'string') {
      return (
        <Typography variant="h2" color={({ palette }) => palette.text.primary}>
          {title}
        </Typography>
      );
    }
    return title;
  }, [title]);

  const memoActions = useMemo(() => {
    if (!actions) return null;
    return <Box>{actions}</Box>;
  }, [actions]);

  return (
    <Helmet title={memoHelmetTitle}>
      <ContentPreloader isLoading={isLoading}>
        <Container maxWidth="xl">
          <Stack
            sx={{
              padding: ({ spacing }) => ({
                xs: `${spacing(2)} 0 ${spacing(2)} 0`,
                sm: `${spacing(4)} 0 ${spacing(10)} 0`,
              }),
            }}
            gap={3}
          >
            {(title || actions) && (
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                {memoTitle}
                {memoActions}
              </Stack>
            )}

            {children}
          </Stack>
        </Container>
      </ContentPreloader>
    </Helmet>
  );
};
