import { useState, useMemo, useCallback, createContext, useContext } from 'react';
// Material ui
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { PaletteMode } from '@mui/material';
import { ruRU } from '@material-ui/core/locale';
// Components
import { getThemes } from '../../theme';
import { ls } from 'lib/utils/ls';
import { CssBaseline } from '@material-ui/core';
type ThemeContextValues = {
  mode: PaletteMode;
  toggleMode: () => void;
};

/**
 * Контекст темы
 */
const ThemeContext = createContext<ThemeContextValues>({
  mode: 'light',
  toggleMode: () => {},
});

/**
 * Хук использующий контекст темы
 */
export const useThemeContext = () => {
  return useContext(ThemeContext);
};

/**
 * Провайдер для mui4 (компонент обертка) ThemeProvider
 */
export const ThemeProviderMui4 = ({ children }: any) => {
  const themeMode = ls.getThemeModeMui4();
  const { dark, light } = getThemes();
  const [mode, setMode] = useState<PaletteMode>(themeMode ? themeMode : 'light');

  if (!themeMode) ls.setThemeModeMui4('light');

  const toggleMode = useCallback(() => {
    setMode(mode === 'light' ? 'dark' : 'light');
    ls.setThemeModeMui4(mode === 'light' ? 'dark' : 'light');
  }, [mode]);

  const theme = useMemo(
    () => createTheme(mode === 'light' ? light : dark, ruRU),
    [mode, dark, light]
  );

  return (
    <ThemeContext.Provider value={{ mode, toggleMode }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
