import { Typography } from '@mui/material';
// Icons
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
// Types
import { SupportList } from 'lib/ui/PrivateLayout/Sidebar/Support/types';

/**
 * Пункты поддержки в проекте Zenit
 */
export const zenitSupport: SupportList = [
  /* {
     id: 'phone',
     Icon: PhoneEnabledRounded,
     link: 'tel:8-800-707-46-44',
     primary: '8-800-707-46-44',
   },*/
  {
    id: 'sendEmail',
    Icon: EmailRoundedIcon,
    link: 'mailto:support7@e-place.com',
    primary: 'support7@e-place.com',
    secondary: (
      <>
        <Typography variant="inherit">- вопросы по профилю</Typography>
        <Typography variant="inherit">- узнать об оплате за заявку</Typography>
        <Typography variant="inherit">- по работе платформы</Typography>
      </>
    ),
  },
];
